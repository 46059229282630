import IPlan from "../models/IPlan";
import ISession from "../models/ISession";
import IVideo from "../models/IVideo";
import { request } from "./ApiService";
import { uploadFile } from "./FileService";

type SavePlanTemplateRequest = Pick<IPlan, "description" | "status" | "title">;

export const addTemplateImageToSession = async (
  blob: Blob,
  fileExtension: string,
  planId: string,
  sessionId: string,
  handleProgress?: (name: string, percentComplete: number) => void,
): Promise<IVideo> => {
  const imageId = await uploadFile(blob, { fileExtension }, handleProgress);

  return request(`plantemplates/${planId}/sessions/${sessionId}/image/${imageId}`, { method: "POST", body: { fileExtension } });
};
export const addVideo = async (
  id: string,
  sessionId: string,
  blob: Blob,
  recordingType: string,
  patientVideoId?: string,
  handleProgress?: (name: string, percentComplete: number) => void,
): Promise<IVideo> => {
  const videoId = await uploadFile(blob, undefined, handleProgress);

  return saveVideo(id, sessionId, videoId, recordingType, false, patientVideoId);
};
export const addSessionTemplate = (id: string, value: ISession) => request(`plantemplates/${id}/sessions`, { method: "POST", body: value });
export const copyPlanToPlanTemplates = (planToCopy: IPlan) => request(`plantemplates/${planToCopy._id}`, { method: "POST" });
export const createPlanTemplate = (value: SavePlanTemplateRequest & { _id?: string }) => request("plantemplates", { method: "POST", body: value });
export const deletePlanTemplate = (planTemplateId: string) => request(`plantemplates/${planTemplateId}`, { method: "DELETE" });
export const deleteSessionTemplateImage = async (planId: string, sessionId: string, imageId: string) =>
  request(`plantemplates/${planId}/sessions/${sessionId}/image/${imageId}`, { method: "DELETE" });
export const getPlanTemplates = () => request("plantemplates");
export const savePlanTemplate = (value: SavePlanTemplateRequest & { _id: string }) => request(`plantemplates/${value._id}`, { method: "PUT", body: value });
export const saveSessionTemplate = (id: string, value: ISession) => request(`plantemplates/${id}/sessions/${value._id}`, { method: "PUT", body: value });
export const saveVideo = async (id: string, sessionId: string, videoId: string, recordingType: string, isVideoLibrary: boolean, patientVideoId?: string) =>
  request(`plantemplates/${id}/sessions/${sessionId}/video/${videoId}`, { method: "POST", body: { recordingType, isVideoLibrary, patientVideoId } });
