import { createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import { rootReducer } from "./Root";

const store = createStore(rootReducer, composeWithDevTools({ trace: true, traceLimit: 25 })());

export const dispatch = store.dispatch;

export default store;
