import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Role } from "../../models/IAuth";

type Props = {
  patientId: string;
  role: Role;
};

const BackLink: FC<Props> = props => {
  return props.role === Role.doctor || props.role === Role.organizationadmin ? (
    <Link className="btn btn-outline" style={{ marginBottom: ".5em", border: "1px solid #ddd" }} to={`/caseload/${props.patientId}`}>
      Back to caseload
    </Link>
  ) : null;
};

export default BackLink;
