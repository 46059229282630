import React, { FC, useEffect } from "react";
import { useMsal } from "@azure/msal-react";

import LoadingBlock from "../core/LoadingBlock";

const Logout: FC = () => {
  const { instance } = useMsal();

  useEffect(() => {
    instance.logoutRedirect();
  }, [instance]);

  return (
    <div className="container d-flex flex-grow-1">
      <div className="row w-100 d-flex justify-content-center align-items-center">
        <div className="card col col-lg-6" style={{ background: "rgba(255, 255, 255, 0.7)" }}>
          <div className="card-body">
            <h3 className="text-center mb-3">Signing Out</h3>
            <LoadingBlock />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logout;
