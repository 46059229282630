import React, { FC } from "react";

import noop from "../../utils/noop";
import Spinner from "../core/Spinner";

type Props = {
  isPending?: boolean;
  isValid?: boolean;
  submitLabel?: string;
  submitPendingLabel?: string;
  onCancel?: () => void;
};

const ActionButtons: FC<Props> = ({ isPending, submitLabel = "Submit", submitPendingLabel = "Submitting", isValid, onCancel = noop }) => {
  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <button type="button" className="action btn text-danger" disabled={isPending} onClick={() => onCancel()}>
        Cancel
      </button>
      <button type="submit" className="action btn btn-primary" disabled={isPending || !isValid}>
        {isPending && <Spinner />} {isPending ? submitPendingLabel : submitLabel}
      </button>
    </div>
  );
};

export default ActionButtons;
