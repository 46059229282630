import React, { CSSProperties, FC, useMemo } from "react";

type Props = {
  left?: CSSProperties["left"];
  zIndex?: CSSProperties["zIndex"];
};

const ModalSlim: FC<Props> = ({ children, left, zIndex }) => {
  const initialTop = useMemo(() => {
    const scrollTop = document.querySelector("html")!.scrollTop;

    return scrollTop + window.innerHeight / 2;
  }, []);

  return (
    <div className="modal-slim" style={{ top: initialTop, left, zIndex }}>
      {children}
    </div>
  );
};

export default ModalSlim;
