import React from "react";

import IVideo from "../../models/IVideo";

type Props = {
  video?: IVideo;
};

export default function VideoPreview({ video }: Props) {
  return (
    <div className="embed-responsive embed-responsive-16by9">
      {video ? (
        <video
          className="embed-responsive-item"
          muted={true}
          poster="images/spinner.svg"
          preload="metadata"
          onLoadedMetadata={x => {
            x.currentTarget.currentTime = 0;
          }}
          onMouseEnter={x => x.currentTarget.play().catch(() => null)}
          onMouseLeave={x => {
            x.currentTarget.pause();
            x.currentTarget.currentTime = 0;
          }}
          onTouchStart={x => {
            if (x.currentTarget.paused) {
              x.currentTarget.play().catch(() => null);
            } else {
              x.currentTarget.pause();
              x.currentTarget.currentTime = 0;
            }
          }}
        >
          {video.sasMp4 && <source src={video.sasMp4} />}
          <source src={video.sas} />
        </video>
      ) : (
        <img className="embed-responsive-item" src="/images/video-placeholder.jpg" alt="Video Placeholder" />
      )}
    </div>
  );
}
