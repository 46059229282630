// Backstory: this is a great component, but the owner does not have much documentation.
// I grabbed this component per their instructions and will extend as needed.
// So far, I think I'll only adjust the ActionsWrapper styles.

import PropTypes from "prop-types";
import React from "react";
import Button from "./videoRecorder/defaults/button";
import Countdown from "./videoRecorder/defaults/countdown";
import RecordButton from "./videoRecorder/defaults/record-button";
import StopButton from "./videoRecorder/defaults/stop-button";
import Timer from "./videoRecorder/defaults/timer";
import styled from "styled-components";

const ActionsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  margin-bottom: 80px;
`;

const Actions = ({
  isVideoInputSupported,
  isInlineRecordingSupported,
  thereWasAnError,
  isRecording,
  isCameraOn,
  streamIsReady,
  isConnecting,
  isRunningCountdown,
  isReplayingVideo,
  countdownTime,
  timeLimit,
  useVideoInput,

  onTurnOnCamera,
  onOpenVideoInput,
  onStartRecording,
  onStopRecording,
  onStopReplaying,
}: any) => {
  const renderContent = () => {
    const shouldUseVideoInput = !isInlineRecordingSupported && isVideoInputSupported;

    if ((!isInlineRecordingSupported && !isVideoInputSupported) || thereWasAnError || isConnecting || isRunningCountdown) {
      return null;
    }

    if (isReplayingVideo) {
      return (
        <Button onClick={onStopReplaying} data-qa="start-replaying">
          Use another video
        </Button>
      );
    }

    if (isRecording) {
      return <StopButton onClick={onStopRecording} data-qa="stop-recording" />;
    }

    if (isCameraOn && streamIsReady) {
      return <RecordButton t={(str: string) => str} onClick={onStartRecording} data-qa="start-recording" />;
    }

    if (useVideoInput) {
      return (
        <Button onClick={onOpenVideoInput} data-qa="open-input">
          Select a video
        </Button>
      );
    }

    return shouldUseVideoInput ? (
      <Button onClick={onOpenVideoInput} data-qa="open-input">
        Record a video
      </Button>
    ) : (
      <Button onClick={onTurnOnCamera} data-qa="turn-on-camera">
        Turn my camera ON
      </Button>
    );
  };

  return (
    <div>
      {isRecording && <Timer timeLimit={timeLimit} />}
      {isRunningCountdown && <Countdown countdownTime={countdownTime} />}
      <ActionsWrapper>{renderContent()}</ActionsWrapper>
    </div>
  );
};

Actions.propTypes = {
  isVideoInputSupported: PropTypes.bool,
  isInlineRecordingSupported: PropTypes.bool,
  thereWasAnError: PropTypes.bool,
  isRecording: PropTypes.bool,
  isCameraOn: PropTypes.bool,
  streamIsReady: PropTypes.bool,
  isConnecting: PropTypes.bool,
  isRunningCountdown: PropTypes.bool,
  countdownTime: PropTypes.number,
  timeLimit: PropTypes.number,
  showReplayControls: PropTypes.bool,
  replayVideoAutoplayAndLoopOff: PropTypes.bool,
  isReplayingVideo: PropTypes.bool,
  useVideoInput: PropTypes.bool,

  onTurnOnCamera: PropTypes.func,
  onTurnOffCamera: PropTypes.func,
  onOpenVideoInput: PropTypes.func,
  onStartRecording: PropTypes.func,
  onStopRecording: PropTypes.func,
  onPauseRecording: PropTypes.func,
  onResumeRecording: PropTypes.func,
  onStopReplaying: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default Actions;
