import React, { FC } from "react";

import { useContacts, usePlans } from "../../hooks/useCollection";
import IAttachment from "../../models/IAttachment";
import IComment from "../../models/IComment";
import IContact from "../../models/IContact";
import IPlan from "../../models/IPlan";
import IVideo from "../../models/IVideo";
import PatientAccount from "../../models/PatientAccount";
import { addPatient } from "../../services/CaseloadService";
import Dashboard from "./Dashboard";

const DashboardPage: FC = () => {
  const contacts = useContacts();
  const plans = usePlans();

  const caseload = contacts && Object.values(contacts).filter(x => !x.self);

  const plansList = plans && Object.values(plans);

  const needsReview: {
    plan: IPlan;
    patient: IContact | null;
    _id: string;
    attachments: IAttachment[];
    comments: IComment[];
    description: string;
    focus: "baseline" | "stability" | "advanced" | "";
    order: number;
    status: "draft" | "active" | "archive";
    title: string;
    videos: IVideo[];
  }[] = [];

  if (plansList) {
    const activePlansAndSessions = plansList
      .filter(x => x.status === "active")
      .flatMap(x => x.sessions.filter(y => y.status === "active").map(y => ({ ...y, plan: x, patient: contacts && contacts[x.patientId] })));

    activePlansAndSessions.forEach(plan => {
      plan.videos.forEach(video => {
        if (
          video.videoType === "patient" &&
          plan.videos.findIndex(reviewVideo => reviewVideo.videoType === "doctor" && reviewVideo.patientVideoId === video._id) === -1 &&
          needsReview.findIndex(session => session._id === plan._id) === -1
        ) {
          needsReview.push(plan);
        }
      });
    });
  }

  // TODO: edit patient
  const onSavePatient = (item: PatientAccount) => addPatient(item);

  return <Dashboard caseload={caseload} needsReview={needsReview} onSavePatient={onSavePatient} />;
};

export default DashboardPage;
