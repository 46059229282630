import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

import useAuth from "../../hooks/useAuth";
import { saveProfile } from "../../services/ProfileService";
import Profile from "./Profile";

const ProfilePageContent: FC = () => {
  const auth = useAuth()!;
  const history = useHistory();

  const [isSaving, setIsSaving] = useState(false);

  const dispatch = useDispatch();

  return (
    <Profile
      key={auth.email + auth.firstName + auth.lastName}
      initEmail={auth.email}
      initFirstName={auth.firstName}
      initLastName={auth.lastName}
      isSaving={isSaving}
      onSubmit={item => {
        setIsSaving(true);

        saveProfile(item)
          .then(data => {
            toast.success("Successfully saved profile");
            dispatch({ type: "SET_AUTH", item: data });
            history.push("/");
          })
          .catch(err => toast.error(err.message))
          .finally(() => setIsSaving(false));
      }}
    />
  );
};

const ProfilePage: FC = () => {
  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <ProfilePageContent />
    </MsalAuthenticationTemplate>
  );
};

export default ProfilePage;
