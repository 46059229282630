import React, { FC } from "react";
import IVideo from "../../models/IVideo";
import VideoPreview from "../video/VideoPreview";
import { CalendarIcon, DeleteIcon, ImageIcon, VerticalDotsIcon } from "../core/Icons";
import { formatObjectId } from "../../services/DateService";
import { useSelector } from "react-redux";
import { IAppState } from "../../store/Root";

export type Props = {
  onVideoClick: (video: IVideo) => void;
  onVideoDelete: (video: IVideo) => void;
  onVideoImageCapture: (video: IVideo) => void;
  video: IVideo;
};

const AssessmentVideoDisplay: FC<Props> = ({ video, onVideoClick, onVideoDelete, onVideoImageCapture }) => {
  const filesUploadingSelector = (state: IAppState) => state.fileUpload.filesUploading;
  const filesUploading = useSelector(filesUploadingSelector);
  const fileProgress = filesUploading.find(file => file.name === video._id);

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div style={{ cursor: "pointer" }} onClick={() => onVideoClick(video)}>
        <VideoPreview video={{ _id: video._id, sas: video.sas, sasMp4: video.sasMp4, videoType: "patient" } as IVideo} />
      </div>

      <section className="border-without-top p-2" style={{ flex: "1 1 auto" }}>
        <div data-toggle="dropdown" style={{ cursor: "pointer", float: "right", minWidth: "1rem", textAlign: "center" }}>
          <VerticalDotsIcon />
        </div>
        <div className="dropdown-menu dropdown-menu-right">
          <div className="dropdown-item" onClick={() => onVideoImageCapture(video)}>
            <ImageIcon /> Capture Image
          </div>
          <div className="dropdown-item" onClick={() => onVideoDelete(video)}>
            <DeleteIcon style={{ color: "red" }} /> Delete Video
          </div>
        </div>
        <div className="info-wrap">
          <div>
            <CalendarIcon /> {formatObjectId(video._id)}
          </div>
          {fileProgress && fileProgress.percentComplete < 100 && <span>&nbsp;{fileProgress.percentComplete}% uploaded</span>}
        </div>
      </section>
    </div>
  );
};

export default AssessmentVideoDisplay;
