import * as poseDetection from "@tensorflow-models/pose-detection";

export const drawResults = (poses: poseDetection.Pose[], annotationsRef: HTMLCanvasElement, poseModel: poseDetection.SupportedModels) => {
  for (const pose of poses) {
    drawResult(pose, annotationsRef, poseModel);
  }
};

const drawResult = (pose: poseDetection.Pose, annotationsRef: HTMLCanvasElement, poseModel: poseDetection.SupportedModels) => {
  if (pose.keypoints != null) {
    const ctx = annotationsRef.getContext("2d");
    if (ctx !== null) {
      drawKeypoints(pose.keypoints, ctx, poseModel);
      drawSkeleton(pose.keypoints, ctx, poseModel);
    }
  }
};

const drawKeypoints = (keypoints: poseDetection.Keypoint[], ctx: CanvasRenderingContext2D, poseModel: poseDetection.SupportedModels) => {
  const keypointInd = poseDetection.util.getKeypointIndexBySide(poseModel);
  ctx.fillStyle = "Red";
  ctx.strokeStyle = "White";
  ctx.lineWidth = 2;

  for (const i of keypointInd.middle) {
    drawKeypoint(keypoints[i], ctx);
  }

  ctx.fillStyle = "Green";
  for (const i of keypointInd.left) {
    drawKeypoint(keypoints[i], ctx);
  }

  ctx.fillStyle = "Orange";
  for (const i of keypointInd.right) {
    drawKeypoint(keypoints[i], ctx);
  }
};

const drawKeypoint = (keypoint: poseDetection.Keypoint, ctx: CanvasRenderingContext2D) => {
  // If score is null, just show the keypoint.
  const score = keypoint.score != null ? keypoint.score : 1;
  const scoreThreshold = 0.3;

  if (score >= scoreThreshold) {
    const circle = new Path2D();
    circle.arc(keypoint.x, keypoint.y, 4, 0, 2 * Math.PI);
    ctx.fill(circle);
    ctx.stroke(circle);
  }
};

const drawSkeleton = (keypoints: poseDetection.Keypoint[], ctx: CanvasRenderingContext2D, poseModel: poseDetection.SupportedModels) => {
  // Each poseId is mapped to a color in the color palette.
  const color = "White";
  ctx.fillStyle = color;
  ctx.strokeStyle = color;
  ctx.lineWidth = 2;

  poseDetection.util.getAdjacentPairs(poseModel).forEach(([i, j]) => {
    const kp1 = keypoints[i];
    const kp2 = keypoints[j];

    // If score is null, just show the keypoint.
    const score1 = kp1.score != null ? kp1.score : 1;
    const score2 = kp2.score != null ? kp2.score : 1;
    const scoreThreshold = 0.3;

    if (score1 >= scoreThreshold && score2 >= scoreThreshold) {
      ctx.beginPath();
      ctx.moveTo(kp1.x, kp1.y);
      ctx.lineTo(kp2.x, kp2.y);
      ctx.stroke();
    }
  });
};
