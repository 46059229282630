import React, { FC } from "react";

import Modal from "../core/Modal";
import FilterDoctorsCard, { Props as CardProps } from "./FilterDoctorsCard";

type Props = CardProps;

const FilterDoctorsModal: FC<Props> = props => (
  <Modal onClose={props.onCancel}>
    <FilterDoctorsCard {...props} />
  </Modal>
);

export default FilterDoctorsModal;
