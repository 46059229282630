import React, { FC } from "react";
import VideoDisplay from "../video/VideoDisplay";
import { CreateIcon, DeleteIcon, EditIcon, VerticalDotsIcon } from "../core/Icons";
import IVideo from "../../models/IVideo";
import { defaultExampleVideos } from "./DefaultVideos";
import ModifyVideoModal from "./ModifyVideoModal";
import DeleteVideoModal from "./DeleteVideoModal";

type Props = {
  deletingVideo?: IVideo;
  editVideo?: IVideo;
  isSavingVideo: boolean;
  onDelete: () => void;
  onSave: (file: File, name: string) => void;
  setDeleteVideo: (x: IVideo | undefined) => void;
  setEditVideo: (x: IVideo | undefined) => void;
  uploadProgress?: number;
  videoLibraryList: IVideo[] | null;
};

const VideoLibrary: FC<Props> = props => {
  return (
    <main>
      <article>
        <h2 style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <div>Video Library</div>
          <div>
            <button className="btn btn-outline" onClick={() => props.setEditVideo({} as IVideo)}>
              <CreateIcon /> Add Video
            </button>
          </div>
        </h2>
        <section className="grid">
          {props.videoLibraryList &&
            props.videoLibraryList.map(videoLibraryVideo => (
              <div key={videoLibraryVideo._id} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <VideoDisplay sasMp4={videoLibraryVideo.sasMp4} sas={videoLibraryVideo.sas} />
                <section className="border-without-top p-2" style={{ flex: "1 1 auto" }}>
                  <div data-cy="session actions" data-toggle="dropdown" style={{ cursor: "pointer", float: "right", minWidth: "1rem", textAlign: "center" }}>
                    <VerticalDotsIcon />
                  </div>
                  <div className="dropdown-menu dropdown-menu-right">
                    <div className="dropdown-item" onClick={() => props.setEditVideo(videoLibraryVideo)}>
                      <EditIcon /> Edit Video
                    </div>
                    <div className="dropdown-item" onClick={() => props.setDeleteVideo(videoLibraryVideo)}>
                      <DeleteIcon /> Delete Video
                    </div>
                  </div>
                  <h3>{videoLibraryVideo.name}</h3>
                </section>
              </div>
            ))}
        </section>
      </article>
      <article>
        <h2 style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <div>Default Library</div>
        </h2>
        <section className="grid">
          {defaultExampleVideos.map(defaultVideo => (
            <div key={defaultVideo._id} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <VideoDisplay sas={defaultVideo.sas} />
              <section className="border-without-top p-2" style={{ flex: "1 1 auto" }}>
                <h3>{defaultVideo.name}</h3>
              </section>
            </div>
          ))}
        </section>
      </article>
      {props.editVideo && (
        <ModifyVideoModal
          current={props.editVideo}
          isPending={props.isSavingVideo}
          onCancel={() => props.setEditVideo(undefined)}
          onSubmit={props.onSave}
          uploadProgress={props.uploadProgress}
        />
      )}
      {props.deletingVideo && <DeleteVideoModal isPending={props.isSavingVideo} onCancel={() => props.setDeleteVideo(undefined)} onSubmit={props.onDelete} />}
    </main>
  );
};

export default VideoLibrary;
