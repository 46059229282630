import React, { FC, useState } from "react";

import { Link, useHistory } from "react-router-dom";

import IContact from "../../models/IContact";
import noop from "../../utils/noop";
import { compareProps } from "../../utils/sort";
import Avatar from "../avatar/Avatar";
import { SearchIcon } from "../core/Icons";

export type Props = {
  caseload?: IContact[];
  onCancel?: () => void;
};

const getScore = (phrase: string, field: string, value: number) => {
  return field.toLocaleLowerCase().includes(phrase) ? value : 0;
};

const SearchCard: FC<Props> = ({ caseload = [], onCancel = noop }) => {
  const history = useHistory();
  const [phrase, setPhrase] = useState("");

  const lowerPhrase = phrase.toLocaleLowerCase();

  const results = caseload
    .map(x => ({ ...x, score: getScore(lowerPhrase, x.lastName, 3) + getScore(lowerPhrase, x.firstName, 2) + getScore(lowerPhrase, x.email, 1) }))
    .filter(x => x.score)
    .sort((a, b) => compareProps(a, b, { score: -1, lastName: 1, firstName: 1 }));

  const total = results.length;

  return (
    <article data-cy="search card" className="material">
      <h2 style={{ display: "flex", alignItems: "center" }}>
        <label className="mr-2" htmlFor="search" style={{ margin: 0 }}>
          <SearchIcon />
        </label>
        <form
          style={{ width: "100%" }}
          onSubmit={e => {
            e.preventDefault();

            if (results.length) {
              history.push(`/caseload/${results[0]._id}`);
              onCancel();
            }
          }}
        >
          <input
            data-cy="search bar"
            type="text"
            className="form-control"
            style={{ background: "transparent", color: "white", padding: "0rem .2rem" }}
            autoComplete="off"
            autoFocus={true}
            id="search"
            spellCheck={false}
            value={phrase}
            onChange={e => setPhrase(e.target.value)}
            onKeyDown={e => {
              if (e.key === "ArrowDown") {
                e.preventDefault();

                const parent = e.currentTarget.closest("article");

                if (!parent) {
                  return;
                }

                const el = parent.querySelector(".list-group-item") as HTMLElement;

                if (el && el.focus) {
                  el.focus();
                }
              }
            }}
          />
        </form>
      </h2>
      <section>
        <div style={{ overflowY: "auto", height: "70vh" }}>
          {!!total && (
            <div
              data-cy="search results"
              className="list-group"
              onKeyDown={e => {
                const el = e.target as HTMLElement;

                if (!el) {
                  return;
                }

                const parent = el.parentElement;

                let sib: HTMLElement | null = null;

                switch (e.key) {
                  case "ArrowUp": {
                    const first = parent && parent.querySelector(".list-group-item");

                    if (el === first) {
                      sib = document.getElementById("search");
                    } else {
                      sib = el.previousElementSibling as HTMLElement;
                    }

                    break;
                  }
                  case "ArrowDown": {
                    sib = el.nextElementSibling as HTMLElement;
                    break;
                  }
                  default: {
                    return;
                  }
                }

                e.preventDefault();

                if (sib && sib.focus) {
                  sib.focus();
                }
              }}
            >
              {results.map(result => (
                <Link
                  key={result._id}
                  to={`/caseload/${result._id}`}
                  className="list-group-item list-group-item-action"
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={() => onCancel()}
                >
                  <Avatar {...result} />
                  <div className="ml-2" style={{ flex: "1 1 0" }}>
                    {result.lastName}, {result.firstName}
                    <span className="ml-2" style={{ color: "gray", fontSize: ".75rem" }}>
                      {result.email}
                    </span>
                  </div>
                </Link>
              ))}
            </div>
          )}
        </div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <div style={{ color: "gray", fontSize: ".8rem" }}>{total} results.</div>
          <button type="button" className="action btn text-danger" onClick={() => onCancel()}>
            Cancel
          </button>
        </div>
      </section>
    </article>
  );
};

export default SearchCard;
