import classNames from "classnames";
import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";

import useClamp from "../../hooks/useClamp";
import useCustomEvent from "../../hooks/useCustomEvent";
import useKeyListener from "../../hooks/useKeyListener";
import { getPencil } from "../../memo/blobs";
import { clearCanvas, drawLine } from "../../utils/canvas";
import noop from "../../utils/noop";
import AnnotationsMenu, { Mode } from "./AnnotationsMenu";

export type { Mode };

type Props = {
  disabled?: boolean;
  disabledHotkeys?: boolean;
  enableIdeal?: boolean;
  idealPoseImageSelected: string | undefined;
  initColor?: string;
  initMode?: Mode;
  initSize?: number;
  onAddIdealPose?: () => void;
  onClear?: () => void;
  onDraw?: () => void;
  onFlipIdealPoseChange?: () => void;
  onModeChange?: (x: Mode) => void;
  onSelectedIdealPoseIdChange?: (x: string) => void;
};

type Annotation = {
  color: string;
  coordinates: number[];
  mode: Mode;
  size: number;
};

const Annotations = forwardRef<HTMLCanvasElement, Props>(
  (
    {
      disabled,
      disabledHotkeys = false,
      enableIdeal = false,
      idealPoseImageSelected,
      initColor,
      initMode,
      initSize,
      onAddIdealPose = noop,
      onClear = noop,
      onDraw = noop,
      onFlipIdealPoseChange = noop,
      onModeChange = noop,
      onSelectedIdealPoseIdChange = noop,
    },
    ref,
  ) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useClamp(canvasRef);

    const [annotations, setAnnotations] = useState<Array<Annotation>>([]);
    const [color, setColor] = useState<string>(initColor || "white");
    const [drawPosition, setDrawPosition] = useState<number[] | null>(null);
    const [mode, _setMode] = useState<Mode>(initMode || "select");
    const [size, setSize] = useState<number>(initSize || 3);

    useCustomEvent(
      "drawCurrentAnnotations",
      () => {
        drawAnnotations(annotations);
      },
      [annotations],
    );

    const setMode = (x: Mode) => {
      _setMode(x);
      onModeChange(x);
      setDrawPosition(null);
    };

    useCustomEvent("recordReviewEnd", () => clearAnnotations());

    useKeyListener(
      "Escape",
      () => {
        if (!disabledHotkeys) {
          setMode("select");
        }
      },
      [disabledHotkeys],
    );

    useKeyListener(
      "p",
      () => {
        if (!disabledHotkeys) {
          setMode("pencil");
        }
      },
      [disabledHotkeys],
    );

    useKeyListener(
      navigator.appVersion.toUpperCase().indexOf("MAC") >= 0 ? "meta+z" : "ctrl+z",
      () => {
        if (!disabledHotkeys) {
          handleUndo();
        }
      },
      [disabledHotkeys, annotations, canvasRef],
    );

    useImperativeHandle(ref, () => canvasRef.current as any);

    const drawAnnotation = (coordinates: number[]) => {
      setAnnotations([...annotations, { color, size, coordinates, mode }]);
      drawLine(canvasRef, color, size, coordinates);
      onDraw();
    };

    const clearAnnotations = () => {
      clearCanvas(canvasRef);

      setDrawPosition(null);
      setMode("select");
      setAnnotations([]);

      if (onClear) {
        onClear();
      }
    };

    const drawFirstPosition = ([x, y]: number[]) => {
      const canvas = canvasRef.current;

      if (!canvas) {
        return;
      }

      const rect = canvas.getBoundingClientRect();

      const newPosition = [x - rect.left, y - rect.top];

      const origin = [newPosition[0] + 1, newPosition[1] + 1];

      let coordinates = null;
      if (mode === "pencil" || (mode === "line" && drawPosition === null)) {
        coordinates = [...origin, ...newPosition];
      } else if (mode === "line") {
        coordinates = [...drawPosition!, ...newPosition];
      }

      drawAnnotation(coordinates!);

      setDrawPosition(newPosition);
    };

    const drawNewPosition = ([x, y]: number[]) => {
      if (mode !== "pencil" || !drawPosition) {
        return;
      }

      const canvas = canvasRef.current;

      if (!canvas) {
        return;
      }

      const rect = canvas.getBoundingClientRect();

      const newPosition = [x - rect.left, y - rect.top];

      const coordinates = [...drawPosition, ...newPosition];

      drawAnnotation(coordinates);

      setDrawPosition(newPosition);
    };

    const handleMouseUp = () => {
      if (mode === "line") {
        return;
      } else {
        setDrawPosition(null);
      }
    };

    const handleUndo = () => {
      const currentAnnotations = [...annotations];
      clearAnnotations();
      const newAnnotations: Array<Annotation> = [];
      let skipped = 0;
      let continueSkipping = true;
      for (let i = currentAnnotations.length - 1; i >= 0; i--) {
        continueSkipping =
          (continueSkipping && skipped <= 10 && currentAnnotations[i].mode === "pencil") || (i === currentAnnotations.length - 1 && currentAnnotations[i].mode !== "pencil");
        if (!continueSkipping) {
          newAnnotations.splice(0, 0, currentAnnotations[i]);
        } else {
          skipped++;
        }
      }

      drawAnnotations(newAnnotations);
      setAnnotations(newAnnotations);
    };

    const drawAnnotations = (annos: Array<Annotation>) => {
      annos.forEach(anno => {
        drawLine(canvasRef, anno.color, anno.size, anno.coordinates);
      });
    };

    const pencilUrl = getPencil(color);
    const customCursor = `url("${pencilUrl}") 0 20, auto`;
    const ghost = disabled || mode === "select" || mode === "ideal";

    return (
      <div className={classNames("annotations", ghost && "ghost")} style={{ cursor: ghost ? undefined : customCursor }}>
        <canvas
          ref={canvasRef}
          onMouseDown={e => {
            if (mode === "line") {
              return;
            }
            drawFirstPosition([e.clientX, e.clientY]);
          }}
          onClick={e => {
            if (mode !== "line") {
              return;
            }
            drawFirstPosition([e.clientX, e.clientY]);
          }}
          onContextMenu={e => {
            if (mode !== "line") {
              return;
            }
            e.preventDefault();
            setDrawPosition(null);
            return;
          }}
          onMouseMove={e => {
            e.preventDefault();

            drawNewPosition([e.clientX, e.clientY]);
          }}
          onMouseUp={handleMouseUp}
          onTouchStart={e => drawFirstPosition([e.changedTouches[0].clientX, e.changedTouches[0].clientY])}
          onTouchMove={e => {
            e.preventDefault();

            const touch = e.changedTouches[0];

            drawNewPosition([touch.clientX, touch.clientY]);
          }}
          onTouchCancel={() => setDrawPosition(null)}
          onTouchEnd={() => setDrawPosition(null)}
        />
        <AnnotationsMenu
          style={{ position: "absolute", top: "1rem", right: "1rem", zIndex: "1" }}
          canUndo={annotations.length > 0}
          color={color}
          enableIdeal={enableIdeal}
          idealPoseImageSelected={idealPoseImageSelected}
          mode={mode}
          size={size}
          onAddIdealPose={onAddIdealPose}
          onClear={clearAnnotations}
          onColorChange={setColor}
          onFlipIdealPoseChange={onFlipIdealPoseChange}
          onModeChange={setMode}
          onSelectedIdealPoseIdChange={onSelectedIdealPoseIdChange}
          onSizeChange={setSize}
          onUndo={handleUndo}
        />
      </div>
    );
  },
);

Annotations.displayName = "Annotations";

export default Annotations;
