import React, { FC } from "react";

type Props = {
  color?: string;
};

const Pencil: FC<Props> = ({ color = "black" }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fad"
    data-icon="pencil"
    className="svg-inline--fa fa-pencil fa-w-16"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    height="20"
    width="20"
  >
    <g className="fa-group">
      <path
        className="fa-secondary"
        fill="white"
        stroke="black"
        strokeWidth="5"
        d="M96 352H32l-16 64 80 80 64-16v-64H96zM498 74.26l-.11-.11L437.77 14a48.09 48.09 0 0 0-67.9 0l-46.1 46.1a12 12 0 0 0 0 17l111 111a12 12 0 0 0 17 0l46.1-46.1a47.93 47.93 0 0 0 .13-67.74z"
      ></path>
      <path
        className="fa-primary"
        fill={color}
        stroke="black"
        strokeWidth="5"
        d="M.37 483.85a24 24 0 0 0 19.47 27.8 24.27 24.27 0 0 0 8.33 0l67.32-16.16-79-79zM412.3 210.78l-111-111a12.13 12.13 0 0 0-17.1 0L32 352h64v64h64v64l252.27-252.25a12 12 0 0 0 .03-16.97z"
      ></path>
    </g>
  </svg>
);

export default Pencil;
