import React from "react";
import { Link } from "react-router-dom";
import SessionPreview from "../sessions/SessionPreview";
import ISession from "../../models/ISession";

type Props = {
  doctor: any;
  planId: string;
  sectionLabel: string;

  sessions: Array<ISession>;
};

export default function SectionDisplay({ doctor, planId, sectionLabel, sessions }: Props) {
  return (
    <>
      {sessions.length > 0 && (
        <>
          <h3 style={{ padding: "0.5rem 0" }}>{sectionLabel}</h3>
          <section className="grid">
            {sessions.map(x => (
              <Link key={x._id} to={`plans/${planId}/sessions/${x._id}`}>
                <SessionPreview contact={doctor} session={x} />
              </Link>
            ))}
          </section>
        </>
      )}
    </>
  );
}
