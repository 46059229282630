import { RefObject } from "react";

export const clearCanvas = (canvasRef: RefObject<HTMLCanvasElement>) => {
  const canvas = canvasRef.current;

  if (!canvas) {
    return;
  }

  const context = canvas.getContext("2d");

  if (!context) {
    return;
  }

  context.clearRect(0, 0, canvas.width, canvas.height);
};

export const drawLine = (canvasRef: RefObject<HTMLCanvasElement>, color: string, thickness: number, [x1, y1, x2, y2]: number[]) => {
  const canvas = canvasRef.current;

  if (!canvas) {
    return;
  }

  const context = canvas.getContext("2d");

  if (!context) {
    return;
  }

  context.beginPath();
  context.strokeStyle = color;
  context.lineWidth = thickness;
  context.moveTo(x1, y1);
  context.lineTo(x2, y2);
  context.stroke();
  context.closePath();
};

export const getContainedSize = (el: HTMLVideoElement) => {
  const ratio = el.videoWidth / el.videoHeight;

  let width = el.clientHeight * ratio;
  let height = el.clientHeight;

  if (width > el.clientWidth) {
    width = el.clientWidth;
    height = el.clientWidth / ratio;
  }

  return [width, height];
};

export const renderFrame = (annotations: HTMLCanvasElement | null, recording: HTMLCanvasElement | null, video: HTMLVideoElement | null) => {
  if (!annotations || !recording || !video) {
    return;
  }

  const context = recording.getContext("2d");

  if (!context) {
    return;
  }

  context.drawImage(video, 0, 0);

  const [containedWidth, containedHeight] = getContainedSize(video);

  const deltaHeight = annotations.height - containedHeight;
  const deltaWidth = annotations.width - containedWidth;

  context.drawImage(annotations, deltaWidth / 2, deltaHeight / 2, containedWidth, containedHeight, 0, 0, recording.width, recording.height);
};

export const captureImage = (annotations: Array<HTMLCanvasElement>, video: HTMLVideoElement | null) => {
  if (!annotations || !video || annotations.length < 1) {
    return;
  }

  const canvas = document.createElement("canvas");
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;

  const context = canvas.getContext("2d");

  if (!context) {
    return;
  }

  context.drawImage(video, 0, 0);

  const [containedWidth, containedHeight] = getContainedSize(video);

  const deltaHeight = annotations[0].height - containedHeight;
  const deltaWidth = annotations[0].width - containedWidth;

  for (let i = 0; i < annotations.length; i++) {
    context.drawImage(annotations[i], deltaWidth / 2, deltaHeight / 2, containedWidth, containedHeight, 0, 0, video.videoWidth, video.videoHeight);
  }

  return canvas;
};
