import { History } from "history";
import React, { Component } from "react";
import { Link } from "react-router-dom";

import NotFoundModuleCss from "./NotFound.module.css";

type Props = {
  history: History;
};

export default class NotFound extends Component<Props> {
  handleGoBack(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.preventDefault();
    this.props.history.goBack();
  }

  render() {
    return (
      <div>
        <object className={NotFoundModuleCss.shapes} data="/images/notFound.svg" datatype="image/svg+xml" aria-label="test" />
        <div className={NotFoundModuleCss.messageBox}>
          <h1 className={NotFoundModuleCss.errorCode}>404</h1>
          <p>Page not found</p>
          <div>
            <div className={NotFoundModuleCss.actionBar}>
              <a className={NotFoundModuleCss.actionButton} href="/" onClick={e => this.handleGoBack(e)}>
                Go Back
              </a>
              <Link className={NotFoundModuleCss.actionButton} to="/">
                Go to Home Page
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
