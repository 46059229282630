import { getObjectIdTimestamp } from "../services/DateService";
import IPlan from "../models/IPlan";

// NOTE: array.sort() will always move undefined to the end. ECMA spec 15.4.4.11
export const compareDefined = (a: any, b: any): number => {
  const noA = a === null || a === undefined;
  const noB = b === null || b === undefined;

  if (noA && noB) {
    return 0;
  }

  if (noA) {
    return -1;
  }

  if (noB) {
    return 1;
  }

  return 0;
};

export const compareNumbers = (a: number, b: number) => a - b;

export const compareStrings = (a: string, b: string) => a.localeCompare(b, undefined, { sensitivity: "accent" });

export const compareProps = <T>(a: T, b: T, props: { [k in keyof T]?: number }) => {
  for (const key in props) {
    const direction = props[key] as number;
    const byDefined = compareDefined(a, b);

    if (byDefined) {
      return byDefined * direction;
    }

    const byPropDefined = compareDefined(a[key], b[key]);

    if (byPropDefined) {
      return byPropDefined * direction;
    }

    const valueA = (a[key] as unknown) as number | string;
    const valueB = (b[key] as unknown) as number | string;

    if (typeof valueA === "number" && typeof valueB === "number") {
      const byNumber = compareNumbers(valueA, valueB);

      if (byNumber) {
        return byNumber * direction;
      }
    } else if (typeof valueA === "string" && typeof valueB === "string") {
      const byString = compareStrings(valueA, valueB);

      if (byString) {
        return byString * direction;
      }
    }
  }

  return 0;
};

export const sortPlans = (a: IPlan, b: IPlan) => {
  if ((a.isFeatured && b.isFeatured) || (!a.isFeatured && !b.isFeatured)) {
    return new Date(getObjectIdTimestamp(b._id)).getTime() - new Date(getObjectIdTimestamp(a._id)).getTime();
  } else {
    return a.isFeatured ? -1 : 1;
  }
};
