import React, { FC, ReactElement, useState } from "react";
import Textarea from "react-textarea-autosize";

import useDebounce from "../../hooks/useDebounce";
import IPlan from "../../models/IPlan";
import { ActiveIcon, ArchiveIcon, DraftIcon } from "../core/Icons";
import PrettySelect from "../core/PrettySelect";
import Spinner from "../core/Spinner";
import SearchSelect, { SearchSelectItem } from "../core/SearchSelect";
import { usePlanTemplates } from "../../hooks/useCollection";

export type Props = {
  current: IPlan;
  isPending: boolean;
  onCancel: () => void;
  onSubmit: (item: Pick<IPlan, "_id" | "description" | "status" | "title">) => void;
};

const getStatusOptions = (): { label: string; icon: ReactElement; value: string; disabled?: boolean }[] => [
  {
    label: "Draft",
    icon: <DraftIcon />,
    value: "draft",
  },
  {
    label: "Active",
    icon: <ActiveIcon />,
    value: "active",
  },
  {
    label: "Archive",
    icon: <ArchiveIcon />,
    value: "archive",
  },
];

const validate = (status: string, title: string) => {
  if (!status) {
    return "Status is required";
  }

  if (!title || !title.trim()) {
    return "Title is required";
  }

  return null;
};

const ModifyPlanCard: FC<Props> = ({ current, isPending, onCancel, onSubmit }) => {
  useDebounce();

  const [description, setDescription] = useState(current.description || "");
  const [status, setStatus] = useState(current.status || "draft");
  const [title, setTitle] = useState(current.title || "");
  const [selectedPlanTemplate, setSelectedPlanTemplate] = useState<SearchSelectItem>();

  const statusOptions = getStatusOptions();

  const planTemplates = usePlanTemplates();
  const planTemplatesList = planTemplates && Object.values(planTemplates);

  switch (current.status) {
    default:
    case "draft": {
      statusOptions[2].disabled = true;
      break;
    }
    case "active": {
      statusOptions[0].disabled = true;
      break;
    }
    case "archive": {
      statusOptions[0].disabled = true;
      statusOptions[1].disabled = true;
      break;
    }
  }

  const validation = validate(status, title);

  return (
    <article data-cy="plan card">
      <h2>{current._id ? `Edit Plan: ${current.title}` : "Create Plan"}</h2>
      <form
        autoComplete="off"
        className="material"
        onSubmit={e => {
          e.preventDefault();
          onSubmit({ _id: selectedPlanTemplate?.id || "", description, status, title });
        }}
      >
        <section>
          <div className="form-group">
            <input
              autoComplete="none"
              autoFocus={true}
              className="form-control text-primary"
              data-cy="title"
              style={{ fontSize: "1.2rem" }}
              type="text"
              placeholder="add title here..."
              required={true}
              title="title"
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
          </div>
          <div className="form-group">
            <Textarea
              autoComplete="none"
              className="form-control autosize"
              data-cy="description"
              minRows={2}
              placeholder="add description here..."
              title="description"
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </div>
        </section>
        {!current ||
          (!current._id && planTemplatesList && planTemplatesList.length > 0 && (
            <div>
              <SearchSelect
                autoFocus={false}
                containerHeight="30vh"
                items={planTemplatesList.map(planTemplate => ({ id: planTemplate._id, title: planTemplate.title }))}
                sectionHeight="20vh"
                onCancel={undefined}
                onSelect={item => {
                  let selectedPlan: SearchSelectItem | undefined = undefined;
                  if (item.id !== selectedPlanTemplate?.id) {
                    selectedPlan = item;
                    if (title === "") {
                      setTitle(item.title);
                    }
                  }
                  setSelectedPlanTemplate(selectedPlan);
                  return Promise.resolve();
                }}
                selectedItem={selectedPlanTemplate}
              />
            </div>
          ))}
        <section>
          <div className="d-flex justify-content-between">
            <div style={{ width: "8rem" }}>
              <PrettySelect options={statusOptions} value={status} isOptionDisabled={x => x.disabled} isSearchable={false} onChange={e => setStatus(e as typeof status)} />
            </div>
            <div>
              <button type="button" className="action btn text-danger" disabled={isPending} onClick={() => onCancel()}>
                Cancel
              </button>
              <button title={validation || undefined} type="submit" className="action btn btn-primary" disabled={!!validation || isPending}>
                {isPending && <Spinner />} {isPending ? "Saving" : "Save"}
              </button>
            </div>
          </div>
        </section>
      </form>
    </article>
  );
};

export default ModifyPlanCard;
