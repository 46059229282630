import IPlan from "../models/IPlan";

type PlansAction = {
  collection?: IPlan[];
  item?: IPlan;
  type: "SET_PLAN" | "SET_PLANS" | "RESET";
};

export type PlansState = {
  [key: string]: IPlan;
};

export const plansReducer = (state: PlansState | null = null, action: PlansAction) => {
  const { collection, item, type } = action;

  switch (type) {
    case "SET_PLAN": {
      return { ...state, [item!._id]: item! };
    }
    case "SET_PLANS": {
      return collection!.reduce((prev, curr) => ({ ...prev, [curr._id]: curr }), {} as PlansState);
    }
    case "RESET": {
      return null;
    }
    default: {
      return state;
    }
  }
};
