import classNames from "classnames";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as poseDetection from "@tensorflow-models/pose-detection";
import "@tensorflow/tfjs-backend-webgl";

import useAnimationFrame from "../../hooks/useAnimationFrame";
import useCustomEvent from "../../hooks/useCustomEvent";
import useKeyActive from "../../hooks/useKeyActive";
import useKeyListener from "../../hooks/useKeyListener";
import IVideo from "../../models/IVideo";
import Turtle from "../../svg/Turtle";
import { clearCanvas, getContainedSize, renderFrame } from "../../utils/canvas";
import Spinner from "../core/Spinner";
import Annotations, { Mode } from "./Annotations";
import { PersonIcon } from "../core/Icons";
import { drawResults } from "./DrawPoses";

const slowSpeed = 0.25;

export type Props = {
  handleOnCancel: () => void;
  handleOnMediaAdd: (value: Blob) => void;
  isVideoPending?: boolean;
  uploadProgress?: number;
  video: IVideo;
};

const AudioVideoRecording: FC<Props> = ({ handleOnCancel, handleOnMediaAdd, isVideoPending, uploadProgress, video }) => {
  const annotationsRef = useRef<HTMLCanvasElement>(null);
  const recordingRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const [annotationsMode, setAnnotationsMode] = useState<Mode>("select");
  const [isRecording, setIsRecording] = useState(false);
  const [isRendering, setIsRendering] = useState(false);
  const [isSlow, setIsSlow] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [recordingBlob, setRecordingBlob] = useState<Blob | null>(null);
  const [recorder, setRecorder] = useState<MediaRecorder>();
  const [showPose, setShowPose] = useState(false);

  let detectorRef = useRef<poseDetection.PoseDetector | null>(null);
  const poseModel = poseDetection.SupportedModels.MoveNet;
  const detectorConfig = { modelType: poseDetection.movenet.modelType.SINGLEPOSE_LIGHTNING };

  const dispatchReviewEnd = useCustomEvent("recordReviewEnd");
  const drawCurrentAnnotations = useCustomEvent("drawCurrentAnnotations");
  const disableAnnotations = useKeyActive("Shift");

  const annotations = annotationsRef.current;
  const recording = recordingRef.current;
  const videoEl = videoRef.current;

  useAnimationFrame(() => renderFrame(annotations, recording, videoEl), isRendering, [annotations, recording, videoEl]);

  useKeyListener(
    " ",
    e => {
      if (!videoEl || !isRecording) {
        return;
      }

      e.preventDefault();

      videoEl.paused ? videoEl.play() : videoEl.pause();
    },
    [isRecording, videoEl],
  );

  useKeyListener(
    "Enter",
    e => {
      if (recordingBlob) {
        return;
      }

      e.preventDefault();

      isRecording ? stopRecording() : startRecording();
    },
    [isRecording, recordingBlob],
  );

  useKeyListener(
    "s",
    e => {
      e.preventDefault();

      setIsSlow(!isSlow);
    },
    [isRecording, isSlow],
  );

  useEffect(() => {
    if (!videoEl) {
      return;
    }

    videoEl.playbackRate = isSlow ? slowSpeed : 1;
  }, [isSlow, videoEl]);

  useEffect(() => {
    poseDetection.createDetector(poseModel, detectorConfig).then(dect => {
      detectorRef.current = dect;
    });
  }, [poseModel, detectorConfig]);

  const getPose = useCallback(() => {
    if (showPose && detectorRef.current !== null && videoRef.current !== null && annotationsRef.current !== null) {
      const [containedWidth, containedHeight] = getContainedSize(videoRef.current);
      const newCanvas = document.createElement("canvas");
      newCanvas.height = annotationsRef.current.height;
      newCanvas.width = annotationsRef.current.width;
      const ctx = newCanvas.getContext("2d");
      const widthOffset = (videoRef.current.clientWidth - containedWidth) / 2;
      const heightOffset = (videoRef.current.clientHeight - containedHeight) / 2;
      ctx?.drawImage(videoRef.current, 0, 0, videoRef.current.videoWidth, videoRef.current.videoHeight, widthOffset, heightOffset, containedWidth, containedHeight);

      detectorRef.current.estimatePoses(newCanvas).then(poses => {
        if (poses && poses.length > 0 && annotationsRef.current !== null) {
          drawResults(poses, annotationsRef.current, poseModel);
        }
      });
    }
  }, [poseModel, showPose]);

  useEffect(() => {
    if (showPose) {
      getPose();
    }
  }, [getPose, detectorRef, showPose]);

  const handleNewPose = () => {
    if (showPose) {
      clearCanvas(annotationsRef);
      drawCurrentAnnotations();
      getPose();
    }
  };

  const showControls = disableAnnotations || annotationsMode === "select";

  const startRecording = async () => {
    const recording = recordingRef.current as any;
    const video = videoRef.current;

    if (!recording || !video) {
      return;
    }

    let audioStream: MediaStream;
    let recordingStream: MediaStream;

    try {
      audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      recordingStream = recording.captureStream();
    } catch {
      setHasError(true);
      return;
    }

    const fullStream = new MediaStream([...recordingStream.getVideoTracks(), ...audioStream.getAudioTracks()]);

    const r = new MediaRecorder(fullStream);
    const blob: Blob[] = [];

    r.ondataavailable = e => blob.push(e.data);

    r.onstop = () => {
      audioStream.getTracks().forEach(x => x.stop());
      video.pause();

      setRecordingBlob(new Blob(blob));
      setIsRecording(false);

      dispatchReviewEnd();

      setIsRendering(false);
    };

    setIsRendering(true);
    r.start();

    video.currentTime = 0;
    video.play();

    setRecorder(r);
    setIsRecording(true);
    setRecordingBlob(null);

    if (hasError) {
      setHasError(false);
    }
  };

  const stopRecording = () => {
    recorder!.stop();
  };

  const saveRecording = () => {
    if (recordingBlob === null) {
      return;
    }

    handleOnMediaAdd(recordingBlob);
  };

  const blobUrl = useMemo(() => (recordingBlob && URL.createObjectURL(recordingBlob)) || undefined, [recordingBlob]);

  return (
    <div style={{ position: "fixed", top: 0, left: 0, height: "100%", width: "100%", zIndex: 999, background: "black" }}>
      <div style={{ top: 0, left: 0, display: recordingBlob || showControls ? undefined : "none", padding: "1rem", position: "absolute", zIndex: 1 }}>
        <div style={{ background: "white", padding: ".25rem", borderRadius: ".5rem" }}>
          <button type="button" className="action btn text-danger" disabled={isVideoPending} onClick={handleOnCancel}>
            Cancel
          </button>
          <button type="button" className="action btn btn-primary" disabled={!recordingBlob || isVideoPending} onClick={saveRecording}>
            {isVideoPending && <Spinner />} {isVideoPending ? `Uploading${uploadProgress ? ` ${uploadProgress}%` : ""}` : "Upload"}
          </button>
        </div>
      </div>

      <video
        crossOrigin="anonymous"
        muted={true}
        style={{ background: "black", height: "100%", width: "100%", objectFit: "contain", display: recordingBlob ? "none" : "block" }}
        ref={videoRef}
        controls={showControls}
        onPause={handleNewPose}
        onLoadedMetadata={e => {
          const recording = recordingRef.current;
          const video = e.currentTarget;

          if (recording) {
            recording.height = video.videoHeight;
            recording.width = video.videoWidth;
          }
        }}
        onTimeUpdate={({ currentTarget }) => {
          handleNewPose();
          const delta = currentTarget.duration - currentTarget.currentTime;

          if (delta < 0.5) {
            currentTarget.pause();
            currentTarget.currentTime = currentTarget.duration - 0.01;
          }
        }}
      >
        {video.sasMp4 && <source src={video.sasMp4} />}
        <source src={video.sas} />
      </video>

      {!recordingBlob && <Annotations ref={annotationsRef} disabled={showControls} onModeChange={setAnnotationsMode} idealPoseImageSelected={undefined} />}

      <canvas ref={recordingRef} style={{ position: "absolute", top: -2000, left: -2000 }} />

      {hasError && <p className="text-danger">Error getting media capture or microphone, check it&apos;s plugged in!</p>}

      {!recordingBlob && (
        <div className={classNames("capture-actions", showControls ? "" : "hide")}>
          <div className={classNames("recordIcon", isRecording ? "stop" : "start")}>
            <button type="button" disabled={isVideoPending} onClick={() => (isRecording ? stopRecording() : startRecording())} />
          </div>
          <div className={classNames("slowIcon", "ml-2", isSlow && "active")} onClick={() => setIsSlow(!isSlow)}>
            <Turtle bodyColor="white" shellColor="white" />
          </div>
          <div title="pose" className={classNames("poseIcon fa-lg", showPose && "active")} onClick={() => setShowPose(!showPose)}>
            <PersonIcon />
          </div>
        </div>
      )}

      <video style={{ background: "black", height: "100%", width: "100%", display: isRecording || !recordingBlob ? "none" : "block" }} src={blobUrl} controls />
    </div>
  );
};

export default AudioVideoRecording;
