import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import IAssessment from "../models/IAssessment";
import IContact from "../models/IContact";
import IEducation from "../models/IEducation";
import IPlan from "../models/IPlan";
import IVideo from "../models/IVideo";
import { getContacts } from "../services/ContactsService";
import { getPlans } from "../services/PlansService";
import { getEducations } from "../services/EducationsService";
import { getNotifications } from "../services/NotificationsService";
import { getVideoLibrary } from "../services/VideoLibraryService";
import { getAssessments } from "../services/AssessmentsService";
import { IAppState } from "../store/Root";
import useAuth from "./useAuth";
import { getPlanTemplates } from "../services/PlanTemplatesService";

const useCollection = <T>(actionName: string, selectorFn: (state: IAppState) => { [key: string]: T } | null, fetchFn: () => Promise<T[]>) => {
  const dispatch = useDispatch();

  const auth = useAuth();
  const collection = useSelector(selectorFn);

  useEffect(() => {
    if (!auth) {
      return;
    }

    if (!collection) {
      fetchFn()
        .then(x => dispatch({ type: actionName, collection: x }))
        .catch(err => console.error(err));
    }
  }, [actionName, auth, dispatch, collection, fetchFn]);

  return collection;
};

export const useAssessments = () => useCollection<IAssessment>("SET_ASSESSMENTS", x => x.assessments, getAssessments);
export const useContacts = () => useCollection<IContact>("SET_CONTACTS", x => x.contacts, getContacts);
export const useNotifications = () =>
  useCollection<any>("SET_NOTIFICATIONS", x => (x.notifications && x.notifications.loaded ? x.notifications.notifications : null), getNotifications);
export const usePlans = () => useCollection<IPlan>("SET_PLANS", x => x.plans, getPlans);
export const usePlanTemplates = () => useCollection<IPlan>("SET_PLAN_TEMPLATES", x => x.planTemplates, getPlanTemplates);
export const useVideoLibrary = () => useCollection<IVideo>("SET_VIDEO_LIBRARY", x => x.videoLibrary, getVideoLibrary);
export const useEducations = () => useCollection<IEducation>("SET_EDUCATIONS", x => x.educations, getEducations);

export default useCollection;
