const databaseName = "telecarerx";
const storeName = "files";

// A function to store a blob in IndexedDB
export function storeBlob(value: any, key: any, onSuccess?: Function, onError?: (error: Event) => void) {
  // Open the database
  let request = indexedDB.open(databaseName, 2);
  request.onsuccess = function (event) {
    // Get the database object
    if (event.target) {
      // @ts-ignore
      let db = event.target.result as IDBDatabase;
      let store: IDBObjectStore;
      // Create a transaction and an object store
      let tx = db.transaction(storeName, "readwrite");
      store = tx.objectStore(storeName);
      // Store the blob with the given key
      store.put(value, key);
      // Handle errors and success
      tx.oncomplete = function () {
        console.log("Transaction completed");
        if (onSuccess) {
          onSuccess();
        }
      };
      tx.onerror = function (error) {
        console.log("Transaction failed: " + error);
        if (onError) {
          onError(error);
        }
      };
    }
  };
  request.onerror = function (error) {
    console.log("Database open failed: " + error);
    if (onError) {
      onError(error);
    }
  };
}

export function getAllBlob(onSuccess: Function, onError?: (error: Event) => void) {
  // Open the database
  let request = indexedDB.open(databaseName);
  request.onupgradeneeded = function (event) {
    // @ts-ignore
    const db = event.target.result;
    db.createObjectStore("files");
  };
  request.onsuccess = function (event) {
    // Get the database object
    // @ts-ignore
    let db = event.target.result as IDBDatabase;
    // Create a transaction and an object store
    let tx = db.transaction(storeName, "readonly");
    let store = tx.objectStore(storeName);
    let items = store.getAll();
    items.onsuccess = function (event) {
      // @ts-ignore
      onSuccess(event.target.result);
    };
    items.onerror = function (error) {
      console.log("Get all request failed: " + error);
      if (onError) {
        onError(error);
      }
    };
  };
  request.onerror = function (error) {
    console.log("Database open failed: " + error);
    if (onError) {
      onError(error);
    }
  };
}

export function deleteBlob(key: any, onSuccess?: Function, onError?: (error: Event) => void) {
  // Open the database
  let request = indexedDB.open(databaseName);
  request.onsuccess = function (event) {
    // Get the database object
    // @ts-ignore
    let db = event.target.result as IDBDatabase;
    // Create a transaction and an object store
    let tx = db.transaction(storeName, "readwrite");
    let store = tx.objectStore(storeName);
    // Get the blob with the given key
    let deleteRequest = store.delete(key);
    deleteRequest.onsuccess = function (event) {
      // @ts-ignore
      onSuccess(event.target.result);
    };
    deleteRequest.onerror = function (error) {
      console.log("Delete request failed: " + error);
      if (onError) {
        onError(error);
      }
    };
  };
  request.onerror = function (error) {
    console.log("Database open failed: " + error);
    if (onError) {
      onError(error);
    }
  };
}

// A function to retrieve a blob from IndexedDB
export function retrieveBlob(key: any, onSuccess: Function, onError?: (error: Event) => void) {
  // Open the database
  let request = indexedDB.open(databaseName);

  request.onsuccess = function (event) {
    // Get the database object
    // @ts-ignore
    let db = event.target.result as IDBDatabase;
    if (!db.objectStoreNames.contains(storeName)) {
      if (onError) {
        onError(new Event("IndexedDBStoreMissing"));
      }
      return;
    }
    // Create a transaction and an object store
    let tx = db.transaction(storeName, "readonly");
    let store = tx.objectStore(storeName);
    // Get the blob with the given key
    let getRequest = store.get(key);
    getRequest.onsuccess = function (event) {
      // @ts-ignore
      onSuccess(event.target.result);
    };
    getRequest.onerror = function (error) {
      console.log("Get request failed: " + error);
      if (onError) {
        onError(error);
      }
    };
  };
  request.onerror = function (error) {
    console.log("Database open failed: " + error);
    if (onError) {
      onError(error);
    }
  };
}
