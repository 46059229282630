import React, { FC, useState } from "react";
import { toast } from "react-toastify";

import ISession from "../../models/ISession";
import { formatObjectId } from "../../services/DateService";
import { CalendarIcon, CreateIcon, DeleteIcon, EditIcon, ExpandIcon, VerticalDotsIcon } from "../core/Icons";
import LoadingBlock from "../core/LoadingBlock";
import ModifyPlanModal from "../plans/ModifyPlanModal";
import ModifySessionModal from "../sessions/ModifySessionModal";
import SessionsDisplay, { EditSession } from "../caseload/SessionsDisplay";
import ConfirmCard from "../core/ConfirmCard";
import ModalSlim from "../core/ModalSlim";
import { addSessionTemplate, createPlanTemplate, deletePlanTemplate, savePlanTemplate } from "../../services/PlanTemplatesService";
import { useDispatch } from "react-redux";
import IPlan from "../../models/IPlan";

type Props = {
  planTemplates: IPlan[] | null;
};

const PlanTemplates: FC<Props> = ({ planTemplates }) => {
  const dispatch = useDispatch();
  const [editPlan, setEditPlan] = useState<IPlan>();
  const [isSavingPlan, setIsSavingPlan] = useState(false);

  const [editSession, setEditSession] = useState<ISession & { plan: IPlan }>();
  const [isSavingSession, setIsSavingSession] = useState(false);
  const [planTemplateToDelete, setPlanTemplateToDelete] = useState<IPlan>();

  const deletePlanTemplateHandler = () => {
    let promise = Promise.resolve();
    if (planTemplateToDelete) {
      setIsSavingPlan(true);
      promise = deletePlanTemplate(planTemplateToDelete._id)
        .then(() => {
          dispatch({ type: "DELETE_PLAN_TEMPLATE", item: { _id: planTemplateToDelete._id } });
          setPlanTemplateToDelete(undefined);
          toast.success("Plan Template deleted!");
        })
        .catch(err => {
          toast.error(`Error deleting plan template: ${err.message}`);
        })
        .finally(() => {
          setIsSavingPlan(false);
        });
    }

    return promise;
  };

  const editSessionHandler = (session: EditSession) => {
    setEditSession(session);
  };

  return (
    <main>
      <article>
        <span style={{ cursor: "pointer" }} onClick={() => setEditPlan({} as IPlan)}>
          <CreateIcon /> Create Plan
        </span>
      </article>
      {planTemplates ? (
        planTemplates.map(plan => (
          <article data-cy={`plan template title ${plan.title}`} key={plan._id}>
            <h2 style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div>Plan: {plan.title}</div>
              <div>
                <button
                  data-cy="expand caseload"
                  className="btn btn-secondary"
                  style={{ marginLeft: "1rem" }}
                  type="button"
                  data-toggle="collapse"
                  data-target={`#collapsePlanTemplate-${plan._id}`}
                  aria-expanded="false"
                  aria-controls={`collapsePlanTemplate-${plan._id}`}
                >
                  <ExpandIcon />
                </button>
              </div>
            </h2>
            <div className={"collapse"} id={`collapsePlanTemplate-${plan._id}`}>
              <section>
                <div data-cy="plan template actions" data-toggle="dropdown" style={{ cursor: "pointer", float: "right", minWidth: "1rem", textAlign: "center" }}>
                  <VerticalDotsIcon />
                </div>
                <div className="dropdown-menu dropdown-menu-right">
                  <div className="dropdown-item" onClick={() => setEditPlan(plan)}>
                    <EditIcon /> Edit Plan
                  </div>
                  <div className="dropdown-item" onClick={() => setEditSession({ plan } as ISession & { plan: IPlan })}>
                    <CreateIcon /> Create Session
                  </div>
                  <div className="dropdown-divider"></div>
                  <div className="dropdown-item" onClick={() => setPlanTemplateToDelete(plan)}>
                    <DeleteIcon /> Delete Plan Template
                  </div>
                </div>
                <div className="info-items">
                  {/* <div>
                    <StatusIcon status={plan.status} />
                    <div>{plan.status}</div>
                  </div> */}
                  <div>
                    <CalendarIcon />
                    <div>{formatObjectId(plan._id)}</div>
                  </div>
                </div>
                <div style={{ whiteSpace: "pre-wrap" }}>{plan.description}</div>
              </section>
              <section className="bg-secondary">
                <h3 className="text-primary">Sessions</h3>
                <SessionsDisplay plan={plan} handleEditSession={editSessionHandler} />
              </section>
            </div>
          </article>
        ))
      ) : (
        <LoadingBlock />
      )}
      {editPlan && (
        <ModifyPlanModal
          current={editPlan}
          isPending={isSavingPlan}
          onCancel={() => setEditPlan(undefined)}
          onSubmit={x => {
            setIsSavingPlan(true);

            const savePromise = editPlan._id ? savePlanTemplate({ ...editPlan, description: x.description, status: x.status, title: x.title }) : createPlanTemplate({ ...x });
            savePromise
              .then(planTemplate => {
                dispatch({ type: "SET_PLAN_TEMPLATE", item: planTemplate });
                toast.success("Plan template saved!");
                setEditPlan(undefined);
              })
              .catch(err => toast.error(err.message))
              .finally(() => setIsSavingPlan(false));
          }}
        />
      )}
      {editSession && (
        <ModifySessionModal
          current={editSession}
          isPending={isSavingSession}
          planId={editSession.plan._id}
          onCancel={() => setEditSession(undefined)}
          onSubmit={x => {
            setIsSavingSession(true);

            return addSessionTemplate(editSession.plan._id, { ...editSession, ...x })
              .then(newSession => {
                setEditSession(undefined);
                dispatch({ type: "SET_PLAN_TEMPLATE", item: { ...editSession.plan, sessions: [...editSession.plan.sessions, newSession] } });
                toast.success("Plan template saved!");
                return newSession;
              })
              .catch(err => toast.error(err.message))
              .finally(() => setIsSavingSession(false));
          }}
        />
      )}
      {planTemplateToDelete && (
        <ModalSlim>
          <ConfirmCard title="Delete Plan Template" question="Delete Plan Templates?" onCancel={() => setPlanTemplateToDelete(undefined)} onSubmit={deletePlanTemplateHandler} />
        </ModalSlim>
      )}
    </main>
  );
};

export default PlanTemplates;
