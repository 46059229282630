import { DependencyList, useCallback, useEffect, useRef } from "react";

const useAnimationFrame = (callback: () => void, isActive: boolean = true, deps: DependencyList = []) => {
  const ref = useRef<number | null>(null);
  const frozen = useCallback(callback, deps);

  useEffect(() => {
    if (!isActive) {
      return;
    }

    const queue = () => {
      ref.current = requestAnimationFrame(() => {
        frozen();
        queue();
      });
    };

    queue();

    return () => {
      if (ref.current) {
        cancelAnimationFrame(ref.current);
        ref.current = null;
      }
    };
  }, [frozen, isActive]);
};

export default useAnimationFrame;
