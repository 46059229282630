import { combineReducers } from "redux";

import IAuth from "../models/IAuth";
import { assessmentsReducer, AssessmentsState } from "./Assessments";
import { authReducer } from "./Auth";
import { contactsReducer, ContactsState } from "./Contacts";
import { educationsReducer, EducationsState } from "./Educations";
import { fileUploadReducer, FileUploadState } from "./FileUpload";
import { notificationsReducer, NotificationsState } from "./Notifications";
import { plansReducer, PlansState } from "./Plans";
import { planTemplatesReducer, PlanTemplatesState } from "./PlanTemplates";
import { settingsReducer, SettingsState } from "./Settings";
import { videoLibraryReducer, VideoLibraryState } from "./VideoLibrary";

export interface IAppState {
  assessments: AssessmentsState | null;
  auth: IAuth | null;
  contacts: ContactsState | null;
  educations: EducationsState | null;
  fileUpload: FileUploadState;
  notifications: NotificationsState | null;
  plans: PlansState | null;
  planTemplates: PlanTemplatesState | null;
  settings: SettingsState;
  videoLibrary: VideoLibraryState | null;
}

export const rootReducer = combineReducers<IAppState>({
  assessments: assessmentsReducer,
  auth: authReducer,
  contacts: contactsReducer,
  educations: educationsReducer,
  fileUpload: fileUploadReducer,
  notifications: notificationsReducer,
  plans: plansReducer,
  planTemplates: planTemplatesReducer,
  settings: settingsReducer,
  videoLibrary: videoLibraryReducer,
});
