import IAuth from "../models/IAuth";

type AuthAction = {
  item?: IAuth;
  type: string;
};

export const authReducer = (state: IAuth | null = null, action: AuthAction): IAuth | null => {
  const { item, type } = action;

  switch (type) {
    case "SET_AUTH": {
      return state ? { ...state, ...item } : item || null;
    }
    case "RESET": {
      return null;
    }
    default: {
      return state;
    }
  }
};
