import React, { FC } from "react";

import IProfile from "../../models/IProfile";
import { msalConfig } from "../../authConfig";
import { getAuthDomain } from "../../utils/config";

type Props = {
  initEmail?: string;
  initFirstName?: string;
  initLastName?: string;
  initPassword?: string;
  initVerifyPassword?: string;
  isSaving?: boolean;
  onSubmit: (x: IProfile) => void;
};

const ProfileCard: FC<Props> = props => {
  const handleEditProfile = () => {
    sessionStorage.clear();
    window.location.replace(
      `https://${getAuthDomain()}.b2clogin.com/${getAuthDomain()}.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_edit_profile&client_id=${
        msalConfig.auth.clientId
      }&nonce=defaultNonce&redirect_uri=${encodeURIComponent(`${window.location.origin}/dashboard`)}&scope=openid&response_type=id_token&prompt=login&login_hint=${
        props.initEmail
      }`,
    );
  };

  const handleResetPassword = () => {
    sessionStorage.clear();
    window.location.replace(
      `https://${getAuthDomain()}.b2clogin.com/${getAuthDomain()}.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_password_reset&client_id=${
        msalConfig.auth.clientId
      }&nonce=defaultNonce&redirect_uri=${encodeURIComponent(`${window.location.origin}/dashboard`)}&scope=openid&response_type=id_token&prompt=login`,
    );
  };

  return (
    <main>
      <article data-cy="profile card">
        <h2>Profile</h2>
        <section>
          <div className="row">
            <div className="col form-group">
              <label>
                Email <input data-cy="email" type="email" className="form-control" autoComplete="username" value={props.initEmail} disabled required />
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-sm form-group">
              <label>
                First Name <input data-cy="first name" type="text" className="form-control" autoComplete="given-name" value={props.initFirstName} disabled required />
              </label>
            </div>
            <div className="col-sm form-group">
              <label>
                Last Name <input data-cy="last name" type="text" className="form-control" autoComplete="family-name" value={props.initLastName} disabled required />
              </label>
            </div>
          </div>
          <div style={{ position: "absolute", top: -1000 }}>
            <label>
              Hidden Username for successful autocomplete
              <input type="text" autoComplete="username" value={props.initEmail} readOnly={true} tabIndex={-1} />
            </label>
          </div>
          <div className="row">
            <div className="col">
              <button type="submit" className="btn btn-primary" onClick={handleEditProfile}>
                Edit
              </button>
            </div>
            <div className="col">
              <button type="submit" className="btn btn-primary" onClick={handleResetPassword}>
                Change Password
              </button>
            </div>
          </div>
        </section>
      </article>
    </main>
  );
};

export default ProfileCard;
