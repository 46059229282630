import React, { FC } from "react";
import IVideo from "../../models/IVideo";
import VideoDisplay from "../video/VideoDisplay";
import { CalendarIcon } from "../core/Icons";
import { formatObjectId } from "../../services/DateService";

export type Props = {
  video: IVideo;
};

const AssessmentPatientVideoDisplay: FC<Props> = ({ video }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div>
        <VideoDisplay sas={video.sas} sasMp4={video.sasMp4} />
      </div>

      <section className="border-without-top p-2" style={{ flex: "1 1 auto" }}>
        <h3>{video.name}</h3>
        <div className="info-wrap">
          <div>
            <CalendarIcon /> {formatObjectId(video.dateAdded !== "000000000000000000000000" ? video.dateAdded! : video._id)}
          </div>
        </div>
      </section>
    </div>
  );
};

export default AssessmentPatientVideoDisplay;
