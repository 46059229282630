import React, { FC } from "react";

import Modal from "../core/Modal";
import ModifyVideoCard, { Props as CardProps } from "./ModifyVideoCard";

type Props = CardProps;

const ModifyVideoModal: FC<Props> = props => (
  <Modal onClose={props.onCancel}>
    <ModifyVideoCard {...props} />
  </Modal>
);

export default ModifyVideoModal;
