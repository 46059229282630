import React, { FC } from "react";

import Modal from "../core/Modal";
import LeadCard, { Props } from "./LeadCard";

const LeadModal: FC<Props> = props => (
  <Modal onClose={props.onCancel}>
    <LeadCard {...props} />
  </Modal>
);

export default LeadModal;
