import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import useAuth from "../../hooks/useAuth";
import { useEducations } from "../../hooks/useCollection";
import IVideo from "../../models/IVideo";
import { addVideoToEducation, addVideoToLibrary, deleteVideo } from "../../services/EducationsService";
import AssessmentPatientVideoDisplay from "../assessments/AssessmentPatientVideoDisplay";
import ConfirmCard from "../core/ConfirmCard";
import { CreateIcon, ExpandIcon, UploadIcon, VerticalDotsIcon, VideoIcon } from "../core/Icons";
import ModalSlim from "../core/ModalSlim";
import PatientEducationVideoDisplay from "./PatientEducationVideoDisplay";
import { Role } from "../../models/IAuth";
import UploadDeviceVideo from "../video/UploadDeviceVideo";
import { uploadFile } from "../../services/FileService";
import VideoRecorder from "../video/videoRecorder/VideoRecorder";
import ModifyVideoModal from "../videoLibrary/ModifyVideoModal";

type Props = {
  doctorID: string;
  patientID: string;
};

const PatientEducation: FC<Props> = ({ doctorID, patientID }) => {
  const auth = useAuth()!;
  const dispatch = useDispatch();
  const educations = useEducations();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [recordingMode, setRecordingMode] = useState<"file" | "camera" | undefined>();
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [video, setVideo] = useState<Blob>();
  const [videos, setVideos] = useState<any>();
  const [videoToDelete, setVideoToDelete] = useState<IVideo>();
  const [videoToAddToLibrary, setVideoToAddToLibrary] = useState<IVideo>();

  useEffect(() => {
    let vids: any = [];
    if (educations && educations[patientID] && educations[patientID].videos.length > 0) {
      vids = educations[patientID].videos.map((vid, idx) => {
        return auth.role === Role.doctor || auth.role === Role.organizationadmin ? (
          <PatientEducationVideoDisplay key={`education-video-${vid._id}-${idx}`} onAddToLibrary={handleAddToLibrary} onVideoDelete={setVideoToDelete} video={vid} />
        ) : (
          <AssessmentPatientVideoDisplay key={`education-video-${vid._id}-${idx}`} video={vid} />
        );
      });
    }
    setVideos(vids);
  }, [patientID, educations, auth.role]);

  const handleVideoDelete = (vid: IVideo) => {
    return deleteVideo(vid._id, patientID)
      .then(() => {
        dispatch({ type: "DELETE_EDUCATION_VIDEO", item: educations![patientID], videoId: vid._id });
        toast.success("Video deleted");
      })
      .catch(() => toast.error("Error deleting video"));
  };

  const handleVideoAdd = (video: IVideo) => {
    return addVideoToEducation(video._id, video.name.trim(), patientID, doctorID)
      .then(ed => {
        dispatch({ type: "SET_EDUCATION", item: ed });
        toast.success("Video added");
      })
      .catch(() => {
        toast.error("Error adding video");
      });
  };

  const handleAddToLibrary = (video: IVideo) => {
    setVideoToAddToLibrary(video);
  };

  const handleSaveToLibrary = (file: File, name: string) => {
    setIsUploading(true);
    addVideoToLibrary(videoToAddToLibrary?._id || "", name.trim(), patientID, doctorID)
      .then(item => {
        setVideoToAddToLibrary(undefined);
        dispatch({ type: "SET_VIDEO_LIBRARY_VIDEO", item });
        toast.success("Video added to library");
      })
      .catch(err => toast.error(err.message))
      .finally(() => setIsUploading(false));
  };

  const handleVideoUpload = async () => {
    setIsUploading(true);

    const videoId = await uploadFile(video!, undefined, (name, percentComplete) => {
      setUploadProgress(percentComplete);
    });

    return addVideoToEducation(videoId, "", patientID, doctorID)
      .then(ed => {
        dispatch({ type: "SET_EDUCATION", item: ed });
        setVideo(undefined);
        setRecordingMode(undefined);
        setUploadProgress(0);
        toast.success("Video uploaded");
      })
      .catch(() => {
        toast.error("Error uploading video");
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  return (
    <article>
      <h2 style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <div>Patient Education</div>
        <div>
          <button
            data-cy="expand patientEducation"
            className="btn btn-secondary"
            style={{ marginLeft: "1rem" }}
            type="button"
            data-toggle="collapse"
            data-target="#collapsePatientEducation"
            aria-expanded="false"
            aria-controls="collapsePatientEducation"
          >
            <ExpandIcon />
          </button>
        </div>
      </h2>
      <div className="collapse" id="collapsePatientEducation">
        {(auth.role === Role.doctor || auth.role === Role.organizationadmin) && (
          <section>
            &nbsp;
            <div data-cy="plan actions" data-toggle="dropdown" style={{ cursor: "pointer", float: "right", minWidth: "1rem", textAlign: "center" }}>
              <VerticalDotsIcon />
            </div>
            <div className="dropdown-menu dropdown-menu-right">
              <div
                className="dropdown-item"
                onClick={() => {
                  const args = {
                    onSelect: (video: IVideo) => {
                      return handleVideoAdd(video);
                    },
                  };
                  const event = new CustomEvent("showSelectExample", { detail: args });
                  window.dispatchEvent(event);
                }}
              >
                <CreateIcon /> Add Video
              </div>
              <div className="dropdown-item" onClick={() => setRecordingMode("camera")}>
                <VideoIcon /> Record Video
              </div>
              <div className="dropdown-item" onClick={() => setRecordingMode("file")}>
                <UploadIcon /> Upload Video
              </div>
            </div>
          </section>
        )}
        <section className="bg-secondary">
          <h3 className="text-primary">Videos</h3>
          <div className="grid" style={{ margin: "0 -1rem -1rem -1rem" }}>
            {videos}
          </div>
        </section>
      </div>
      {recordingMode === "file" && (
        <UploadDeviceVideo
          canUpload={!!video}
          isPending={isUploading}
          onCancel={() => {
            setRecordingMode(undefined);
            setVideo(undefined);
          }}
          onRecordingComplete={setVideo}
          onSaveRecording={handleVideoUpload}
          recordingMode={recordingMode}
          uploadProgress={uploadProgress}
        />
      )}
      {recordingMode === "camera" && (
        <VideoRecorder
          canUpload={!!video}
          isPending={isUploading}
          onCancel={() => {
            setRecordingMode(undefined);
            setVideo(undefined);
          }}
          onRecordingComplete={setVideo}
          onSaveRecording={handleVideoUpload}
          recordingMode={recordingMode}
          uploadProgress={uploadProgress}
        />
      )}
      {videoToDelete && (
        <ModalSlim>
          <ConfirmCard
            question="Delete this education video?"
            onCancel={() => setVideoToDelete(undefined)}
            confirmLabel="Delete"
            confirmPendingLabel="Deleting"
            onSubmit={() => handleVideoDelete(videoToDelete)}
          />
        </ModalSlim>
      )}
      {videoToAddToLibrary && (
        <ModifyVideoModal current={videoToAddToLibrary} isPending={isUploading} onCancel={() => setVideoToAddToLibrary(undefined)} onSubmit={handleSaveToLibrary} />
      )}
    </article>
  );
};

export default PatientEducation;
