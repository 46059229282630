import React, { FC, useMemo } from "react";

import useKeyListener from "../../hooks/useKeyListener";
import noop from "../../utils/noop";

export type Props = {
  onClose?: () => void;
};

// Deprecated
const Modal: FC<Props> = ({ children, onClose = noop }) => {
  const initialTop = useMemo(() => {
    const scrollTop = document.querySelector("html")!.scrollTop;

    return scrollTop + window.innerHeight / 2;
  }, []);

  useKeyListener("Escape", () => onClose());

  return (
    <>
      <div className="modal-slim-overlay"></div>
      <div className="modal-slim" style={{ top: initialTop }}>
        {children}
      </div>
    </>
  );
};

export default Modal;
