import React, { FC } from "react";

import { useContacts, usePlans } from "../../hooks/useCollection";
import PatientAccount from "../../models/PatientAccount";
import { addPatient } from "../../services/CaseloadService";
import Dashboard from "./Dashboard";
import { Role } from "../../models/IAuth";
import { compareProps } from "../../utils/sort";
import IPlan from "../../models/IPlan";
import IAttachment from "../../models/IAttachment";
import IContact from "../../models/IContact";
import IComment from "../../models/IComment";
import IVideo from "../../models/IVideo";

const DashboardPage: FC = () => {
  const contacts = useContacts();
  const plans = usePlans();
  const caseload = contacts && Object.values(contacts).filter(x => !x.self && x.role === Role.patient);
  const plansList = plans && Object.values(plans);
  const doctors = ((contacts && Object.values(contacts).filter(x => x.role === Role.doctor || x.role === Role.organizationadmin)) || []).sort((a, b) => {
    if (a.self && !b.self) return -1;
    if (!a.self && b.self) return 1;
    return compareProps(a, b, { lastName: 1, firstName: 1, _id: 1 });
  });

  const needsReview: {
    plan: IPlan;
    patient: IContact | null;
    _id: string;
    attachments: IAttachment[];
    comments: IComment[];
    description: string;
    focus: "baseline" | "stability" | "advanced" | "";
    order: number;
    status: "draft" | "active" | "archive";
    title: string;
    videos: IVideo[];
  }[] = [];

  if (plansList) {
    const activePlansAndSessions = plansList
      .filter(x => x.status === "active")
      .flatMap(x => x.sessions.filter(y => y.status === "active").map(y => ({ ...y, plan: x, patient: contacts && contacts[x.patientId] })));

    activePlansAndSessions.forEach(plan => {
      plan.videos.forEach(video => {
        if (
          video.videoType === "patient" &&
          plan.videos.findIndex(reviewVideo => reviewVideo.videoType === "doctor" && reviewVideo.patientVideoId === video._id) === -1 &&
          needsReview.findIndex(session => session._id === plan._id) === -1
        ) {
          needsReview.push(plan);
        }
      });
    });
  }

  const onSavePatient = (item: PatientAccount) => addPatient(item);

  return <Dashboard caseload={caseload} needsReview={needsReview} doctors={doctors} onSavePatient={onSavePatient} />;
};

export default DashboardPage;
