export default interface IAuth {
  token: string;
  firstName: string;
  lastName: string;
  email: string;
  role: Role;
}

export enum Role {
  doctor = "doctor",
  organizationadmin = "organizationadmin",
  patient = "patient",
}
