import { toast } from "react-toastify";

import PatientAccount from "../models/PatientAccount";
import { dispatch } from "../store/Store";
import { request } from "./ApiService";

export const addPatient = (item: PatientAccount) =>
  request("caseload", { method: "POST", body: item })
    .then(item => dispatch({ type: "ADD_CONTACT", item }))
    .catch(err => toast.error(err.message));

export const deletePatient = (patientId: string) => request(`caseload/${patientId}`, { method: "DELETE" });
