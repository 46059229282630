import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

import { useContacts, usePlans } from "../../hooks/useCollection";
import IPlan from "../../models/IPlan";
import ISession from "../../models/ISession";
import { sendInvitation } from "../../services/InvitationsService";
import { addSession, createPlan, savePlan, saveSession } from "../../services/PlansService";
import PatientDetail from "./PatientDetail";

const PatientDetailPageContent: FC = () => {
  const dispatch = useDispatch();

  const contacts = useContacts();
  const plans = usePlans();
  const { patientId } = useParams<{ patientId: string }>();

  const patient = contacts && contacts[patientId];
  const plansList = plans && Object.values(plans).filter(x => x.patientId === patientId);

  const onSavePlan = (plan: IPlan, planTemplateId?: string) => {
    if (planTemplateId) {
      return createPlan({ ...plan, _id: planTemplateId }).then(item => dispatch({ type: "SET_PLAN", item }));
    }
    return (plan._id ? savePlan(plan) : createPlan(plan)).then(item => dispatch({ type: "SET_PLAN", item }));
  };

  const onFeaturePlan = (plan: IPlan) => {
    const featuredPlans =
      plansList
        ?.filter(planFind => planFind.isFeatured && planFind._id !== plan._id)
        .map(planMap => {
          planMap.isFeatured = false;
          return planMap;
        }) || [];
    featuredPlans.push({ ...plan, isFeatured: !plan.isFeatured });

    const promises: Array<Promise<any>> = [];
    featuredPlans.forEach(plan => {
      promises.push(onSavePlan(plan));
    });

    return Promise.all(promises).then(() => toast.success("Toggled plan featured"));
  };

  const onSaveSession = (plan: IPlan, session: ISession) => {
    const promise = (session._id ? saveSession : addSession)(plan._id, session);

    promise.then(res => {
      let data: IPlan;

      if (session._id) {
        data = { ...plan, sessions: plan.sessions.map(x => (x._id === session._id ? { ...res } : x)) };
      } else {
        data = { ...plan, sessions: [...plan.sessions, res] };
      }

      dispatch({ type: "SET_PLAN", item: data });
      return res;
    });

    return promise;
  };

  return (
    <PatientDetail patient={patient} plans={plansList} onFeaturePlan={onFeaturePlan} onSavePlan={onSavePlan} onSaveSession={onSaveSession} onSendInvitation={sendInvitation} />
  );
};

const PatientDetailPage: FC = () => {
  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <PatientDetailPageContent />
    </MsalAuthenticationTemplate>
  );
};

export default PatientDetailPage;
