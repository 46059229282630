import React, { FC } from "react";
import IVideo from "../../models/IVideo";
import { CalendarIcon, CreateIcon, DeleteIcon, VerticalDotsIcon } from "../core/Icons";
import { formatObjectId } from "../../services/DateService";
import VideoDisplay from "../video/VideoDisplay";

export type Props = {
  onAddToLibrary: (video: IVideo) => void;
  onVideoDelete: (video: IVideo) => void;
  video: IVideo;
};

const PatientEducationVideoDisplay: FC<Props> = ({ video, onAddToLibrary, onVideoDelete }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div style={{ cursor: "pointer" }}>
        <VideoDisplay sas={video.sas} sasMp4={video.sasMp4} />
      </div>

      <section className="border-without-top p-2" style={{ flex: "1 1 auto" }}>
        <div data-toggle="dropdown" style={{ cursor: "pointer", float: "right", minWidth: "1rem", textAlign: "center" }}>
          <VerticalDotsIcon />
        </div>
        <div className="dropdown-menu dropdown-menu-right">
          <div className="dropdown-item" onClick={() => onAddToLibrary(video)}>
            <CreateIcon />
            Add to Library
          </div>
          <div className="dropdown-item" onClick={() => onVideoDelete(video)}>
            <DeleteIcon style={{ color: "red" }} /> Delete Video
          </div>
        </div>
        <h3>{video.name}</h3>
        <div className="info-wrap">
          <div>
            <CalendarIcon /> {formatObjectId(video.dateAdded !== "000000000000000000000000" ? video.dateAdded! : video._id)}
          </div>
        </div>
      </section>
    </div>
  );
};

export default PatientEducationVideoDisplay;
