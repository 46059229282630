import React, { FC } from "react";
import IImage from "../../models/IImage";

export type Props = {
  onCancel: () => void;
  images: Array<IImage>;
};

const AssessmentCompare: FC<Props> = ({ images, onCancel }) => {
  return (
    <div style={{ position: "fixed", top: 0, left: 0, height: "100%", width: "100%", zIndex: 9999, background: "#f5f5f5", overflow: "scroll" }}>
      <div style={{ top: 0, left: 0, padding: "1rem", position: "absolute", zIndex: 1 }}>
        <div style={{ background: "white", padding: ".25rem", borderRadius: ".5rem" }}>
          <button type="button" className="action btn btn-primary" onClick={onCancel}>
            Back
          </button>
        </div>
      </div>
      <section className="bg-secondary" style={{ paddingTop: "4rem" }}>
        <div className="container">
          <div className="row">
            {images.map(image => (
              <div key={`image-assessment-${image._id}`} className="col-6" style={{ paddingBottom: "3rem" }}>
                <img src={image.sas} alt="" style={{ height: "100%", width: "100%" }} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default AssessmentCompare;
