type FileUploadAction = {
  fileProgress: { name: string; percentComplete: number };
  item: string;
  type: "ADD_FILE_UPLOAD" | "REMOVE_FILE_UPLOAD" | "UPDATE_FILE_PROGRESS";
};

export type FileUploadState = {
  checkedCameraPermissions: boolean;
  doctorFilter?: string;
  filesUploading: Array<{ name: string; percentComplete: number }>;
  patientFilter?: string;
};

export const fileUploadReducer = (state: FileUploadState = { checkedCameraPermissions: false, filesUploading: [] }, action: FileUploadAction) => {
  const { fileProgress, item, type } = action;

  switch (type) {
    case "ADD_FILE_UPLOAD": {
      return { ...state, checkedCameraPermissions: true };
    }
    case "REMOVE_FILE_UPLOAD": {
      return { ...state, doctorFilter: item };
    }
    case "UPDATE_FILE_PROGRESS": {
      if (fileProgress && fileProgress.name && fileProgress.percentComplete) {
        return {
          ...state,
          filesUploading: [...state.filesUploading.filter(file => file.name !== fileProgress.name), { name: fileProgress.name, percentComplete: fileProgress.percentComplete }],
        };
      } else {
        return { ...state };
      }
    }
    default: {
      return state;
    }
  }
};
