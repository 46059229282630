import React, { FC } from "react";

import Modal from "../core/Modal";
import DeleteVideoCard, { Props as CardProps } from "./DeleteVideoCard";

type Props = CardProps;

const DeleteVideoModal: FC<Props> = props => (
  <Modal onClose={props.onCancel}>
    <DeleteVideoCard {...props} />
  </Modal>
);

export default DeleteVideoModal;
