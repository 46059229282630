import { request } from "./ApiService";
import IVideo from "../models/IVideo";

export const getEducations = () => request("educations");
export const addVideoToLibrary = async (videoId: string, name: string, patientId: String, doctorId?: string) =>
  request(`educations/video/${videoId}/addvideotolibrary`, { method: "POST", body: { patientId, doctorId, name } });
export const addVideoToEducation = async (videoId: string, name: string, patientId: String, doctorId?: string): Promise<IVideo> => {
  return request(`educations/video/${videoId}`, { method: "POST", body: { patientId, doctorId, name } });
};
export const deleteVideo = async (videoId: string, patientId: String): Promise<String> => request(`educations/video/${videoId}`, { method: "DELETE", body: { patientId } });
