import { request } from "./ApiService";
import { uploadFile } from "./FileService";
import IVideo from "../models/IVideo";

export const getVideoLibrary = () => request("videolibrary");
export const addVideoToVideoLibrary = async (blob: Blob, name: String, handleProgress?: (name: string, percentComplete: number) => void): Promise<IVideo> => {
  const videoId = await uploadFile(blob, undefined, handleProgress);

  return request(`videolibrary/video/${videoId}`, { method: "POST", body: { name } });
};
export const updateVideo = async (videoId: string, name: string): Promise<IVideo> => request(`videolibrary/video/${videoId}`, { method: "PUT", body: { name } });
// export const convertVideo = async (videoId: string): Promise<IVideo> => request(`videolibrary/video/${videoId}/convert`, { method: "PUT" });
export const deleteVideo = async (videoId: string) => request(`videolibrary/video/${videoId}`, { method: "DELETE" });
