import React, { FC, useRef, useState } from "react";

import Required from "../core/Required";
import CancelSaveButtons from "../buttons/CancelSaveButtons";
import { createLead } from "../../services/LeadsService";
import { toast } from "react-toastify";

const Webinar: FC = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [isPending, setIsPending] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const form = useRef<HTMLFormElement>(null);
  const isValid = form.current?.checkValidity();

  const styles = `
    .bg-light-gray > .navbar {display: none;}
    #header {
      background: #fff;
      transition: all 0.5s;
      z-index: 997;
    }
    
    #header.header-scrolled {
      padding: 12px 0;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    }
    
    #header .logo {
      font-size: 30px;
      margin: 0;
      padding-left: 2rem;
      font-weight: 600;
      font-family: "Poppins", sans-serif;
    }
    
    #header .logo a {
      color: #111111;
    }
    
    #header .logo a span {
      color: #457b9d;
    }
    
    #header .logo img {
      max-height: 40px;
    }

    .landing.custom-theme {
      background-image: url(/assets/img/webinar/landing.jpg);
      background-size: contain;
      background-repeat: no-repeat;
    }

    .container.my-3 {
    }

    .introduction h3, .introduction h2, .introduction h4 {
      color: white;
    }

    .landing > article {
      min-height: initial;
    }

    .landing .introduction .container {
      padding: 5rem;
    }

    .landing.custom-theme {
      margin-top: 3rem;
    }

    @media (max-width: 768px) {
      .col-xl-6.col-lg-12 {
        padding-top: 10rem;
      }

      .introduction h3, .introduction h2, .introduction h4 {
        color: black;
      }
    }

    @media (max-width: 576px) {
      .landing .introduction > .container {
        padding: 0!important;
      }
    }
  `;

  return (
    <>
      <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet" />
      <header id="header" className="fixed-top d-flex align-items-center">
        <a href="/" className="logo me-auto">
          <img src="/assets/img/TeleCareRxLogo.png" alt="Logo" />
        </a>

        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto">
              <li className="nav-item active">
                <a className="nav-link" href="/">
                  Home{" "}
                </a>
              </li>
              {/* <li>
                <ul>
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Value
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <a className="dropdown-item" href="#about">
                        Value
                      </a>
                      <a className="dropdown-item" href="#solutions">
                        Solutions
                      </a>
                      <a className="dropdown-item" href="#testimonials">
                        Testimonials
                      </a>
                      <a className="dropdown-item" href="#team">
                        About Us
                      </a>
                      <a className="dropdown-item" href="#stakes">
                        Get Started
                      </a>
                      <a className="dropdown-item" href="#pricing">
                        Pricing
                      </a>
                      <a className="dropdown-item" href="#contact">
                        Contact
                      </a>
                    </div>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="#contact">
                  Schedule a Demo
                </a>
              </li> */}

              <li className="nav-item">
                <a className="nav-link" href="/dashboard">
                  Sign In
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="https://blog.telecarerx.com/" target="_blank" rel="noopener noreferrer">
                  Blog
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header>
      <main className="landing custom-theme">
        <style>{styles}</style>
        <article className="introduction">
          <div className="container" style={{ padding: ".2rem 5rem 10rem 5rem" }}>
            <div className="col-xl-6 col-lg-12">
              {submitted && (
                <>
                  <h3>CONGRATULATIONS!!!</h3>
                  <h4>You have successfully registered for the Webinar on Tuesday, January 24th at 2:00 pm CST.</h4>
                </>
              )}
              <h3>Soon you will be able to leverage simple concepts that will BUY you TIME, SAVE your ENERGY, and Earn you Money!</h3>
              {!submitted && <h3>Just fill in the information below to sign up for the webinar on Tuesday, Jan 24th at 2:00 pm CST and click submit!</h3>}
            </div>
          </div>
          {!submitted && (
            <div className="container" style={{ padding: "0" }}>
              <form
                ref={form}
                onSubmit={e => {
                  e.preventDefault();

                  setIsPending(true);

                  return createLead({ topic: "webinar", email, name: `${firstName} ${lastName}` })
                    .then(() => {
                      toast.success("Invitation sent! Check your email!");
                      setSubmitted(true);
                    })
                    .catch(() => toast.error("The server had an unknown error. Please try again sometime later."))
                    .finally(() => setIsPending(false));
                }}
              >
                <section>
                  <div className="form-group">
                    <label htmlFor="email">
                      Email <Required />
                    </label>
                    <input
                      type="email"
                      autoComplete="email"
                      autoFocus={true}
                      className="form-control"
                      data-cy="email"
                      id="email"
                      required={true}
                      value={email}
                      onBlur={e => e.currentTarget.value && e.currentTarget.reportValidity()}
                      onChange={e => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="firstName">
                      First Name <Required />
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      autoComplete="given-name"
                      id="firstName"
                      required={true}
                      value={firstName}
                      onChange={e => setFirstName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="lastName">
                      Last Name <Required />
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      autoComplete="family-name"
                      id="lastName"
                      required={true}
                      value={lastName}
                      onChange={e => setLastName(e.target.value)}
                    />
                  </div>
                  <CancelSaveButtons
                    isPending={isPending}
                    isValid={isValid}
                    onCancel={() => {
                      setEmail("");
                      setFirstName("");
                      setLastName("");
                    }}
                    label="Submit"
                    labelPending="Submitting"
                  />
                </section>
              </form>
            </div>
          )}
        </article>
      </main>
    </>
  );
};

export default Webinar;
