// Tracks events for Google Analytics
import "autotrack";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { toast } from "react-toastify";
import IAuth from "./models/IAuth";

import App from "./comps/shell/App";
import store from "./store/Store";
import { getAuthDomain } from "./utils/config";

// MSAL imports
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);

    setAuthToken(payload);
  }
});

const setAuthToken = (payload: AuthenticationResult) => {
  const idTokenClaims: any = payload.idTokenClaims;

  const item = {
    firstName: idTokenClaims.given_name,
    lastName: idTokenClaims.family_name,
    token: payload.idToken,
    email: idTokenClaims.emails[0],
    role: idTokenClaims.jobTitle || "patient",
  } as IAuth;
  localStorage.setItem("_t", item.token);
  store.dispatch({ type: "SET_AUTH", item });
};

const initAuthorization = () => {
  if (sessionStorage[`msal.${msalConfig.auth.clientId}.error.description`]) {
    const error = sessionStorage[`msal.${msalConfig.auth.clientId}.error.description`];
    if (error.indexOf("AADB2C90118") > -1) {
      sessionStorage.clear();
      window.location.replace(
        `https://${getAuthDomain()}.b2clogin.com/${getAuthDomain()}.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_passwordReset&client_id=${
          msalConfig.auth.clientId
        }&nonce=defaultNonce&redirect_uri=${encodeURIComponent(window.location.origin)}/dashboard&scope=openid&response_type=id_token&prompt=login`,
      );
    } else if (error.indexOf("AADB2C99002") > -1) {
      sessionStorage.clear();
      toast.error("Account does not exist, contact your doctor for assistance.");
    }
  }

  return silentLoginb2c();
};

export const silentLoginb2c = () => {
  if (msalInstance.getActiveAccount()) {
    try {
      return msalInstance
        .acquireTokenSilent({ scopes: [msalConfig.auth.clientId] })
        .then(response => {
          return setAuthToken(response);
        })
        .catch(() => Promise.resolve());
    } catch (e) {
      console.log("error getting token", e);
    }
  }
};

const startApp = async () => {
  await initAuthorization();

  ReactDOM.render(
    <Provider store={store}>
      <App pca={msalInstance} />
    </Provider>,
    document.getElementById("root"),
  );

  if (navigator.serviceWorker) {
    navigator.serviceWorker.register("/sw.js");
  }
};

startApp();
