import React, { FC, useState } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { usePlans } from "../../hooks/useCollection";
import ModalSlim from "../core/ModalSlim";
import Spinner from "../core/Spinner";
import { deleteVideo } from "../../services/PlansService";

type Props = {
  onCancel: () => void;
  videoId: string;
};

const DeleteVideoModal: FC<Props> = props => {
  const dispatch = useDispatch();
  const [isPending, setIsPending] = useState(false);
  const { planId, sessionId } = useParams<{ planId: string; sessionId: string }>();
  const plans = usePlans();

  const handleDeleteVideo = () => {
    setIsPending(true);

    if (!plans || !planId || !sessionId) {
      toast.error("No plans or missing plan/session Id(s)");
      setIsPending(false);
      return;
    }

    const plan = plans[planId];
    if (!plan) {
      toast.error("Plan not found");
      setIsPending(false);
      return;
    }

    const session = plan.sessions.find(x => x._id === sessionId);
    if (!session) {
      toast.error("Session not found");
      setIsPending(false);
      return;
    }

    let data = {
      ...plan,
      sessions: plan.sessions.map(session => (session._id === sessionId ? { ...session, videos: session.videos.filter(video => video._id !== props.videoId) } : { ...session })),
    };

    deleteVideo(planId, sessionId, props.videoId)
      .then(() => {
        props.onCancel();
        dispatch({ type: "SET_PLAN", item: data });
        toast.success("Video deleted");
      })
      .catch(response => {
        toast.error("Error deleting video: " + response);
        setIsPending(false);
      });
  };

  return (
    <ModalSlim>
      <article data-cy="delete video modal">
        <h2>Delete Video?</h2>
        <form
          autoComplete="off"
          className="material"
          onSubmit={e => {
            e.preventDefault();

            handleDeleteVideo();
          }}
        >
          <section>
            <p>Are you sure you want to delete this video? This cannot be undone.</p>
            <div className="d-flex justify-content-between">
              <button type="button" className="action btn" disabled={isPending} onClick={props.onCancel}>
                Cancel
              </button>
              <button title="Delete Video" type="submit" className="action btn btn-danger" disabled={isPending}>
                {isPending && <Spinner />} {isPending ? "Deleting" : "Delete Video"}
              </button>
            </div>
          </section>
        </form>
      </article>
    </ModalSlim>
  );
};

export default DeleteVideoModal;
