import React, { FC } from "react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import VideoDisplay from "../video/VideoDisplay";
import { doctorVideos, supportVideos } from "./SupportVideos";
import useAuth from "../../hooks/useAuth";
import { Role } from "../../models/IAuth";

const SupportPage: FC = () => {
  const auth = useAuth();
  const { role } = auth || { role: null };

  const getVideoSection = (title: string, videos: Array<{ title: string; url: string }>, type: string) => {
    return (
      <>
        <h2>{title}</h2>
        <section className="grid">
          {videos.map((video, index) => (
            <div key={`${type}-${index}`} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <VideoDisplay sasMp4={video.url} />
              <section className="border-without-top p-2" style={{ flex: "1 1 auto" }}>
                <h3>{video.title}</h3>
              </section>
            </div>
          ))}
        </section>
      </>
    );
  };

  return (
    <main>
      <article>
        {getVideoSection("Patient Support", supportVideos, "support")}
        {role === Role.doctor && getVideoSection("Provider Training Modules", doctorVideos, "provider")}
      </article>
    </main>
  );
};

const Support: FC = () => {
  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <SupportPage />
    </MsalAuthenticationTemplate>
  );
};

export default Support;
