import React, { CSSProperties, FC } from "react";

import IContact from "../../models/IContact";
import Avatar from "../avatar/Avatar";
import { ActiveIcon, DraftIcon, MailIcon } from "../core/Icons";

type Props = {
  style?: CSSProperties;
  contact: IContact;
};

const PatientPreview: FC<Props> = ({ contact, style }) => {
  let onboardStatus;

  if (contact.invitationClaimedTms) {
    onboardStatus = "active";
  } else if (contact.invitationTms) {
    onboardStatus = "invited";
  } else {
    onboardStatus = "draft";
  }

  return (
    <div style={{ background: "white", display: "flex", ...style }}>
      <Avatar {...contact} circle={true} size={160} style={{ flex: "0 0 auto", objectFit: "cover", width: "5rem" }} />
      <section className="border-0 p-2" style={{ display: "flex", flex: "1 1 auto", flexDirection: "column", justifyContent: "center" }}>
        <h3 title={`${contact.lastName}, ${contact.firstName}`} style={{ marginBottom: ".2rem", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
          {contact.lastName}, {contact.firstName}
        </h3>
        <div title={contact.email} style={{ color: "gray", fontSize: ".8rem", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
          {contact.email}
        </div>
        <div style={{ color: "gray", fontSize: ".8rem" }}>
          {onboardStatus === "active" && (
            <>
              <ActiveIcon /> Active
            </>
          )}
          {onboardStatus === "invited" && (
            <>
              <MailIcon /> Invited
            </>
          )}
          {onboardStatus === "draft" && (
            <>
              <DraftIcon /> Draft
            </>
          )}
        </div>
      </section>
    </div>
  );
};

export default PatientPreview;
