import React, { FC, useMemo, useRef, useState } from "react";
import Spinner from "../core/Spinner";

type Props = {
  canUpload: boolean;
  isPending: boolean;
  onCancel: () => void;
  onRecordingComplete: (video: Blob) => void;
  onSaveRecording: () => void;
  recordingMode: "camera" | "file";
  uploadProgress?: number;
};

const UploadDeviceVideo: FC<Props> = ({ canUpload, isPending, onCancel, onRecordingComplete, onSaveRecording, recordingMode, uploadProgress }) => {
  const VIDEO_WRAPPER_CLASS = "video-recorder-wrapper";
  const videoInputField = useRef<HTMLInputElement>(null);
  const [videoFile, setVideoFile] = useState<File | null>(null);

  const handleFileChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      setVideoFile(e.target.files[0]);
      onRecordingComplete(e.target.files[0]);
    }
  };

  const videoBlobUrl = useMemo(() => (videoFile ? URL.createObjectURL(videoFile) : undefined), [videoFile]);

  return (
    <div style={{ position: "fixed", top: 0, left: 0, height: "100%", width: "100%", zIndex: 999 }}>
      <div className={VIDEO_WRAPPER_CLASS}>
        <input type="file" accept="video/*" ref={videoInputField} onChange={handleFileChange} style={{ display: "none" }} />
        {videoBlobUrl !== undefined && (
          <div className="camera-view-wrapper">
            <video className="capture-video" src={videoBlobUrl} playsInline controls></video>
          </div>
        )}
        <div>
          <div className="video-input-wrapper">
            <button
              data-qa="open-input"
              color="black"
              className="video-input-button"
              onClick={() => {
                videoInputField.current?.click();
              }}
            >
              {videoFile === null ? "Select a video" : "Use another video"}
            </button>
          </div>
        </div>
      </div>
      <div style={{ top: 0, left: 0, padding: "1rem", position: "absolute" }}>
        <div style={{ background: "white", padding: ".25rem", borderRadius: ".5rem" }}>
          <button type="button" className="action btn text-danger" disabled={isPending} onClick={onCancel}>
            Cancel
          </button>
          <button data-cy="video upload button" type="button" className="action btn btn-primary" disabled={!canUpload || isPending} onClick={onSaveRecording}>
            {isPending && <Spinner />} {isPending ? `Uploading${uploadProgress ? ` ${uploadProgress}%` : ""}` : "Upload"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadDeviceVideo;
