import React, { FC } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import Logout from "../auth/Logout.page";
import PatientDetail from "../caseload/PatientDetail.page";
import NotFound from "../errors/NotFound.page";
import Profile from "../profile/Profile.page";
import SessionDetail from "../sessions/SessionDetail.page";
import Support from "../support/Support.page";
import VideoLibrary from "../videoLibrary/VideoLibrary.page";
import DashboardLanding from "../dashboard/DashboardLanding.page";
import Webinar from "../webinar/webinar.page";
import PlanTemplates from "../planTemplates/PlatTemplates.page";
import PlanTemplateSessionDetail from "../planTemplates/PlanTemplateSessionDetail.page";

const Routes: FC = () => {
  const auth = useAuth();
  const { token } = auth || { token: null };

  const routes = [];

  routes.push(<Redirect key="home-redirect" from="/" exact to="/dashboard" />);
  routes.push(<Route key="dashboard" path="/dashboard" exact component={DashboardLanding} />);
  if (token) {
    routes.push(<Route key="logout" path="/logout" exact component={Logout} />);
    routes.push(<Route key="saveProfile" path="/profile" exact component={Profile} />);
    routes.push(<Route key="session-detail" path="/plans/:planId/sessions/:sessionId" exact component={SessionDetail} />);
    routes.push(<Route key="support" path="/support" exact component={Support} />);
    routes.push(<Route key="patient-detail" path="/caseload/:patientId" exact component={PatientDetail} />);
    routes.push(<Route key="plantemplates" path="/plantemplates" exact component={PlanTemplates} />);
    routes.push(<Route key="plantemplates-session-detail" path="/plantemplates/:planId/sessions/:sessionId" exact component={PlanTemplateSessionDetail} />);
    routes.push(<Route key="video-library" path="/videolibrary" exact component={VideoLibrary} />);

    // add default redirect
    routes.push(<Route key="notFound" component={NotFound} />);
  } else {
    routes.push(<Route key="webinar" path="/webinar" exact component={Webinar} />);
    routes.push(<Redirect key="no-auth" to="/dashboard" />);
  }

  return <Switch>{routes}</Switch>;
};

export default Routes;
