import { DependencyList } from "react";

import useEventListener from "./useEventListener";

const useKeyListener = (targetKey: string, listener: (ev: KeyboardEvent) => void, deps: DependencyList = []) => {
  let mainKey = targetKey;
  let shortcutKey = "";

  if (targetKey.includes("+")) {
    [shortcutKey, mainKey] = targetKey.split("+");
  }

  useEventListener(
    "keydown",
    e => {
      if (e.key === mainKey) {
        if (!shortcutKey || (e as any)[shortcutKey + "Key"] === true) {
          e.preventDefault();
          listener(e);
        }
      }
    },
    deps,
  );
};

export default useKeyListener;
