import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { addVideoToLibrary } from "../../services/PlansService";
import IVideo from "../../models/IVideo";
import ModifyVideoModal from "../videoLibrary/ModifyVideoModal";

type Props = {
  onCancel: () => void;
  video: IVideo;
};

const AddToVideoLibrary: FC<Props> = props => {
  const [isSavingVideo, setIsSavingVideo] = useState(false);
  const dispatch = useDispatch();
  const { planId, sessionId } = useParams();

  const handleSave = (file: File, name: string) => {
    setIsSavingVideo(true);

    addVideoToLibrary(planId!, sessionId!, props.video._id, name)
      .then(item => {
        dispatch({ type: "SET_VIDEO_LIBRARY_VIDEO", item });
        props.onCancel();
      })
      .catch(err => toast.error(err.message))
      .finally(() => setIsSavingVideo(false));
  };

  return <ModifyVideoModal current={props.video} isPending={isSavingVideo} onCancel={props.onCancel} onSubmit={handleSave} />;
};

export default AddToVideoLibrary;
