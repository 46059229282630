import React, { FC, useRef, useState } from "react";
import Textarea from "react-textarea-autosize";

import Lead from "../../models/Lead";
import noop, { noopp } from "../../utils/noop";
import CancelSaveButtons from "../buttons/CancelSaveButtons";
import Required from "../core/Required";

type Topic = Lead["topic"];

export type Props = {
  topic?: Topic;
  onCancel?: () => void;
  onSubmit?: (item: Lead) => Promise<any>;
};

const titles = new Map<Topic, string>([
  ["contact", "Contact Us"],
  ["demo", "Schedule a Demo"],
  ["guide", "Request Free Guide"],
]);

const LeadCard: FC<Props> = ({ topic = "guide", onCancel = noop, onSubmit = noopp }) => {
  const [comments, setComments] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const [isPending, setIsPending] = useState(false);

  const form = useRef<HTMLFormElement>(null);

  const isValid = form.current?.checkValidity();

  const title = titles.get(topic);

  return (
    <article data-cy="lead card">
      <h2>{title}</h2>
      <form
        ref={form}
        onSubmit={e => {
          e.preventDefault();

          setIsPending(true);

          onSubmit({ topic, email, name, phone, comments }).finally(() => setIsPending(false));
        }}
      >
        <section>
          <div className="form-group">
            <label htmlFor="email">
              Email <Required />
            </label>
            <input
              type="email"
              autoComplete="email"
              autoFocus={true}
              className="form-control"
              data-cy="email"
              id="email"
              required={true}
              value={email}
              onBlur={e => e.currentTarget.value && e.currentTarget.reportValidity()}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          {(topic === "demo" || topic === "contact") && (
            <>
              <div className="form-group">
                <label htmlFor="name">
                  Name <Required />
                </label>
                <input type="text" className="form-control" autoComplete="name" data-cy="name" id="name" required={true} value={name} onChange={e => setName(e.target.value)} />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone {topic !== "contact" && <Required />}</label>
                <input
                  type="tel"
                  className="form-control"
                  autoComplete="tel"
                  data-cy="phone"
                  id="phone"
                  required={topic !== "contact"}
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                />
                <small>Phone numbers are used in the case where follow-up emails are lost in Spam mail</small>
              </div>
              <div className="form-group">
                <label htmlFor="comments">Comments</label>
                <Textarea
                  autoComplete="none"
                  className="form-control autosize"
                  data-cy="comments"
                  id="comments"
                  minRows={2}
                  value={comments}
                  onChange={e => setComments(e.target.value)}
                />
              </div>
            </>
          )}
          <CancelSaveButtons isPending={isPending} isValid={isValid} onCancel={onCancel} label="Submit" labelPending="Submitting" />
        </section>
      </form>
    </article>
  );
};

export default LeadCard;
