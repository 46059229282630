import { RefObject, useEffect } from "react";

type T = {
  getBoundingClientRect: () => ClientRect;
  height: number;
  width: number;
};

const useClamp = (ref: RefObject<T>) => {
  useEffect(() => {
    const current = ref.current;

    if (current) {
      const rect = current.getBoundingClientRect();

      current.height = rect.height;
      current.width = rect.width;
    }
  }, [ref]);
};

export default useClamp;
