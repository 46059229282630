import IContact from "../models/IContact";

type ContactsAction = {
  collection?: IContact[];
  invitation?: { _id: string; tms: Date };
  item?: IContact;
  type: "ADD_CONTACT" | "SET_CONTACTS" | "SET_CONTACT_INVITATION" | "REMOVE_CONTACT" | "RESET" | "RESET_CONTACT";
};

export type ContactsState = {
  [key: string]: IContact;
};

export const contactsReducer = (state: ContactsState | null = null, action: ContactsAction) => {
  const { collection, invitation, item, type } = action;

  switch (type) {
    case "ADD_CONTACT": {
      return { ...state, [item!._id]: item! };
    }
    case "SET_CONTACT_INVITATION": {
      const current = state![invitation!._id];
      return { ...state, [current._id]: { ...current, invitationTms: invitation!.tms } };
    }
    case "SET_CONTACTS": {
      return collection!.reduce((prev, curr) => ({ ...prev, [curr._id]: curr }), {} as ContactsState);
    }
    case "REMOVE_CONTACT": {
      const rtrn = { ...state };
      delete rtrn[item?._id!];
      return rtrn;
    }
    case "RESET": {
      return null;
    }
    case "RESET_CONTACT": {
      return null;
    }
    default: {
      return state;
    }
  }
};
