import { request } from "./ApiService";
import { uploadFile } from "./FileService";
import IVideo from "../models/IVideo";

export const getAssessments = () => request("assessments");
export const addImageToAssessment = async (
  blob: Blob,
  doctorId: String,
  patientId: String,
  videoId: string,
  handleProgress?: (name: string, percentComplete: number) => void,
): Promise<IVideo> => {
  const fileExtension = "png";
  const imageId = await uploadFile(blob, { fileExtension }, handleProgress);

  return request(`assessments/image/${imageId}`, { method: "POST", body: { doctorId, patientId, videoId, fileExtension } });
};
export const addVideoToAssessment = async (blob: Blob, patientId: String, doctorId: string, handleProgress?: (name: string, percentComplete: number) => void): Promise<IVideo> => {
  const videoId = await uploadFile(blob, undefined, handleProgress);

  return request(`assessments/video/${videoId}`, { method: "POST", body: { patientId, doctorId } });
};
// export const convertVideo = async (videoId: string, patientId: String, doctorId: String): Promise<IVideo> =>
//   request(`assessments/video/${videoId}/convert`, { method: "PUT", body: { patientId, doctorId } });
export const deleteImage = async (imageId: string, patientId: String, doctorId: String): Promise<String> =>
  request(`assessments/image/${imageId}`, { method: "DELETE", body: { patientId, doctorId } });
export const deleteVideo = async (videoId: string, patientId: String, doctorId: String): Promise<String> =>
  request(`assessments/video/${videoId}`, { method: "DELETE", body: { patientId, doctorId } });
