import { toast } from "react-toastify";

import store, { dispatch } from "../store/Store";
import { request } from "./ApiService";
import { uploadFile } from "./FileService";

export const addAttachment = async (
  planId: string,
  sessionId: string,
  file: File,
  name: string,
  handleProgress?: (name: string, percentComplete: number) => void,
  isPlanTemplate = false,
) => {
  try {
    const fileExtension = file.name.split(".").pop();
    const attachmentId = await uploadFile(file, { disposition: "attachment", fileName: name, fileExtension }, handleProgress);
    const attachment = await request(`${isPlanTemplate ? "plantemplates" : "plans"}/${planId}/sessions/${sessionId}/attachments/${attachmentId}`, {
      method: "PUT",
      body: { name, fileExtension },
    });
    const state = store.getState();
    const plan = isPlanTemplate ? state.planTemplates![planId] : state.plans![planId];
    const session = plan.sessions.find(x => x._id === sessionId)!;
    session.attachments = [...session.attachments, attachment];

    dispatch({ type: isPlanTemplate ? "SET_PLAN_TEMPLATE" : "SET_PLAN", item: plan });
  } catch (err) {
    toast.error(err.message);
  }
};

export const removeAttachment = async (planId: string, sessionId: string, attachmentId: string, isPlanTemplate = false) => {
  try {
    await request(`${isPlanTemplate ? "plantemplates" : "plans"}/${planId}/sessions/${sessionId}/attachments/${attachmentId}`, { method: "DELETE" });

    const state = store.getState();
    const plan = isPlanTemplate ? state.planTemplates![planId] : state.plans![planId];
    const session = plan.sessions.find(x => x._id === sessionId)!;
    session.attachments = session.attachments.filter(x => x._id !== attachmentId);

    dispatch({ type: isPlanTemplate ? "SET_PLAN_TEMPLATE" : "SET_PLAN", item: plan });
  } catch (err) {
    toast.error(err.message);
  }
};
