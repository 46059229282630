import React, { FC, useCallback } from "react";

import classNames from "classnames";
import { useLocation } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import { useContacts, useVideoLibrary } from "../../hooks/useCollection";
import useCustomEvent from "../../hooks/useCustomEvent";
import useKeyListener from "../../hooks/useKeyListener";
import Modals from "./Modals";
import NavBar from "./NavBar";
import Routes from "./Routes";
import { Role } from "../../models/IAuth";

const Routed: FC = () => {
  const location = useLocation();

  const auth = useAuth();
  const contacts = useContacts();
  const library = useVideoLibrary();

  const showSearch = useCustomEvent("showSearch");

  const showSearchIfDoctor = useCallback(() => (auth?.role === Role.doctor || auth?.role === Role.organizationadmin) && showSearch(), [auth, showSearch]);

  useKeyListener("ctrl+f", showSearchIfDoctor, [showSearchIfDoctor]);
  useKeyListener("meta+f", showSearchIfDoctor, [showSearchIfDoctor]);

  const caseload = (contacts && Object.values(contacts).filter(x => !x.self && x.role === Role.patient)) || undefined;
  const libraryList = (library && Object.values(library)) || undefined;

  return (
    <>
      <NavBar auth={auth} />
      <div className={classNames(location && location.pathname !== "/" && "container my-3")}>
        <Routes />
      </div>
      <Modals caseload={caseload} library={libraryList} />
    </>
  );
};

export default Routed;
