import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import * as poseDetection from "@tensorflow-models/pose-detection";
import "@tensorflow/tfjs-backend-webgl";
import { Stage, Layer } from "react-konva";

import useKeyActive from "../../hooks/useKeyActive";
import useKeyListener from "../../hooks/useKeyListener";
import IVideo from "../../models/IVideo";
import Turtle from "../../svg/Turtle";
import { captureImage, clearCanvas, getContainedSize } from "../../utils/canvas";
import Annotations, { Mode } from "./Annotations";
import { CameraIcon, CropIcon, PersonIcon } from "../core/Icons";
import ImageResize from "./ImageResize";
import defaultPoses from "../assessments/DefaultPoses";
import { drawResults } from "./DrawPoses";
import useCustomEvent from "../../hooks/useCustomEvent";

const slowSpeed = 0.25;
const defaultIdealImagePosition = { x: 250, y: 250, scaleX: 1 };
export type IdealImageSettings = { id: number; shapeProps: any; url: string };

export type Props = {
  handleOnCancel: () => void;
  handleOnMediaAdd: (value: Blob) => void;
  isVideoPending: boolean;
  video: IVideo;
};

const VideoImageCapture: FC<Props> = ({ handleOnCancel, handleOnMediaAdd, isVideoPending, video }) => {
  const annotationsRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const cropImageRef = useRef<HTMLImageElement>(null);
  const [annotationsMode, setAnnotationsMode] = useState<Mode>("select");
  const [crop, setCrop] = useState<Crop>({
    unit: "%", // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 35,
    height: 65,
  });
  const [isCropping, setIsCropping] = useState(false);
  const [isSlow, setIsSlow] = useState(false);
  const [className, setClassName] = useState<string>();
  const [idealPoseImageSelected, setIdealPoseImageSelected] = useState<string>();
  const [idealPoseImages, setIdealPoseImages] = useState<Array<IdealImageSettings>>([]);
  const [selectedPoseImage, setSelectedPoseImage] = useState(defaultPoses[0]);
  const [showPose, setShowPose] = useState(false);
  const disableAnnotations = useKeyActive("Shift");
  const videoEl = videoRef.current;
  const idealStageRef = useRef<any>(null);
  let detectorRef = useRef<poseDetection.PoseDetector | null>(null);
  const poseModel = useMemo(() => {
    return poseDetection.SupportedModels.MoveNet;
  }, []);
  const detectorConfig = useMemo(() => {
    return { modelType: poseDetection.movenet.modelType.SINGLEPOSE_LIGHTNING };
  }, []);
  const drawCurrentAnnotations = useCustomEvent("drawCurrentAnnotations");
  const dispatchImageCaptured = useCustomEvent("recordReviewEnd");
  // const poseModel = poseDetection.SupportedModels.BlazePose;
  // const detectorConfig = {
  //   runtime: "tfjs",
  //   enableSmoothing: true,
  //   modelType: "full",
  // };

  useKeyListener(
    " ",
    e => {
      if (!videoEl) {
        return;
      }

      e.preventDefault();

      videoEl.paused ? videoEl.play() : videoEl.pause();
    },
    [videoEl],
  );

  useKeyListener(
    "s",
    e => {
      e.preventDefault();

      setIsSlow(!isSlow);
    },
    [isSlow],
  );

  useKeyListener(
    "Enter",
    e => {
      e.preventDefault();

      handleCaptureImage();
    },
    [isCropping, crop, annotationsRef, videoRef, cropImageRef],
  );

  useKeyListener("Delete", () => handleRemoveSelectedIdealPose(), [idealPoseImageSelected]);
  useKeyListener("Backspace", () => handleRemoveSelectedIdealPose(), [idealPoseImageSelected]);

  useEffect(() => {
    if (!videoEl) {
      return;
    }

    videoEl.playbackRate = isSlow ? slowSpeed : 1;
  }, [isSlow, videoEl]);

  useEffect(() => {
    poseDetection.createDetector(poseModel, detectorConfig).then(dect => {
      detectorRef.current = dect;
    });
  }, [poseModel, detectorConfig]);

  const getPose = useCallback(() => {
    if (showPose && detectorRef.current !== null && videoRef.current !== null && annotationsRef.current !== null) {
      const [containedWidth, containedHeight] = getContainedSize(videoRef.current);
      const newCanvas = document.createElement("canvas");
      newCanvas.height = annotationsRef.current.height;
      newCanvas.width = annotationsRef.current.width;
      const ctx = newCanvas.getContext("2d");
      const widthOffset = (videoRef.current.clientWidth - containedWidth) / 2;
      const heightOffset = (videoRef.current.clientHeight - containedHeight) / 2;
      ctx?.drawImage(videoRef.current, 0, 0, videoRef.current.videoWidth, videoRef.current.videoHeight, widthOffset, heightOffset, containedWidth, containedHeight);

      detectorRef.current.estimatePoses(newCanvas).then(poses => {
        if (poses && poses.length > 0 && annotationsRef.current !== null) {
          drawResults(poses, annotationsRef.current, poseModel);
        }
      });
    }
  }, [poseModel, showPose]);

  useEffect(() => {
    if (showPose) {
      getPose();
    }
  }, [getPose, detectorRef, showPose]);

  const handleNewPose = () => {
    if (showPose) {
      clearCanvas(annotationsRef);
      drawCurrentAnnotations();
      getPose();
    }
  };

  const checkDeselect = (e: any) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      setIdealPoseImageSelected(undefined);
    }
  };

  const handleCaptureImage = async () => {
    setClassName("camera-blink");
    setTimeout(() => {
      setClassName(undefined);
    }, 500);

    let annos = [];
    if (annotationsRef.current) {
      annos.push(annotationsRef.current);
    }

    if (idealPoseImages.length > 0 && idealStageRef.current) {
      annos.push(idealStageRef.current.toCanvas());
    }

    let canvas = captureImage(annos, videoRef.current);

    if (canvas) {
      if (isCropping) {
        const newCanvas = document.createElement("canvas");
        let scaleX = cropImageRef.current?.naturalWidth! / cropImageRef.current?.width!;
        let scaleY = cropImageRef.current?.naturalHeight! / cropImageRef.current?.height!;
        let widthMargin = 0;
        let heightMargin = 0;
        if (cropImageRef.current?.naturalWidth! / cropImageRef.current?.offsetWidth! < cropImageRef.current?.naturalHeight! / cropImageRef.current?.offsetHeight!) {
          const imageWidth = (cropImageRef.current?.height! / canvas.height) * canvas.width;
          widthMargin = (cropImageRef.current?.width! - imageWidth) / 2;
          scaleX = cropImageRef.current?.naturalWidth! / imageWidth;
        } else {
          const imageHeight = (cropImageRef.current?.width! / canvas.width) * canvas.height;
          heightMargin = (cropImageRef.current?.height! - imageHeight) / 2;
          scaleY = cropImageRef.current?.naturalHeight! / imageHeight;
        }

        newCanvas.height = crop?.height!;
        newCanvas.width = crop?.width!;
        const ctx = newCanvas.getContext("2d");
        ctx?.drawImage(
          canvas,
          (crop?.x! - widthMargin) * scaleX,
          (crop?.y! - heightMargin) * scaleY,
          crop?.width! * scaleX,
          crop?.height! * scaleY,
          0,
          0,
          crop?.width!,
          crop?.height!,
        );

        canvas = newCanvas;
      }

      canvas.toBlob(blob => {
        if (blob) {
          handleOnMediaAdd(blob);
        }
      });

      dispatchImageCaptured();
      handleClearAnnotations();
      setIsCropping(false);
      setTimeout(() => {
        handleNewPose();
      }, 2000);
    }
  };

  const getCropSource = () => {
    const canvases: HTMLCanvasElement[] = [];
    if (annotationsRef.current != null) {
      canvases.push(annotationsRef.current);
    }

    if (idealStageRef.current != null && idealPoseImages.length > 0) {
      canvases.push(idealStageRef.current.toCanvas());
    }
    return captureImage(canvases, videoRef.current)?.toDataURL() || "";
  };

  const showControls = disableAnnotations || annotationsMode === "select";

  const onFlipIdealPoseChange = () => {
    const image = idealPoseImages.find(arrayPose => arrayPose.id.toString() === idealPoseImageSelected);
    if (image) {
      setIdealPoseImages([
        ...idealPoseImages.filter(arrayPose => arrayPose.id.toString() !== idealPoseImageSelected),
        { id: image?.id, url: image?.url, shapeProps: { ...image?.shapeProps, scaleX: image.shapeProps.scaleX * -1 } },
      ]);
    }
  };

  const handleAddIdealPose = () => {
    setIdealPoseImages([...idealPoseImages, { shapeProps: { ...defaultIdealImagePosition }, url: selectedPoseImage.url, id: new Date().getTime() }]);
  };

  const handleRemoveSelectedIdealPose = () => {
    if (idealPoseImageSelected) {
      setIdealPoseImages(idealPoseImages.filter(img => img.id.toString() !== idealPoseImageSelected));
      setIdealPoseImageSelected(undefined);
    }
  };

  const handleClearAnnotations = () => {
    setIdealPoseImageSelected(undefined);
    setIdealPoseImages([]);

    if (showPose) {
      getPose();
    }
  };

  const ghostIdeal = annotationsMode !== "ideal";

  return (
    <div style={{ position: "fixed", top: 0, left: 0, height: "100%", width: "100%", zIndex: 999, background: "black" }}>
      <div className={className} style={{ height: "100%", width: "100%" }}>
        <div style={{ top: 0, left: 0, padding: "1rem", position: "absolute", zIndex: 1 }}>
          <div style={{ background: "white", padding: ".25rem", borderRadius: ".5rem" }}>
            <button type="button" className="action btn text-primary" disabled={isVideoPending} onClick={handleOnCancel}>
              Back
            </button>
          </div>
        </div>

        <video
          crossOrigin="anonymous"
          muted={true}
          style={{ background: "black", height: "100%", width: "100%", objectFit: "contain", display: "block" }}
          ref={videoRef}
          controls={showControls}
          onTimeUpdate={handleNewPose}
          onPause={handleNewPose}
        >
          {video.sasMp4 && <source src={video.sasMp4} />}
          <source src={video.sas} />
        </video>

        <Annotations
          ref={annotationsRef}
          disabled={showControls}
          disabledHotkeys={isCropping}
          enableIdeal={true}
          idealPoseImageSelected={idealPoseImageSelected}
          onFlipIdealPoseChange={onFlipIdealPoseChange}
          onAddIdealPose={handleAddIdealPose}
          onClear={handleClearAnnotations}
          onModeChange={setAnnotationsMode}
          onSelectedIdealPoseIdChange={id => {
            setSelectedPoseImage(defaultPoses.find(pose => pose._id === id) || defaultPoses[0]);
            setIdealPoseImageSelected(undefined);
          }}
        />

        <Stage
          className={classNames("annotations", ghostIdeal && "ghost")}
          ref={idealStageRef}
          width={window.innerWidth}
          height={window.innerHeight}
          style={{ position: "fixed", top: "0" }}
          onMouseDown={checkDeselect}
          onTouchStart={checkDeselect}
        >
          <Layer>
            {idealPoseImages.map(pose => (
              <ImageResize
                key={pose.id}
                shapeProps={{ ...pose.shapeProps }}
                url={pose.url}
                isSelected={idealPoseImageSelected === pose.id.toString()}
                onSelect={() => {
                  setIdealPoseImageSelected(pose.id.toString());
                }}
                onChange={(newAttrs: any) => {
                  const image = idealPoseImages.find(arrayPose => arrayPose.id === pose.id);
                  if (image) {
                    setIdealPoseImages([
                      ...idealPoseImages.filter(arrayPose => arrayPose.id !== pose.id),
                      { id: image?.id, url: image?.url, shapeProps: { ...image?.shapeProps, ...newAttrs } },
                    ]);
                  }
                }}
              />
            ))}
          </Layer>
        </Stage>

        <div className={classNames("capture-actions", showControls ? "" : "hide")}>
          <div onClick={handleCaptureImage}>
            <CameraIcon />
          </div>
          <div className={isCropping ? "active" : ""} onClick={() => setIsCropping(!isCropping)}>
            <CropIcon />
          </div>
          {!isCropping && (
            <div className={classNames("slowIcon", "ml-2", isSlow && "active")} onClick={() => setIsSlow(!isSlow)}>
              <Turtle bodyColor="white" shellColor="white" />
            </div>
          )}
          <div title="pose" className={classNames("poseIcon fa-lg", showPose && "active")} onClick={() => setShowPose(!showPose)}>
            <PersonIcon />
          </div>
        </div>
      </div>
      {isCropping && (
        <ReactCrop
          crop={crop}
          onChange={(newCrop: any) => {
            setCrop(newCrop);
          }}
          className="cropImage"
        >
          <img className="inner-crop-image" ref={cropImageRef} alt="" src={getCropSource()} />
        </ReactCrop>
      )}
    </div>
  );
};

export default VideoImageCapture;
