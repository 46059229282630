import React, { FC, ReactElement, useState } from "react";
import Switch from "react-switch";
import Textarea from "react-textarea-autosize";

import { useVideoLibrary } from "../../hooks/useCollection";
import useDebounce from "../../hooks/useDebounce";
import IContact from "../../models/IContact";
import ISession from "../../models/ISession";
import IVideo from "../../models/IVideo";
import { saveExample } from "../../services/ExamplesService";
import { ActiveIcon, ArchiveIcon, DraftIcon } from "../core/Icons";
import PrettySelect from "../core/PrettySelect";
import Spinner from "../core/Spinner";
import SelectExampleCard from "../examples/SelectExampleCard";

export type Props = {
  current: ISession;
  isPending: boolean;
  patient?: IContact;
  planId: string;
  onCancel: () => void;
  onSendInvitation?: (item: string) => Promise<any>;
  onSubmit: (value: ISession) => Promise<any>;
};

const getStatusOptions = (): { label: string; icon: ReactElement; value: string; disabled?: boolean }[] => [
  {
    label: "Draft",
    icon: <DraftIcon />,
    value: "draft",
  },
  {
    label: "Active",
    icon: <ActiveIcon />,
    value: "active",
  },
  {
    label: "Archive",
    icon: <ArchiveIcon />,
    value: "archive",
  },
];

const getFocusOptions = (): { label: string; value: string }[] => [
  {
    label: "Acute",
    value: "baseline",
  },
  {
    label: "Intermediate",
    value: "stability",
  },
  {
    label: "Advanced",
    value: "advanced",
  },
];

const validate = (title: string, description: string) => {
  if (!title || !title.trim()) {
    return "Title is required";
  }

  return null;
};

const ModifySessionCard: FC<Props> = ({ current, isPending, patient, planId, onCancel, onSendInvitation, onSubmit }) => {
  useDebounce();

  const showInvitation = !!patient && !patient.invitationTms && !patient.invitationClaimedTms;

  const [description, setDescription] = useState(current.description || "");
  const [focus, setFocus] = useState(current.focus || "baseline");
  const [sendInvitation, setSendInvitation] = useState(showInvitation);
  const [status, setStatus] = useState(current.status || "draft");
  const [title, setTitle] = useState(current.title || "");
  const [selectedVideo, setSelectedVideo] = useState<IVideo>();
  const [selectedVideoIsVideoLibrary, setSelectedVideoIsVideoLibrary] = useState(false);

  const statusOptions = getStatusOptions();
  const focusOptions = getFocusOptions();

  const library = useVideoLibrary();
  const libraryList = (library && Object.values(library)) || undefined;

  switch (current.status) {
    default:
    case "draft": {
      statusOptions[2].disabled = true;
      break;
    }
    case "active": {
      statusOptions[0].disabled = true;
      break;
    }
    case "archive": {
      statusOptions[0].disabled = true;
      statusOptions[1].disabled = true;
      break;
    }
  }

  const validation = validate(title, description);

  return (
    <article data-cy="session card">
      <h2>{current._id ? `Edit Session: ${current.title}` : "Create Session"}</h2>
      <form
        autoComplete="off"
        className="material"
        onSubmit={e => {
          e.preventDefault();

          onSubmit({ ...current, description, status, title, focus }).then(newSession => {
            if (selectedVideo) {
              let isPlanTemplate = true;
              if (window.location.pathname.toLocaleLowerCase().indexOf("/plantemplates") < 0) {
                isPlanTemplate = false;
              }
              return saveExample(planId, newSession._id, selectedVideo._id, selectedVideoIsVideoLibrary, isPlanTemplate);
            }
          });

          if (showInvitation && sendInvitation && onSendInvitation && patient) {
            onSendInvitation(patient._id);
          }
        }}
      >
        <section>
          <div className="form-group">
            <input
              autoComplete="on"
              autoFocus={true}
              className="form-control text-primary"
              data-cy="title"
              style={{ fontSize: "1.2rem" }}
              type="text"
              id="title"
              name="session-title"
              placeholder="add title here..."
              required={true}
              title="title"
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
          </div>
          <div>
            <Textarea
              autoComplete="none"
              className="form-control autosize"
              data-cy="description"
              id="description"
              minRows={2}
              placeholder="add description here..."
              title="description"
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </div>
          {showInvitation && (
            <div className="form-group">
              <label style={{ display: "flex" }}>
                <Switch data-cy="send invitation" checked={sendInvitation} onChange={setSendInvitation} onColor="#1894d5" checkedIcon={false} uncheckedIcon={false} />
                <span style={{ marginLeft: ".5rem" }}>Send Invitation</span>
              </label>
            </div>
          )}
        </section>
        <div className="embed-responsive embed-responsive-16by9">
          <img className="embed-responsive-item" src="/images/video-placeholder.jpg" alt="Video Placeholder" />
        </div>
        {!current._id && (
          <div>
            <SelectExampleCard
              autoFocus={false}
              containerHeight="30vh"
              library={libraryList}
              sectionHeight="20vh"
              onCancel={undefined}
              onSelect={(video, isVideoLibrary) => {
                setSelectedVideo(video);
                setSelectedVideoIsVideoLibrary(isVideoLibrary);
                if (title === "") {
                  setTitle(video.name);
                }
                return Promise.resolve();
              }}
            />
          </div>
        )}
        <section>
          <div className="d-flex justify-content-between">
            <div style={{ width: "8rem" }}>
              <PrettySelect options={statusOptions} value={status} isOptionDisabled={x => x.disabled} isSearchable={false} onChange={e => setStatus(e as typeof status)} />
            </div>
            <div style={{ width: "8rem" }}>
              <PrettySelect options={focusOptions} value={focus} isSearchable={false} onChange={e => setFocus(e as typeof focus)} />
            </div>
            <div>
              <button type="button" className="action btn text-danger" disabled={isPending} onClick={() => onCancel()}>
                Cancel
              </button>
              <button title={validation || undefined} type="submit" className="action btn btn-primary" disabled={!!validation || isPending}>
                {isPending && <Spinner />} {isPending ? "Saving" : "Save"}
              </button>
            </div>
          </div>
        </section>
      </form>
    </article>
  );
};

export default ModifySessionCard;
