import React, { FunctionComponent, ReactElement } from "react";
import Select from "react-select";
import Option from "react-select/lib/components/Option";
import SingleValue from "react-select/lib/components/SingleValue";
import { OptionProps, OptionsType } from "react-select/lib/types";

const PrettySelectOption: FunctionComponent<OptionProps> = props => (
  <Option {...(props as any)}>
    <div className="d-flex align-items-center">
      {props.data.icon}
      <span className="ml-1">{props.data.label}</span>
    </div>
  </Option>
);

const PrettySelectSingleValue: FunctionComponent<OptionProps> = props => (
  <SingleValue {...(props as any)}>
    <div className="d-flex align-items-center">
      {props.data.icon}
      <span className="ml-1">{props.data.label}</span>
    </div>
  </SingleValue>
);

type OptionType = { icon?: ReactElement; label: string; value: string; [key: string]: any };

type PrettySelectProps = {
  inputId?: string;
  isDisabled?: boolean;
  isOptionDisabled?: (option: OptionType, options: OptionsType<OptionType>) => boolean | false;
  isSearchable?: boolean;
  options: OptionsType<OptionType>;
  value: string;
  onChange: (value: string) => void;
};

const PrettySelect: FunctionComponent<PrettySelectProps> = props => (
  <Select
    {...props}
    classNamePrefix="react-select"
    components={{ Option: PrettySelectOption as any, SingleValue: PrettySelectSingleValue as any }}
    isSearchable={props.isSearchable}
    menuPlacement="auto"
    menuPortalTarget={document.querySelector("body")}
    styles={{ menuPortal: s => ({ ...s, zIndex: 10000 }) }}
    value={props.options.find(x => x.value === props.value)}
    onChange={(e: any) => !Array.isArray(e) && props.onChange(e.value)}
  />
);

export default PrettySelect;
