import IAssessment from "../models/IAssessment";
import IVideo from "../models/IVideo";

type AssessmentsAction = {
  collection?: IAssessment[];
  imageId?: string;
  item?: IAssessment;
  type: "ADD_ASSESSMENT_VIDEO" | "DELETE_ASSESSMENT_IMAGE" | "DELETE_ASSESSMENT_VIDEO" | "SET_ASSESSMENT" | "SET_ASSESSMENTS" | "RESET";
  videoId?: string;
  video?: IVideo;
};

export type AssessmentsState = {
  [key: string]: IAssessment;
};

export const assessmentsReducer = (state: AssessmentsState | null = null, action: AssessmentsAction) => {
  const { collection, imageId, item, type, video, videoId } = action;

  switch (type) {
    case "ADD_ASSESSMENT_VIDEO": {
      if (state != null && video) {
        const assessment = { ...state[item!.patientId] };
        assessment.videos.push(video);
        return { ...state, [item!.patientId]: assessment };
      } else {
        return state;
      }
    }
    case "SET_ASSESSMENT": {
      return state == null ? state : { ...state, [item!.patientId]: item! };
    }
    case "SET_ASSESSMENTS": {
      return collection!.reduce((prev, curr) => ({ ...prev, [curr.patientId]: curr }), {} as AssessmentsState);
    }
    case "DELETE_ASSESSMENT_IMAGE": {
      const newState = { ...state } as AssessmentsState;
      if (item && item.patientId && imageId && newState && newState[item.patientId]) {
        newState[item.patientId].images = newState[item.patientId].images.filter(image => image._id !== imageId);
      }
      return newState;
    }
    case "DELETE_ASSESSMENT_VIDEO": {
      const newState = { ...state } as AssessmentsState;
      if (item && item.patientId && videoId && newState && newState[item.patientId]) {
        newState[item.patientId].videos = newState[item.patientId].videos.filter(video => video._id !== videoId);
      }
      return newState;
    }
    case "RESET": {
      return null;
    }
    default: {
      return state;
    }
  }
};
