import React, { FC, useState } from "react";

import PatientAccount from "../../models/PatientAccount";
import Spinner from "../core/Spinner";
import useAuth from "../../hooks/useAuth";
import { Role } from "../../models/IAuth";
import IContact from "../../models/IContact";

export type Props = {
  current: PatientAccount;
  doctors?: IContact[];
  isPending: boolean;
  onCancel: () => void;
  onSubmit: (item: PatientAccount) => void;
};

const validate = (doctorId: string, email: string, firstName: string, lastName: string) => {
  if (!email || !email.trim()) {
    return "Email is required";
  }

  if (!firstName) {
    return "First name is required";
  }

  if (!lastName || !lastName.trim()) {
    return "Last name is required";
  }

  if (doctorId === "0") {
    return "Doctor selection required";
  }

  return null;
};

const ModifyPatientCard: FC<Props> = ({ current, doctors, isPending, onCancel, onSubmit }) => {
  const auth = useAuth();
  const [doctorId, setDoctorId] = useState("0");
  const [email, setEmail] = useState(current.email || "");
  const [firstName, setFirstName] = useState(current.firstName || "");
  const [lastName, setLastName] = useState(current.lastName || "");

  const validation = validate(auth?.role === Role.organizationadmin ? doctorId : "1", email, firstName, lastName);

  return (
    <article data-cy="patient card">
      <h2>{current._id ? `Edit Patient: ${current.firstName} ${current.lastName}` : "Create Patient"}</h2>
      <section>
        <form
          autoComplete="off"
          className="material"
          onSubmit={e => {
            e.preventDefault();
            onSubmit({ doctorId, _id: current._id, email, firstName, lastName });
          }}
        >
          {auth?.role === Role.organizationadmin && doctors && doctors.length > 0 && (
            <div className="form-group">
              <select className="form-control" style={{ fontSize: "1.2rem" }} onChange={e => setDoctorId(e.target.value)}>
                <option value="0">Select Doctor</option>
                {doctors.map(doctor => (
                  <option key={doctor._id} value={doctor._id}>{`${doctor.lastName}, ${doctor.firstName}`}</option>
                ))}
              </select>
            </div>
          )}
          <div className="form-group">
            <input
              autoComplete="none"
              autoFocus={true}
              className="form-control text-primary"
              data-cy="email"
              style={{ fontSize: "1.2rem" }}
              type="email"
              placeholder="add email here..."
              required={true}
              title="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <input
              autoComplete="none"
              className="form-control text-primary"
              data-cy="first name"
              style={{ fontSize: "1.2rem" }}
              type="text"
              placeholder="add first name here..."
              required={true}
              title="first name"
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <input
              autoComplete="none"
              className="form-control text-primary"
              data-cy="last name"
              style={{ fontSize: "1.2rem" }}
              type="text"
              placeholder="add last name here..."
              required={true}
              title="last name"
              value={lastName}
              onChange={e => setLastName(e.target.value)}
            />
          </div>
          <div className="d-flex justify-content-end">
            <div>
              <button type="button" className="action btn text-danger" disabled={isPending} onClick={() => onCancel()}>
                Cancel
              </button>
              <button title={validation || undefined} type="submit" className="action btn btn-primary" disabled={!!validation || isPending}>
                {isPending && <Spinner />} {isPending ? "Saving" : "Save"}
              </button>
            </div>
          </div>
        </form>
      </section>
    </article>
  );
};

export default ModifyPatientCard;
