import React, { FC, useRef, useState } from "react";

import { noop, noopp } from "../../utils/noop";
import CancelSaveButtons from "../buttons/CancelSaveButtons";
import Required from "../core/Required";

export type AddAttachmentRequest = {
  file: File;
  name: string;
};

export type Props = {
  onCancel?: () => void;
  onSave?: (item: AddAttachmentRequest) => Promise<any>;
};

const AddAttachmentCard: FC<Props> = ({ onCancel = noop, onSave = noopp }) => {
  const [file, setFile] = useState<File>();
  const [name, setName] = useState("");
  const [isPending, setIsPending] = useState(false);
  const form = useRef<HTMLFormElement>(null);
  const isValid = form.current?.checkValidity();

  return (
    <article data-cy="add attachment card">
      <h2>Add Attachment</h2>
      <form
        ref={form}
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();

          if (!file) {
            return;
          }

          setIsPending(true);

          onSave({ file, name }).finally(() => onCancel());
        }}
      >
        <section>
          <div className="form-group">
            <label htmlFor="name">
              Name <Required />
            </label>
            <input data-cy="name" className="form-control" type="text" id="name" autoFocus={true} required={true} value={name} onChange={e => setName(e.target.value)} />
          </div>
          <div className="form-group">
            <label htmlFor="file">
              File <Required />
            </label>
            <input
              data-cy="file"
              className="form-control-file"
              type="file"
              id="file"
              accept="image/*,.doc,.docx,.pdf"
              required={true}
              onChange={e => setFile(e.target.files![0])}
            />
          </div>
          <CancelSaveButtons isPending={isPending} isValid={isValid} onCancel={onCancel} />
        </section>
      </form>
    </article>
  );
};

export default AddAttachmentCard;
