import { DependencyList, useCallback, useEffect } from "react";

type Listener<T extends keyof WindowEventMap> = (this: Window, ev: WindowEventMap[T]) => any;

const useEventListener = <T extends keyof WindowEventMap>(type: T, listener: Listener<T>, deps: DependencyList = []) => {
  const frozen = useCallback(listener, deps);

  useEffect(() => {
    window.addEventListener(type, frozen);

    return () => window.removeEventListener(type, frozen);
  }, [type, frozen]);
};

export default useEventListener;
