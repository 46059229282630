import React from "react";

import IAttachment from "../../models/IAttachment";
import ConfirmCard from "../core/ConfirmCard";

export type Props = {
  attachment: IAttachment;
  onCancel?: () => void;
  onSubmit?: () => Promise<any>;
};

export default function RemoveAttachmentCard({ attachment, onCancel, onSubmit }: Props) {
  return (
    <ConfirmCard question={`Remove ${attachment.name}.${attachment.fileExtension}?`} title="Remove Attachment" onCancel={onCancel} onSubmit={onSubmit} confirmLabel="Delete" />
  );
}
