import React, { FC } from "react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

import useAuth from "../../hooks/useAuth";
import DoctorDashboard from "../doctor/Dashboard.page";
import OrganizationAdmin from "../organizationAdmin/Dashboard.page";
import PatientDashboard from "../patient/Dashboard.page";
import { Role } from "../../models/IAuth";

const DashboardLandingPageContent: FC = () => {
  const auth = useAuth();
  const { role } = auth || { role: null };

  let content: any;
  if (role === Role.doctor) {
    content = <DoctorDashboard />;
  } else if (role === Role.organizationadmin) {
    content = <OrganizationAdmin />;
  } else {
    content = <PatientDashboard />;
  }

  return <>{content}</>;
};

const DashboardLandingPage: FC = () => {
  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <DashboardLandingPageContent />
    </MsalAuthenticationTemplate>
  );
};

export default DashboardLandingPage;
