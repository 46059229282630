import { DependencyList, useCallback } from "react";

import IAttachment from "../models/IAttachment";
import noop from "../utils/noop";
import useEventListener from "./useEventListener";

export type CustomEventMap = {
  drawCurrentAnnotations: any;
  recordReviewEnd: any;
  showAddAttachment: {
    planId: string;
    sessionId: string;
  };
  showContactUs: any;
  showLogin: any;
  showRemoveAttachment: {
    attachment: IAttachment;
    planId: string;
    sessionId: string;
  };
  showRequestGuide: any;
  showScheduleDemo: any;
  showSearch: any;
  showSelectExample: {
    planId: string;
    sessionId: string;
  };
};

type CustomListener<T extends keyof CustomEventMap> = (ev?: CustomEventMap[T]) => any;

const useCustomEvent = <T extends keyof CustomEventMap>(type: T, listener: CustomListener<T> = noop, deps: DependencyList = []) => {
  const frozen = useCallback(listener, deps);

  const dispatch = (ev?: CustomEventMap[T]) => {
    const event = new CustomEvent(type, { detail: ev });
    window.dispatchEvent(event);
  };

  useEventListener(type as any, frozen, deps);

  return dispatch;
};

export default useCustomEvent;
