import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { default as Recorder } from "./video-recorder";

import { IAppState } from "../../../store/Root";
import Spinner from "../../core/Spinner";
import VideoActions from "../VideoActions";

type Props = {
  canUpload: boolean;
  isPending: boolean;
  onCancel: () => void;
  onRecordingComplete: (video: Blob) => void;
  onSaveRecording: () => void;
  recordingMode: "camera" | "file";
  uploadProgress?: number;
};
const VideoRecorder: FC<Props> = ({ canUpload, isPending, onCancel, onRecordingComplete, onSaveRecording, recordingMode, uploadProgress }) => {
  const VIDEO_WRAPPER_CLASS = "video-recorder-wrapper";
  const [isMirrored, setIsMirrored] = useState(false);
  const [videoConstraintId, setVideoConstraintId] = useState<string>();
  const [videoInputs, setVideoInputs] = useState<Array<MediaDeviceInfo>>([]);

  const dispatch = useDispatch();
  const selectCameraPermissions = (state: IAppState) => state.settings.checkedCameraPermissions;
  const checkedCameraPermissions = useSelector(selectCameraPermissions);

  const selectDefaultVideoId = (state: IAppState) => state.settings.defaultVideoDeviceId;
  const defaultVideoDeviceId = useSelector(selectDefaultVideoId);

  useEffect(() => {
    if (defaultVideoDeviceId) {
      setVideoConstraintId(defaultVideoDeviceId);
    }
  }, [defaultVideoDeviceId]);

  useEffect(() => {
    if (!checkedCameraPermissions) {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(stream => {
          stream.getTracks().forEach(track => track.stop());
        })
        .finally(() => {
          dispatch({ type: "SET_VIDEO_PERMISSIONS_CHECKED" });
        });
    }
  }, [checkedCameraPermissions, dispatch]);

  const handleVideoReady = (stream: MediaStream) => {
    if (videoInputs.length === 0) {
      getVideoDevices();
    }
    const tracks = stream.getVideoTracks();
    if (tracks.length > 0) {
      const capabilities = tracks[0].getCapabilities();
      if (capabilities.facingMode !== undefined && capabilities.facingMode.includes("user")) {
        setIsMirrored(true);
      }
    }
  };

  const getVideoDevices = () => {
    navigator.mediaDevices.enumerateDevices().then(devices => {
      const videoDevices = devices.filter(device => device.kind === "videoinput");
      setVideoInputs(videoDevices);
    });
  };

  const handleSwitchCamera = (deviceId: string) => {
    const currentVideoDevice = videoInputs.find(videoInput => videoInput.deviceId === deviceId);
    if (currentVideoDevice) {
      const indexOfCurrentVideo = videoInputs.indexOf(currentVideoDevice);
      if (indexOfCurrentVideo !== -1) {
        const inputDeviceInfo = videoInputs[indexOfCurrentVideo] as InputDeviceInfo;
        const capabilities = inputDeviceInfo.getCapabilities();
        dispatch({ type: "SET_DEFAULT_VIDEO_ID", item: inputDeviceInfo.deviceId });
        setIsMirrored(capabilities.facingMode !== undefined && capabilities.facingMode.includes("user"));
        setVideoConstraintId(inputDeviceInfo.deviceId);
      }
    }
  };

  return (
    <div style={{ position: "fixed", top: 0, left: 0, height: "100%", width: "100%", zIndex: 999 }}>
      {checkedCameraPermissions && (
        <>
          <Recorder
            onSwitchCamera={handleSwitchCamera}
            videoClassName="capture-video"
            cameraViewClassName="camera-view-wrap"
            wrapperClassName={VIDEO_WRAPPER_CLASS}
            isFlipped={isMirrored}
            countdownTime={0}
            isOnInitially={recordingMode !== "file"}
            renderActions={VideoActions}
            replayVideoAutoplayAndLoopOff={true}
            showReplayControls={true}
            useVideoInput={recordingMode === "file"}
            onRecordingComplete={onRecordingComplete}
            onCameraOn={handleVideoReady}
            defaultVideoDeviceId={videoConstraintId}
          />
          <div style={{ top: 0, left: 0, padding: "1rem", position: "absolute" }}>
            <div style={{ background: "white", padding: ".25rem", borderRadius: ".5rem" }}>
              <button type="button" className="action btn text-danger" disabled={isPending} onClick={onCancel}>
                Cancel
              </button>
              <button data-cy="video upload button" type="button" className="action btn btn-primary" disabled={!canUpload || isPending} onClick={onSaveRecording}>
                {isPending && <Spinner />} {isPending ? `Uploading${uploadProgress ? ` ${uploadProgress}%` : ""}` : "Upload"}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default VideoRecorder;
