import React, { FC } from "react";

import IContact from "../../models/IContact";
import ISession from "../../models/ISession";
import { formatObjectId } from "../../services/DateService";
import Avatar from "../avatar/Avatar";
import { CalendarIcon, EditIcon, StatusIcon, VerticalDotsIcon } from "../core/Icons";
import VideoPreview from "../video/VideoPreview";

type Props = {
  contact?: IContact | null;
  session: ISession;
  showStatus?: boolean;
  onEditSession?: () => void;
};

const SessionPreview: FC<Props> = ({ contact, session, showStatus, onEditSession }) => {
  const latestVideo = session.videos != null && session.videos.length > 0 ? session.videos[session.videos.length - 1] : undefined;

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <VideoPreview video={latestVideo} />

      <section className="border-without-top p-2" style={{ flex: "1 1 auto" }}>
        {onEditSession && (
          <>
            <div data-toggle="dropdown" style={{ cursor: "pointer", float: "right", minWidth: "1rem", textAlign: "center" }}>
              <VerticalDotsIcon />
            </div>
            <div className="dropdown-menu dropdown-menu-right">
              <div
                className="dropdown-item"
                onClick={e => {
                  e.preventDefault();
                  onEditSession();
                }}
              >
                <EditIcon /> Edit Session
              </div>
            </div>
          </>
        )}
        <h3>{session.title}</h3>
        <div className="info-wrap">
          {contact && (
            <div>
              <Avatar {...contact} circle={true} size={24} /> {contact.firstName[0]}. {contact.lastName}
            </div>
          )}
          {showStatus && (
            <div>
              <StatusIcon status={session.status} /> {session.status}
            </div>
          )}
          <div>
            <CalendarIcon /> {formatObjectId(session._id)}
          </div>
        </div>
      </section>
    </div>
  );
};

export default SessionPreview;
