export const supportVideos = [
  {
    title: "TeleCare Rx Welcome and Setup",
    url:
      "https://telecare.blob.core.windows.net/prod/TelecareRxWelcomeAndSetup.mp4?sp=r&st=2020-02-17T03:37:10Z&se=2040-02-17T11:37:10Z&spr=https&sv=2019-02-02&sr=b&sig=F2R9XK%2FbdhyNsDv9OhP24npNJl0%2B%2B5QoH05vTxqOloY%3D",
  },
  {
    title: "First Video",
    url:
      "https://telecare.blob.core.windows.net/prod/FirstVideo.mp4?sp=r&st=2020-05-04T18:34:44Z&se=2040-05-05T02:34:44Z&spr=https&sv=2019-10-10&sr=b&sig=dNdtCY5rC8F6iV0rWZpYFr%2FkeiYvt9f0b9x157Wb%2BlI%3D",
  },
];

export const doctorVideos = [
  {
    title: "Welcome and Intro - Listen to This FIRST!",
    url:
      "https://telecare.blob.core.windows.net/prod/Welcome%20and%20Intro%20-%20Listen%20to%20This%20FIRST!.mp4?sp=r&st=2020-08-07T18:57:56Z&se=2030-08-08T02:57:56Z&spr=https&sv=2019-12-12&sr=b&sig=PWfF3pZEt%2FCejYocxn2iXufhmgJ0E6VDYjaBd9UApIs%3D",
  },
  {
    title: "Module 1 - Provider Account Sign-in",
    url:
      "https://telecare.blob.core.windows.net/prod/Module%201%20-%20Provider%20Account%20Sign-in.mp4?sp=r&st=2020-08-04T13:35:56Z&se=2030-08-04T21:35:56Z&spr=https&sv=2019-12-12&sr=b&sig=B8c8zaLktQaIbiOCxMA7AnPmpwKZ87I91sKDf%2F5D1GM%3D",
  },
  {
    title: "Module 2 - 1 Click App Setup on Workstations",
    url:
      "https://telecare.blob.core.windows.net/prod/Module%202%20-%201%20Click%20App%20Setup%20on%20Workstations.mp4?sp=r&st=2020-08-04T13:37:17Z&se=2030-08-04T21:37:17Z&spr=https&sv=2019-12-12&sr=b&sig=4oBtXIw9zDHgUyqn%2B%2FvLAtCaHbBqrtk8AUBmx5afpK4%3D",
  },
  {
    title: "Module 3 - Adding a Patient to Caseload and Patient Setup",
    url:
      "https://telecare.blob.core.windows.net/prod/Module%203%20-%20Adding%20a%20Patient%20to%20Caseload%20and%20Patient%20Setup.mp4?sp=r&st=2020-08-04T13:37:44Z&se=2030-08-04T21:37:44Z&spr=https&sv=2019-12-12&sr=b&sig=P4F1qr0npuBR%2F2cUZqpOIFdmGrgPPDiD6CwOJNoqQ7Q%3D",
  },
  {
    title: "Module 3.1 - App Setup on Patient's Mobile Device",
    url:
      "https://telecare.blob.core.windows.net/prod/Module%203.1%20-%20App%20Setup%20on%20Patient's%20Mobile%20Device.mp4?sp=r&st=2020-08-04T13:38:04Z&se=2030-08-04T21:38:04Z&spr=https&sv=2019-12-12&sr=b&sig=jmnCwPsPPA4cz%2F6Hu4yW7mWpObOd7VRCVLmpfi7kKD8%3D",
  },
  {
    title: "Module 4 - Navigating the Default Video Library",
    url:
      "https://telecare.blob.core.windows.net/prod/Module%204%20-%20Navigating%20the%20Default%20Video%20Library.mp4?sp=r&st=2021-02-12T19:28:40Z&se=2041-02-13T03:28:40Z&spr=https&sv=2019-12-12&sr=b&sig=ba93U11%2BNXR3MW87quDT1ayqE6Nlo44zLqmAaKR3zRg%3D",
  },
  {
    title: "Module 4.1 - Adding a Video Sessions to a Patient Account",
    url:
      "https://telecare.blob.core.windows.net/prod/Module%204%20-%20Adding%20a%20Video%20Sessions%20to%20a%20Patient%20Account.mp4?sp=r&st=2020-08-04T13:38:30Z&se=2030-08-04T21:38:30Z&spr=https&sv=2019-12-12&sr=b&sig=ErA6jgWbNlsDAT9MFJ%2B3XFPpxE6JW0tZmafUsGzMPqM%3D",
  },
  {
    title: "Module 5 - Creating a Video from an External Source",
    url:
      "https://telecare.blob.core.windows.net/prod/Module%205%20-%20Creating%20a%20Video%20from%20an%20External%20Source.mp4?sp=r&st=2020-08-04T13:38:57Z&se=2030-08-04T21:38:57Z&spr=https&sv=2019-12-12&sr=b&sig=Tx297S1YZcG70qHsdVB3DZdX%2BYmsTs9v0FgWlbzwuQY%3D",
  },
  {
    title: "Module 5.1 - Equipment List and Trimming a Long Video to Multiple Single Videos",
    url:
      "https://telecare.blob.core.windows.net/prod/Module%205.1%20-%20Equipment%20List%20and%20Trimming%20a%20Long%20Video%20to%20Multiple%20Single%20Videos.mp4?sp=r&st=2020-08-05T13:38:11Z&se=2030-08-05T21:38:11Z&spr=https&sv=2019-12-12&sr=b&sig=ef6nsrHArUtNomUN0P%2BMDv1W1fgj5afrbLAj08ogT6Y%3D",
  },
  {
    title: "Module 5.2 - Elaborate Editing Process Using Camtasia",
    url:
      "https://telecare.blob.core.windows.net/prod/Module%205.2%20-%20Elaborate%20Editing%20Process%20Using%20Camtasia.mp4?sp=r&st=2020-08-05T13:39:20Z&se=2030-08-05T21:39:20Z&spr=https&sv=2019-12-12&sr=b&sig=T%2BlsJ%2BB3VQHQlCvH9q34c0LUYUVH1vT4UJnlnK3PWZk%3D",
  },
  {
    title: "Module 5.3 - Creating a Video using TeleCare Rx",
    url:
      "https://telecare.blob.core.windows.net/prod/Module%205.3%20-%20Creating%20a%20Video%20using%20TeleCare%20Rx.mp4?sp=r&st=2020-08-05T13:39:46Z&se=2030-08-05T21:39:46Z&spr=https&sv=2019-12-12&sr=b&sig=fJhjKdIYVo2iU%2FGf%2BkI5Y5FMFfRVHdJacp4pH0nyxYc%3D",
  },
  {
    title: "Module 6 - Importing a Video from an External Source and Adding to Your Library",
    url:
      "https://telecare.blob.core.windows.net/prod/Module%206%20-%20Importing%20a%20Video%20from%20an%20External%20Source%20and%20Adding%20to%20Your%20Library.mp4?sp=r&st=2020-08-05T13:40:07Z&se=2030-08-05T21:40:07Z&spr=https&sv=2019-12-12&sr=b&sig=gx8qRV7WEyMdPAqdn2YTGbiRD8QoIprghqaYdJFXBfw%3D",
  },
  {
    title: "Module 7 - Reviewing a Patient Video",
    url:
      "https://telecare.blob.core.windows.net/prod/Module%207%20-%20Reviewing%20a%20Patient%20Video.mp4?sp=r&st=2020-08-05T13:40:27Z&se=2030-08-05T21:40:27Z&spr=https&sv=2019-12-12&sr=b&sig=Zj4sn22Ga99eUcSMbRMEf5WdTjR5a0K9IC1yh7jHcuk%3D",
  },
  {
    title: "Module 8 - Patient Experience",
    url:
      "https://telecare.blob.core.windows.net/prod/Module%208%20-%20Patient%20Experience.mp4?sp=r&st=2020-08-31T16:43:01Z&se=2030-09-01T00:43:01Z&spr=https&sv=2019-12-12&sr=b&sig=Gkflm4DGczKkO2VS9UYl32v1Tfaw7UheQrWkeTLXsEU%3D",
  },
  {
    title: "Module 9 - Generate Revenue",
    url:
      "https://telecare.blob.core.windows.net/prod/Module%209%20-%20Generate%20Revenue.mp4?sp=r&st=2020-08-31T16:43:47Z&se=2030-09-01T00:43:47Z&spr=https&sv=2019-12-12&sr=b&sig=qbf7nu0fCejF%2FSRdgiINY6gJ7nyct4fCuXV8rKQPcTg%3D",
  },
  {
    title: "Module 10 - Reducing Expenses",
    url:
      "https://telecare.blob.core.windows.net/prod/Module%2010%20-%20Reducing%20Expenses.mp4?sp=r&st=2020-08-31T16:44:23Z&se=2030-09-01T00:44:23Z&spr=https&sv=2019-12-12&sr=b&sig=PoknWz8R9HEr8QNy9ddUoIJRiSlPjRjjRIQx%2FubJJrA%3D",
  },
];
