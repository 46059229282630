import React, { FC } from "react";
import AssessmentImageDisplay from "../assessments/AssessmentImageDisplay";
import IAttachment from "../../models/IAttachment";
import IImage from "../../models/IImage";
import ViewFileCard from "./ViewFileCard";
import useCustomEvent from "../../hooks/useCustomEvent";

type Props = {
  attachements: IAttachment[];
  images: IImage[];
  onImageDelete: (image: IImage) => void;
  planId: string;
  sessionId: string;
};

const SessionFilesCard: FC<Props> = ({ attachements, images, onImageDelete, planId, sessionId }) => {
  const showRemoveAttachment = useCustomEvent("showRemoveAttachment");

  const files: Array<{ fileType: string; file: IImage | IAttachment }> = [
    ...attachements.map(attachment => ({ fileType: "attachment", file: attachment })),
    ...images.map(image => ({ fileType: "image", file: image })),
  ];

  files.sort((x, y) => x.file._id.localeCompare(y.file._id));

  return (
    <>
      {files.length > 0 && (
        <>
          <h3 className="text-primary" style={{ paddingTop: "2rem" }}>
            Files
          </h3>
          <div className="grid" style={{ padding: "1rem 0" }}>
            {files.map(fileObj => {
              if (fileObj.fileType === "image") {
                return <AssessmentImageDisplay key={`session-file-${fileObj.file._id}`} allowImagineSelect={false} image={fileObj.file as IImage} onImageDelete={onImageDelete} />;
              } else {
                return (
                  <ViewFileCard
                    key={`session-file-${fileObj.file._id}`}
                    attachment={fileObj.file as IAttachment}
                    onAttachmentDelete={() => showRemoveAttachment({ attachment: fileObj.file as IAttachment, planId, sessionId })}
                  />
                );
              }
            })}
          </div>
        </>
      )}
    </>
  );
};

export default SessionFilesCard;
