import React, { useState } from "react";

import { noop, noopp } from "../../utils/noop";
import CancelSaveButtons from "../buttons/CancelSaveButtons";

export type Props = {
  confirmLabel?: string;
  confirmPendingLabel?: string;
  question?: string;
  title?: string;
  onCancel?: () => void;
  onSubmit?: () => Promise<any>;
};

export default function ConfirmCard({ confirmLabel = "Save", confirmPendingLabel = "Saving", question, title, onCancel = noop, onSubmit = noopp }: Props) {
  const [isPending, setIsPending] = useState(false);

  return (
    <article data-cy="confirm card">
      {!!title && <h2>{title}</h2>}
      <form
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();

          setIsPending(true);

          onSubmit().finally(() => onCancel());
        }}
      >
        <section>
          <p>{question}</p>
          <CancelSaveButtons isPending={isPending} isValid={true} onCancel={onCancel} label={confirmLabel} labelPending={confirmPendingLabel} />
        </section>
      </form>
    </article>
  );
}
