import React, { FC, useState } from "react";

import { toast } from "react-toastify";

import IContact from "../../models/IContact";
import IPlan from "../../models/IPlan";
import ISession from "../../models/ISession";
import LoadingBlock from "../core/LoadingBlock";
import ModifySessionModal from "./ModifySessionModal";
import ViewSessionCard from "./ViewSessionCard";
import { Role } from "../../models/IAuth";

type Props = {
  contacts: Map<string, IContact>;
  doctorId: string;
  isVideoPending?: boolean;
  patientId?: string;
  role: Role;
  plan?: IPlan;
  session?: ISession;
  onAddComment: (value: string) => void;
  onAddVideo: (value: Blob, recordingType: "example" | "doctor" | "patient") => void;
  onRemoveAttachment: (id: string) => Promise<any>;
  onSaveSession?: (session: ISession) => Promise<any>;
  onSendInvitation?: (item: string) => Promise<any>;
  uploadProgress?: number;
};

const SessionDetail: FC<Props> = props => {
  const [editSession, setEditSession] = useState<boolean>(false);
  const [isSavingSession, setIsSavingSession] = useState(false);

  let patient = undefined;
  let contact = undefined;

  if (props.patientId) {
    contact = props.contacts.get(props.role === Role.doctor ? props.patientId : props.doctorId);
    patient = props.contacts.get(props.patientId);
  }

  return (
    <main>
      {props.session ? (
        <ViewSessionCard
          {...props}
          contact={contact}
          plan={props.plan!}
          session={props.session!}
          onEditSession={() => setEditSession(true)}
          showStatus={props.role === Role.doctor}
        />
      ) : (
        <section>
          <LoadingBlock />
        </section>
      )}
      {props.session && editSession && (
        <ModifySessionModal
          current={props.session}
          isPending={isSavingSession}
          patient={patient}
          planId={props.plan!._id}
          onCancel={() => setEditSession(false)}
          onSendInvitation={props.onSendInvitation}
          onSubmit={x => {
            if (!props.onSaveSession) {
              return Promise.resolve();
            }

            setIsSavingSession(true);

            return props
              .onSaveSession({ ...props.session, ...x })
              .then(() => setEditSession(false))
              .catch(err => toast.error(err.message))
              .finally(() => setIsSavingSession(false));
          }}
        />
      )}
    </main>
  );
};

export default SessionDetail;
