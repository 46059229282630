import IEducation from "../models/IEducation";

type EducationsAction = {
  collection?: IEducation[];
  item?: IEducation;
  type: "DELETE_EDUCATION_VIDEO" | "SET_EDUCATION" | "SET_EDUCATIONS" | "RESET";
  videoId?: string;
};

export type EducationsState = {
  [key: string]: IEducation;
};

export const educationsReducer = (state: EducationsState | null = null, action: EducationsAction) => {
  const { collection, item, type, videoId } = action;

  switch (type) {
    case "SET_EDUCATION": {
      return state == null ? state : { ...state, [item!.patientId]: item! };
    }
    case "SET_EDUCATIONS": {
      return collection!.reduce((prev, curr) => ({ ...prev, [curr.patientId]: curr }), {} as EducationsState);
    }
    case "DELETE_EDUCATION_VIDEO": {
      const newState = { ...state } as EducationsState;
      if (item && item.patientId && videoId && newState && newState[item.patientId]) {
        newState[item.patientId].videos = newState[item.patientId].videos.filter(video => video._id !== videoId);
      }
      return newState;
    }
    case "RESET": {
      return null;
    }
    default: {
      return state;
    }
  }
};
