import React, { FC } from "react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { usePlanTemplates } from "../../hooks/useCollection";
import { compareStrings } from "../../utils/sort";
import PlanTemplates from "./PlanTemplates";

const PlanTemplatePageContent: FC = () => {
  const planTemplates = usePlanTemplates();
  const planTemplatesList = planTemplates && Object.values(planTemplates).sort((x, y) => compareStrings(x.title, y.title));

  return <PlanTemplates planTemplates={planTemplatesList} />;
};

const PlanTemplatePage: FC = () => {
  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <PlanTemplatePageContent />
    </MsalAuthenticationTemplate>
  );
};

export default PlanTemplatePage;
