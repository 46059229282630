import React, { FunctionComponent, HTMLAttributes } from "react";

import classNames from "classnames";

type IconProps = HTMLAttributes<HTMLElement>;
type IconComponent = FunctionComponent<IconProps>;

const IconBase: FunctionComponent<IconProps & { iconClass: string; iconColor?: string }> = ({ iconClass, iconColor, ...props }) => (
  <i {...props} style={{ color: iconColor, ...props.style }} className={classNames(props.className, iconClass)} />
);

export const AttachmentIcon: IconComponent = props => <IconBase {...props} iconClass="fas fa-paperclip" />;
export const BellIcon: IconComponent = props => <IconBase {...props} iconClass="fas fa-bell" />;
export const CalendarIcon: IconComponent = props => <IconBase {...props} iconClass="far fa-calendar" />;
export const CameraIcon: IconComponent = props => <IconBase {...props} iconClass="fas fa-camera fa-2x" />;
export const CreateIcon: IconComponent = props => <IconBase {...props} iconClass="fas fa-plus" iconColor="var(--add)" />;
export const CropIcon: IconComponent = props => <IconBase {...props} iconClass="fas fa-crop fa-2x" />;
export const DeleteIcon: IconComponent = props => <IconBase {...props} iconClass="fas fa-trash" />;
export const DocumentIcon: IconComponent = props => <IconBase {...props} iconClass="far fa-file-alt" iconColor="var(--primary)" />;
export const EditIcon: IconComponent = props => <IconBase {...props} iconClass="fas fa-pen" />;
export const ExerciseIcon: IconComponent = props => <IconBase {...props} iconClass="fas fa-running" iconColor="#d32f2f" />;
export const ExpandIcon: IconComponent = props => <IconBase {...props} iconClass="fas fa-arrows-alt-v" iconColor="var(--primary-dark)" />;
export const FilterIcon: IconComponent = props => <IconBase {...props} iconClass="fas fa-filter" />;
export const ImageIcon: IconComponent = props => <IconBase {...props} iconClass="fas fa-image" iconColor="var(--primary)" />;
export const LineIcon: IconComponent = props => <IconBase {...props} iconClass="fas fa-long-arrow-alt-up" />;
export const LogoutIcon: IconComponent = props => <IconBase {...props} iconClass="fas fa-sign-out-alt" />;
export const MailIcon: IconComponent = props => <IconBase {...props} iconClass="far fa-envelope" />;
export const PersonIcon: IconComponent = props => <IconBase {...props} iconClass="fas fa-walking" />;
export const PointerIcon: IconComponent = props => <IconBase {...props} iconClass="fas fa-mouse-pointer" />;
export const ProfileIcon: IconComponent = props => <IconBase {...props} iconClass="fas fa-user" />;
export const SearchIcon: IconComponent = props => <IconBase {...props} iconClass="fas fa-search" />;
export const StarIcon: IconComponent = props => <IconBase {...props} iconClass="far fa-star" />;
export const SupportIcon: IconComponent = props => <IconBase {...props} iconClass="fas fa-question-circle" />;
export const TimesIcon: IconComponent = props => <IconBase {...props} iconClass="fas fa-times" />;
export const UnknownIcon: IconComponent = props => <IconBase {...props} iconClass="far fa-question-circle" />;
export const UploadFileIcon: IconComponent = props => <IconBase {...props} iconClass="fas fa-file-upload" />;
export const UploadIcon: IconComponent = props => <IconBase {...props} iconClass="fas fa-cloud-upload-alt" iconColor="var(--primary)" />;
export const VerticalDotsIcon: IconComponent = props => <IconBase {...props} iconClass="fas fa-ellipsis-v" iconColor="gray" />;
export const VideoLibraryIcon: IconComponent = props => <IconBase {...props} iconClass="fas fa-photo-video" />;
export const VideoIcon: IconComponent = props => <IconBase {...props} iconClass="fas fa-video" iconColor="#d32f2f" />;
export const WrenchIcon: IconComponent = props => <IconBase {...props} iconClass="fa fa-wrench" />;

// Status Icons
export const DraftIcon: IconComponent = props => <IconBase {...props} iconClass="fas fa-drafting-compass" iconColor="orange" />;
export const ActiveIcon: IconComponent = props => <IconBase {...props} iconClass="far fa-check-circle" iconColor="var(--success)" />;
export const ArchiveIcon: IconComponent = props => <IconBase {...props} iconClass="fas fa-toggle-off" iconColor="gray" />;

type StatusIconProps = HTMLAttributes<HTMLElement> & {
  status: "draft" | "active" | "archive";
};

export const StatusIcon: FunctionComponent<StatusIconProps> = ({ status, ...props }) => {
  switch (status) {
    case "draft": {
      return <DraftIcon {...props} />;
    }
    case "active": {
      return <ActiveIcon {...props} />;
    }
    case "archive": {
      return <ArchiveIcon {...props} />;
    }
  }

  return null;
};
