import React, { FC } from "react";

import Modal from "../core/Modal";
import ModifyPatientCard, { Props as CardProps } from "./ModifyPatientCard";

type Props = CardProps;

const ModifyPatientModal: FC<Props> = props => (
  <Modal onClose={props.onCancel}>
    <ModifyPatientCard {...props} />
  </Modal>
);

export default ModifyPatientModal;
