import React from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { Link, useLocation } from "react-router-dom";
import SessionPreview from "../sessions/SessionPreview";
import ISession from "../../models/ISession";

type Props = {
  handleEditSession: (session: ISession) => void;
  planId: string;
  sectionId: string;
  sectionLabel: string;
  videoSection: Array<ISession>;
};

export default function DroppableSection({ handleEditSession, planId, sectionId, sectionLabel, videoSection }: Props) {
  const location = useLocation();
  const currentPath = location.pathname as string;
  let sessionLocation = "/plans/";
  if (currentPath.toLocaleLowerCase().startsWith("/plantemplates")) {
    sessionLocation = "/plantemplates/";
  }
  const grid = 8;

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 ${grid}px 0 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver: Boolean) => ({
    background: isDraggingOver ? "lightblue" : "initial",
    padding: grid,
    overflow: "auto",
  });

  return (
    <>
      <h3 style={{ padding: "0.5rem 0" }}>{sectionLabel}</h3>
      <Droppable droppableId={sectionId} direction="horizontal">
        {(provided, snapshot) => (
          <div ref={provided.innerRef} style={{ ...getListStyle(snapshot.isDraggingOver), border: "1px solid #e0e0e0" }} {...provided.droppableProps} className="grid">
            {videoSection.map((session, index) => (
              <Draggable key={session._id} draggableId={session._id} index={index}>
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                    <Link data-cy={`session title ${session.title}`} key={session._id} to={`${sessionLocation}${planId}/sessions/${session._id}`}>
                      <SessionPreview session={session} showStatus={true} onEditSession={() => handleEditSession(session)} />
                    </Link>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </>
  );
}
