import React, { FC, useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import ModalSlim from "../core/ModalSlim";
import Spinner from "../core/Spinner";
import IContact from "../../models/IContact";
import { deletePatient } from "../../services/CaseloadService";

type Props = {
  onCancel: () => void;
  patient: IContact;
};

const DeletePatientModal: FC<Props> = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isPending, setIsPending] = useState(false);

  const handleDeletePatient = () => {
    setIsPending(true);

    deletePatient(props.patient._id)
      .then(() => {
        props.onCancel();
        history.push("/");
        dispatch({ type: "REMOVE_CONTACT", item: { _id: props.patient._id } });
        toast.success("Patient deleted");
      })
      .catch(response => {
        toast.error("Error deleting patient: " + response);
        setIsPending(false);
      });
  };

  return (
    <ModalSlim>
      <article data-cy="delete patient modal">
        <h2>Delete Patient?</h2>
        <form
          autoComplete="off"
          className="material"
          onSubmit={e => {
            e.preventDefault();

            handleDeletePatient();
          }}
        >
          <section>
            <p>Are you sure you want to delete this patient and all patient data? This cannot be undone.</p>
            <div className="d-flex justify-content-between">
              <button type="button" className="action btn" disabled={isPending} onClick={props.onCancel}>
                Cancel
              </button>
              <button title="Delete Video" type="submit" className="action btn btn-danger" disabled={isPending}>
                {isPending && <Spinner />} {isPending ? "Deleting" : "Delete Patient"}
              </button>
            </div>
          </section>
        </form>
      </article>
    </ModalSlim>
  );
};

export default DeletePatientModal;
