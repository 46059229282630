import React, { FC, useCallback, useRef, useState } from "react";

import ObjectId from "bson-objectid";
import { format, formatDistanceStrict } from "date-fns";

import IComment from "../../models/IComment";
import IContact from "../../models/IContact";
import IPlan from "../../models/IPlan";
import ISession from "../../models/ISession";
import IVideo from "../../models/IVideo";

import { formatObjectId } from "../../services/DateService";
import { compareProps } from "../../utils/sort";
import Avatar from "../avatar/Avatar";
import {
  AttachmentIcon,
  CalendarIcon,
  CreateIcon,
  DeleteIcon,
  EditIcon,
  ExerciseIcon,
  ImageIcon,
  StatusIcon,
  UploadIcon,
  VerticalDotsIcon,
  VideoIcon,
  VideoLibraryIcon,
} from "../core/Icons";
import AudioVideoRecording from "../video/AudioVideoRecording";
import VideoRecorder from "../video/videoRecorder/VideoRecorder";
import UploadDeviceVideo from "../video/UploadDeviceVideo";
import AddToVideoLibrary from "./AddToVideoLibrary";
import BackLink from "./BackLink";
import VideoDisplay from "../video/VideoDisplay";
import DeleteVideoModal from "./DeleteVideoModal";
import UploadImage from "../image/UploadImage";
import { toast } from "react-toastify";
import { addImageToSession, deleteSessionImage } from "../../services/PlansService";
import { addTemplateImageToSession, deleteSessionTemplateImage } from "../../services/PlanTemplatesService";
import { dispatch } from "../../store/Store";
import ModalSlim from "../core/ModalSlim";
import ConfirmCard from "../core/ConfirmCard";
import { Role } from "../../models/IAuth";
import { useSelector } from "react-redux";
import { IAppState } from "../../store/Root";
import IAttachment from "../../models/IAttachment";
import SessionFilesCard from "./SessionFilesCard";
import { Link } from "react-router-dom";

export type Props = {
  contact?: IContact;
  contacts: Map<string, IContact>;
  isVideoPending?: boolean;
  role: Role;
  patientId?: string;
  plan: IPlan;
  session: ISession;
  showStatus?: boolean;
  onAddComment: (value: string) => void;
  onAddVideo: (value: Blob, recordingType: "example" | "doctor" | "patient", patientVideoId?: string) => void;
  onEditSession: () => void;
  uploadProgress?: number;
};

type State = {
  addComment: string;
  addToLibrary: boolean;
  deleteVideoModal: boolean;
  imageToDelete?: string;
  imageUpload: boolean;
  isUploading: boolean;
  recording?: "example" | "doctor" | "patient";
  recordingBlob?: Blob;
  recordingMode?: "camera" | "file";
  currentVideo: IVideo | undefined;
};

const ViewSessionCard: FC<Props> = ({ contact, contacts, isVideoPending, role, patientId, plan, session, showStatus, onAddComment, onAddVideo, onEditSession, uploadProgress }) => {
  const [addComment, setAddComment] = useState("");
  const [addToLibrary, setAddToLibrary] = useState(false);
  const [currentVideo, setCurrentVideo] = useState<IVideo>();
  const [deleteVideoModal, setDeleteVideoModal] = useState(false);
  const [imageToDelete, setImageToDelete] = useState<string>();
  const [imageUpload, setImageUpload] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [recording, setRecording] = useState<"example" | "doctor" | "patient">();
  const [recordingBlob, setRecordingBlob] = useState<Blob>();
  const [recordingMode, setRecordingMode] = useState<"camera" | "file">();

  const addCommentRef = useRef<HTMLTextAreaElement>(null);

  const handleRecordingComplete = useCallback(
    (e: Blob) => {
      setRecordingBlob(e);
    },
    [setRecordingBlob],
  );

  const { description } = session;

  const getHistory = () => {
    const joined = [...session.comments];

    return joined.sort((a, b) => compareProps(a, b, { _id: 1 }));
  };

  const selfContact = Array.from(contacts.values()).find(x => x.self)!;
  const history = getHistory();
  const filesUploadingSelector = (state: IAppState) => state.fileUpload.filesUploading;
  const filesUploading = useSelector(filesUploadingSelector);

  const getVideoHtml = (attachments: IAttachment[], videos: IVideo[], role: Role) => {
    let videoHtml = null;
    let patientVideos = 1,
      exampleVideos = 1;

    if (videos.length > 0) {
      const videoLabels: any = {};
      videos.forEach(video => {
        switch (video.videoType) {
          case "example": {
            videoLabels[video._id] = `Example Video ${exampleVideos++}`;
            break;
          }
          case "patient": {
            videoLabels[video._id] = `Patient Video ${patientVideos++}`;
            break;
          }
        }
      });

      videoHtml = (
        <div className="row" style={{ paddingTop: "1rem" }}>
          {videos.map((video, i) => {
            const fileProgress = filesUploading.find(file => file.name === video._id);
            return (
              <div className="col-md-3 col-sm-6 col-xs-12" key={`video_${video._id}`} style={{ paddingBottom: "1rem" }}>
                <VideoDisplay data-cy={`${video.videoType} review`} key={video.videoType} sasMp4={video.sasMp4} sas={video.sas} />
                <div>
                  <h3 style={{ display: "inline-block" }}>{video.videoType === "doctor" ? `${videoLabels[video.patientVideoId!] || ""} Review` : videoLabels[video._id]}</h3>
                  {fileProgress && fileProgress.percentComplete < 100 && <span>&nbsp;{fileProgress.percentComplete}% uploaded</span>}
                  {(role === Role.doctor || role === Role.organizationadmin) && (
                    <>
                      <div data-cy="session actions" data-toggle="dropdown" style={{ cursor: "pointer", float: "right", minWidth: "1rem", textAlign: "center" }}>
                        <VerticalDotsIcon />
                      </div>

                      <div className="dropdown-menu dropdown-menu-right">
                        {video.videoType === "example" && (
                          <>
                            <div
                              className="dropdown-item"
                              onClick={() => {
                                setCurrentVideo(video);
                                setAddToLibrary(true);
                              }}
                            >
                              <CreateIcon />
                              Add to Library
                            </div>
                            <div className="dropdown-divider"></div>
                          </>
                        )}
                        {video.videoType === "patient" && !videos.some(review => review.patientVideoId === video._id) && (
                          <>
                            <div
                              className="dropdown-item"
                              onClick={() => {
                                setRecording("doctor");
                                setCurrentVideo(video);
                              }}
                            >
                              <VideoIcon /> Record Review
                            </div>
                            <div className="dropdown-divider"></div>
                          </>
                        )}
                        <div
                          className="dropdown-item"
                          onClick={() => {
                            setCurrentVideo(video);
                            setDeleteVideoModal(true);
                          }}
                        >
                          <DeleteIcon style={{ color: "red" }} /> Delete Video
                        </div>
                      </div>
                    </>
                  )}
                  {role === Role.patient && video.videoType === "patient" && (
                    <>
                      <div data-cy="session actions" data-toggle="dropdown" style={{ cursor: "pointer", float: "right", minWidth: "1rem", textAlign: "center" }}>
                        <VerticalDotsIcon />
                      </div>
                      <div className="dropdown-menu dropdown-menu-right">
                        <div
                          className="dropdown-item"
                          onClick={() => {
                            setCurrentVideo(video);
                            setDeleteVideoModal(true);
                          }}
                        >
                          <DeleteIcon style={{ color: "red" }} /> Delete Video
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      );
    } else if (role === Role.doctor || role === Role.organizationadmin) {
      videoHtml = (
        <div className="embed-responsive embed-responsive-16by9">
          <img className="embed-responsive-item" src="/images/video-placeholder.jpg" alt="Video Placeholder" />
        </div>
      );
    } else {
      videoHtml = (
        <div className="embed-responsive embed-responsive-16by9">
          <div
            className="embed-responsive-item"
            data-cy="record video image"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setRecording("patient");
              setRecordingMode("camera");
            }}
          >
            <div style={{ background: "black", color: "#ddd", fontSize: "2rem", display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
              <VideoIcon style={{ marginRight: ".5rem" }} /> Record Video
            </div>
          </div>
        </div>
      );
    }

    return videoHtml;
  };

  const videoHtml = getVideoHtml(session.attachments, session.videos, role);

  const handleImageUpload = (blob: Blob) => {
    if (blob.type.indexOf("image/") === -1) {
      toast.error("Please select an image");
    } else {
      setIsUploading(true);
      const fileExtension = blob.type.replace("image/", "");
      let addImagePromise: Promise<IVideo> | undefined = undefined;
      if (patientId) {
        addImagePromise = addImageToSession(blob, fileExtension, plan._id, session._id, (name: string, percentComplete: number) => {
          // setUploadProgress(progress);
          // this.setState({ uploadProgress: progress })
        });
      } else {
        addImagePromise = addTemplateImageToSession(blob, fileExtension, plan._id, session._id, (name: string, percentComplete: number) => {
          // setUploadProgress(progress);
          // this.setState({ uploadProgress: progress })
        });
      }

      return addImagePromise
        .then(planRtn => {
          setImageUpload(false);
          dispatch({ type: patientId ? "SET_PLAN" : "SET_PLAN_TEMPLATE", item: planRtn });
          toast.success("Image uploaded");
        })
        .catch(() => toast.error("Error uploading image."))
        .finally(() => {
          setIsUploading(false);
        });
    }
  };

  const handleImageToDelete = () => {
    if (imageToDelete) {
      let deleteSessionImagePromise: Promise<any> | undefined;
      if (patientId) {
        deleteSessionImagePromise = deleteSessionImage(plan._id, session._id, imageToDelete);
      } else {
        deleteSessionImagePromise = deleteSessionTemplateImage(plan._id, session._id, imageToDelete);
      }
      return deleteSessionImagePromise
        .then((updatedPlan: IPlan) => {
          dispatch({ type: patientId ? "SET_PLAN" : "SET_PLAN_TEMPLATE", item: updatedPlan });
          setImageToDelete(undefined);
          toast.success("Image deleted");
        })
        .catch(() => toast.error("Error deleting image"));
    } else {
      return Promise.resolve();
    }
  };

  const getDateFromId = (id: string) => new Date(new ObjectId(id).getTimestamp());

  const handleAddComment = () => {
    onAddComment(addComment);
    setAddComment("");
  };

  const handleAddCommentPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13) {
      handleAddComment();
    }
  };

  const renderComment = (comment: IComment) => {
    const author = contacts.get(comment.authorId)!;
    const date = getDateFromId(comment._id);

    return (
      <div key={comment._id} className="d-flex mb-2" style={{ fontSize: 13 }}>
        <div>
          <Avatar {...author} />{" "}
        </div>
        <div className="flex-grow-1" style={{ marginLeft: 7 }}>
          <div style={{ padding: 7, display: "inline-block", background: "#f5f5f5", borderRadius: 20 }}>
            <span className="text-primary" style={{ fontWeight: "bold" }}>
              {author.firstName[0]}. {author.lastName}
            </span>{" "}
            {comment.content}
          </div>
          <div title={format(date, "PP p")} style={{ marginLeft: 14 }}>
            {formatDistanceStrict(date, new Date())}
          </div>
        </div>
      </div>
    );
  };

  const saveRecording = () => {
    if (!recording || !recordingBlob) {
      return;
    }

    onAddVideo(recordingBlob, recording);

    setRecording(undefined);
    setRecordingBlob(undefined);
  };

  return (
    <article>
      {patientId ? (
        <BackLink role={role} patientId={patientId} />
      ) : (
        <Link className="btn btn-outline" style={{ marginBottom: ".5em", border: "1px solid #ddd" }} to={"/plantemplates"}>
          Back to plan templates
        </Link>
      )}
      <h2>Session: {session.title}</h2>
      <section>
        <div data-cy="session actions" data-toggle="dropdown" style={{ cursor: "pointer", float: "right", minWidth: "1rem", textAlign: "center" }}>
          <VerticalDotsIcon />
        </div>
        <div className="dropdown-menu dropdown-menu-right">
          {role === Role.patient && (
            <>
              <div
                className="dropdown-item"
                onClick={() => {
                  setRecording("patient");
                  setRecordingMode("camera");
                }}
              >
                <VideoIcon /> Record Video
              </div>
              <div
                className="dropdown-item"
                onClick={() => {
                  setRecording("patient");
                  setRecordingMode("file");
                }}
              >
                <UploadIcon /> Upload Video
              </div>
            </>
          )}
          {(role === Role.doctor || role === Role.organizationadmin) && (
            <>
              <div className="dropdown-item" onClick={onEditSession}>
                <EditIcon /> Edit Session
              </div>
              <div className="dropdown-divider"></div>
              <div
                className="dropdown-item"
                onClick={() => {
                  setRecording("example");
                  setRecordingMode("camera");
                }}
              >
                <ExerciseIcon /> Record Example
              </div>
              <div
                className="dropdown-item"
                onClick={() => {
                  setRecording("example");
                  setRecordingMode("file");
                }}
              >
                <UploadIcon /> Upload Example
              </div>
              <div
                className="dropdown-item"
                onClick={() => {
                  const args = { planId: plan._id, sessionId: session._id };
                  const event = new CustomEvent("showSelectExample", { detail: args });
                  window.dispatchEvent(event);
                }}
              >
                <VideoLibraryIcon /> Library Example
              </div>
            </>
          )}
          <div className="dropdown-item" onClick={() => setImageUpload(true)}>
            <ImageIcon /> Upload Image
          </div>
          <div
            className="dropdown-item"
            onClick={() => {
              const args = { planId: plan._id, sessionId: session._id };
              const event = new CustomEvent("showAddAttachment", { detail: args });
              window.dispatchEvent(event);
            }}
          >
            <AttachmentIcon /> Attach File
          </div>
        </div>
        <div className="info-items">
          {showStatus && (
            <div>
              <StatusIcon status={session.status} />
              <div>{session.status}</div>
            </div>
          )}
          {contact && (
            <div>
              <Avatar {...contact} circle={true} size={24} /> {contact.firstName[0]}. {contact.lastName}
            </div>
          )}
          <div>
            <CalendarIcon />
            <div>{formatObjectId(session._id)}</div>
          </div>
        </div>
        <div style={{ whiteSpace: "pre-wrap" }}>{description}</div>
      </section>
      {videoHtml}
      <SessionFilesCard
        attachements={session.attachments || []}
        images={session.images || []}
        onImageDelete={image => setImageToDelete(image._id)}
        planId={plan._id}
        sessionId={session._id}
      />
      {contact && (
        <section>
          {!!history.length && <div style={{ marginBottom: "1rem" }}>{history.map(x => renderComment(x))}</div>}
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <Avatar email={selfContact.email} firstName={selfContact.firstName} lastName={selfContact.lastName} />
              </span>
            </div>
            <textarea
              ref={addCommentRef}
              className="form-control bg-light"
              style={{ height: "inherit" }}
              placeholder="Write a comment..."
              value={addComment}
              onChange={e => setAddComment(e.target.value)}
              onKeyDown={e => handleAddCommentPress(e)}
            />
            <button className="btn btn-primary" onClick={() => handleAddComment()} disabled={addComment.trim() === ""}>
              Send
            </button>
          </div>
        </section>
      )}
      {(recording === "example" || recording === "patient" || isVideoPending) && recordingMode === "file" && (
        <UploadDeviceVideo
          canUpload={!!recordingBlob}
          isPending={!!isVideoPending}
          onCancel={() => {
            setRecording(undefined);
            setRecordingBlob(undefined);
            setRecordingMode(undefined);
          }}
          onRecordingComplete={handleRecordingComplete}
          onSaveRecording={() => saveRecording()}
          recordingMode={recordingMode || "camera"}
          uploadProgress={uploadProgress}
        />
      )}
      {(recording === "example" || recording === "patient" || isVideoPending) && recordingMode === "camera" && (
        <VideoRecorder
          canUpload={!!recordingBlob}
          isPending={!!isVideoPending}
          onCancel={() => {
            setRecording(undefined);
            setRecordingBlob(undefined);
            setRecordingMode(undefined);
          }}
          onRecordingComplete={handleRecordingComplete}
          onSaveRecording={() => saveRecording()}
          recordingMode={recordingMode || "camera"}
          uploadProgress={uploadProgress}
        />
      )}
      {(recording === "doctor" || isVideoPending) && currentVideo && (
        <AudioVideoRecording
          isVideoPending={isVideoPending}
          handleOnMediaAdd={blob => {
            onAddVideo(blob, recording!, currentVideo._id);
            setRecording(undefined);
            setRecordingMode(undefined);
          }}
          handleOnCancel={() => {
            setRecording(undefined);
            setRecordingMode(undefined);
            setCurrentVideo(undefined);
          }}
          uploadProgress={uploadProgress}
          video={currentVideo}
        />
      )}
      {currentVideo && addToLibrary && (
        <AddToVideoLibrary
          onCancel={() => {
            setCurrentVideo(undefined);
            setAddToLibrary(false);
          }}
          video={currentVideo!}
        />
      )}
      {deleteVideoModal && (
        <DeleteVideoModal
          videoId={currentVideo?._id!}
          onCancel={() => {
            setCurrentVideo(undefined);
            setDeleteVideoModal(false);
          }}
        />
      )}
      {imageUpload && <UploadImage isPending={isUploading} onCancel={() => setImageUpload(false)} onUpload={image => handleImageUpload(image)} uploadProgress={uploadProgress} />}
      {imageToDelete && (
        <ModalSlim>
          <ConfirmCard
            question="Delete this session image?"
            onCancel={() => setImageToDelete(undefined)}
            confirmLabel="Delete"
            confirmPendingLabel="Deleting"
            onSubmit={() => handleImageToDelete()}
          />
        </ModalSlim>
      )}
    </article>
  );
};

export default ViewSessionCard;
