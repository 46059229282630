import Lead from "../models/Lead";
import { request } from "./ApiService";

export const createLead = (item: Lead) => {
  try {
    const ga = (window as any).ga;
    if (ga) {
      ga("send", "event", "Lead", "submit", item.topic);
    }
  } finally {
    console.log("google analytics error");
  }
  return request(`leads/${item.topic}`, { method: "POST", body: item });
};
