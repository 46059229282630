import React, { FC, useState } from "react";
import { toast } from "react-toastify";

import IContact from "../../models/IContact";
import IPlan from "../../models/IPlan";
import ISession from "../../models/ISession";
import { formatObjectId } from "../../services/DateService";
import { CalendarIcon, CreateIcon, DeleteIcon, DocumentIcon, EditIcon, ExpandIcon, MailIcon, StarIcon, StatusIcon, VerticalDotsIcon } from "../core/Icons";
import LoadingBlock from "../core/LoadingBlock";
import ModifyPlanModal from "../plans/ModifyPlanModal";
import ModifySessionModal from "../sessions/ModifySessionModal";
import PatientPreview from "./PatientPreview";
import DeletePatientModal from "./DeletePatientModal";
import PatientAssessment from "../assessments/PatientAssessment";
import { sortPlans } from "../../utils/sort";
import SessionsDisplay from "./SessionsDisplay";
import PatientEducation from "../patientEducation/PatientEducation";
import useAuth from "../../hooks/useAuth";
import { Role } from "../../models/IAuth";
import ConfirmCard from "../core/ConfirmCard";
import ModalSlim from "../core/ModalSlim";
import { copyPlanToPlanTemplates } from "../../services/PlanTemplatesService";
import { useDispatch } from "react-redux";

type Props = {
  patient: IContact | null;
  plans: IPlan[] | null;
  onFeaturePlan: (plan: IPlan) => Promise<any>;
  onSavePlan: (item: IPlan, planTemplateId?: string) => Promise<any>;
  onSaveSession?: (plan: IPlan, session: ISession) => Promise<any>;
  onSendInvitation?: (item: string) => Promise<any>;
};

const PatientDetail: FC<Props> = ({ patient, plans, onFeaturePlan, onSavePlan, onSaveSession, onSendInvitation }) => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const [editPlan, setEditPlan] = useState<IPlan>();
  const [isSavingPlan, setIsSavingPlan] = useState(false);
  const [editSession, setEditSession] = useState<ISession & { plan: IPlan }>();
  const [isSavingSession, setIsSavingSession] = useState(false);
  const [deletePatientModal, setDeletePatientModal] = useState(false);
  const [planToCopy, setPlanToCopy] = useState<IPlan>();

  const savePlanToPlanTemplates = () => {
    let promise = Promise.resolve();
    if (planToCopy) {
      setIsSavingPlan(true);
      promise = copyPlanToPlanTemplates(planToCopy)
        .then(planTemplate => {
          dispatch({ type: "SET_PLAN_TEMPLATE", item: planTemplate });
          setPlanToCopy(undefined);
          toast.success("Plan saved to Plan Templates!");
        })
        .catch(err => {
          toast.error(`Error saving plan to plan template: ${err.message}`);
        })
        .finally(() => {
          setIsSavingPlan(false);
        });
    }

    return promise;
  };

  return (
    <main>
      <article>
        <h2>Patient</h2>
        <section>
          {patient ? (
            <>
              <div data-cy="patient actions" data-toggle="dropdown" style={{ cursor: "pointer", float: "right", minWidth: "1rem", textAlign: "center" }}>
                <VerticalDotsIcon />
              </div>
              <div className="dropdown-menu dropdown-menu-right">
                <div className="dropdown-item" onClick={() => setEditPlan({} as IPlan)}>
                  <CreateIcon /> Create Plan
                </div>
                {!patient.invitationClaimedTms && (
                  <>
                    <div className="dropdown-divider"></div>
                    <div className="dropdown-item" onClick={() => onSendInvitation && onSendInvitation(patient._id)}>
                      <MailIcon /> {patient.invitationTms ? "Re-send" : "Send"} Invitation
                    </div>
                  </>
                )}
                {auth && auth.role === Role.doctor && (
                  <>
                    <div className="dropdown-divider"></div>
                    <div className="dropdown-item" onClick={() => setDeletePatientModal(true)}>
                      <DeleteIcon style={{ color: "red" }} /> Delete Patient
                    </div>
                  </>
                )}
              </div>
              <PatientPreview contact={patient} />
            </>
          ) : (
            <LoadingBlock />
          )}
        </section>
      </article>
      {plans && plans.length > 0 && <PatientAssessment patientID={patient?._id!} doctorID={plans[0].doctorId} />}
      {plans ? (
        plans.sort(sortPlans).map(plan => (
          <article data-cy={`plan title ${plan.title}`} key={plan._id}>
            <h2 style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div>Plan: {plan.title}</div>
              <div>
                <button
                  data-cy="expand caseload"
                  className="btn btn-secondary"
                  style={{ marginLeft: "1rem" }}
                  type="button"
                  data-toggle="collapse"
                  data-target={`#collapsePlan-${plan._id}`}
                  aria-expanded="false"
                  aria-controls={`collapsePlan-${plan._id}`}
                >
                  <ExpandIcon />
                </button>
              </div>
            </h2>
            <div className={plan.isFeatured ? "collapse show" : "collapse"} id={`collapsePlan-${plan._id}`}>
              <section>
                <div data-cy="plan actions" data-toggle="dropdown" style={{ cursor: "pointer", float: "right", minWidth: "1rem", textAlign: "center" }}>
                  <VerticalDotsIcon />
                </div>
                <div style={{ cursor: "pointer", float: "right", paddingRight: "1rem" }} onClick={() => onFeaturePlan(plan)}>
                  <StarIcon style={plan.isFeatured ? { color: "#dbdb42" } : {}} />
                </div>
                <div className="dropdown-menu dropdown-menu-right">
                  <div className="dropdown-item" onClick={() => setEditPlan(plan)}>
                    <EditIcon /> Edit Plan
                  </div>
                  <div className="dropdown-divider"></div>
                  <div className="dropdown-item" onClick={() => setEditSession({ plan } as ISession & { plan: IPlan })}>
                    <CreateIcon /> Create Session
                  </div>
                  <div className="dropdown-item" onClick={() => setPlanToCopy(plan)}>
                    <DocumentIcon /> Copy to Template
                  </div>
                </div>
                <div className="info-items">
                  <div>
                    <StatusIcon status={plan.status} />
                    <div>{plan.status}</div>
                  </div>
                  <div>
                    <CalendarIcon />
                    <div>{formatObjectId(plan._id)}</div>
                  </div>
                </div>
                <div style={{ whiteSpace: "pre-wrap" }}>{plan.description}</div>
              </section>
              <section className="bg-secondary">
                <h3 className="text-primary">Sessions</h3>
                <SessionsDisplay plan={plan} handleEditSession={setEditSession} />
              </section>
            </div>
          </article>
        ))
      ) : (
        <LoadingBlock />
      )}
      {patient && plans && plans[0] && patient._id && <PatientEducation doctorID={plans[0].doctorId} patientID={patient._id} />}
      {editPlan && (
        <ModifyPlanModal
          current={editPlan}
          isPending={isSavingPlan}
          onCancel={() => setEditPlan(undefined)}
          onSubmit={x => {
            setIsSavingPlan(true);
            const planTemplateId = x._id;
            x._id = editPlan._id;

            onSavePlan({ ...editPlan, ...x, patientId: patient!._id }, planTemplateId ? planTemplateId : undefined)
              .then(() => setEditPlan(undefined))
              .catch(err => toast.error(err.message))
              .finally(() => setIsSavingPlan(false));
          }}
        />
      )}
      {editSession && patient && (
        <ModifySessionModal
          current={editSession}
          isPending={isSavingSession}
          patient={patient}
          planId={editSession.plan._id}
          onCancel={() => setEditSession(undefined)}
          onSendInvitation={onSendInvitation}
          onSubmit={x => {
            if (!onSaveSession) {
              return Promise.resolve();
            }

            setIsSavingSession(true);

            return onSaveSession(editSession.plan, { ...editSession, ...x })
              .then(newSession => {
                setEditSession(undefined);
                return newSession;
              })
              .catch(err => toast.error(err.message))
              .finally(() => setIsSavingSession(false));
          }}
        />
      )}
      {deletePatientModal && <DeletePatientModal patient={patient!} onCancel={() => setDeletePatientModal(false)} />}
      {planToCopy && (
        <ModalSlim>
          <ConfirmCard title="Add to Plan Template" question="Copy Plan to Plan Templates?" onCancel={() => setPlanToCopy(undefined)} onSubmit={savePlanToPlanTemplates} />
        </ModalSlim>
      )}
    </main>
  );
};

export default PatientDetail;
