import IVideo from "../models/IVideo";

type VideoLibraryAction = {
  collection?: IVideo[];
  item?: IVideo;
  type: "DELETE_VIDEO" | "SET_VIDEO_LIBRARY" | "SET_VIDEO_LIBRARY_VIDEO" | "RESET";
};

export type VideoLibraryState = {
  [key: string]: IVideo;
};

export const videoLibraryReducer = (state: VideoLibraryState | null = null, action: VideoLibraryAction) => {
  const { collection, item, type } = action;

  switch (type) {
    case "SET_VIDEO_LIBRARY_VIDEO": {
      return state == null ? state : { ...state, [item!._id]: item! };
    }
    case "SET_VIDEO_LIBRARY": {
      return collection!.reduce((prev, curr) => ({ ...prev, [curr._id]: curr }), {} as VideoLibraryState);
    }
    case "DELETE_VIDEO": {
      const newState = { ...state } as VideoLibraryState;
      if (item && item._id && newState && newState[item._id]) {
        delete newState[item._id];
      }
      return newState;
    }
    case "RESET": {
      return null;
    }
    default: {
      return state;
    }
  }
};
