type SettingsAction = {
  item: string;
  type: "SET_DEFAULT_VIDEO_ID" | "SET_DOCTOR_FILTER" | "SET_PATIENT_FILTER" | "SET_VIDEO_PERMISSIONS_CHECKED";
};

export const DEFAULT_VIDEO_DEVICE_ID = "telecare_default_video_id";

export type SettingsState = {
  checkedCameraPermissions: boolean;
  defaultVideoDeviceId?: string;
  doctorFilter?: string;
  patientFilter?: string;
};

export const settingsReducer = (state: SettingsState = { checkedCameraPermissions: false }, action: SettingsAction) => {
  const { item, type } = action;

  switch (type) {
    case "SET_DEFAULT_VIDEO_ID": {
      localStorage.setItem(DEFAULT_VIDEO_DEVICE_ID, item);
      return { ...state, defaultVideoDeviceId: item };
    }
    case "SET_VIDEO_PERMISSIONS_CHECKED": {
      return { ...state, checkedCameraPermissions: true };
    }
    case "SET_DOCTOR_FILTER": {
      return { ...state, doctorFilter: item };
    }
    case "SET_PATIENT_FILTER": {
      return { ...state, patientFilter: item };
    }
    default: {
      return state;
    }
  }
};
