import React, { FC } from "react";

import ActionButtons from "./ActionButtons";

type Props = {
  isPending?: boolean;
  isValid?: boolean;
  label?: string;
  labelPending?: string;
  validation?: string | null;
  onCancel?: () => void;
};

const CancelSaveButtons: FC<Props> = props => {
  return <ActionButtons {...props} submitLabel={props.label || "Save"} submitPendingLabel={props.labelPending || "Saving"} />;
};

export default CancelSaveButtons;
