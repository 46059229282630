const defaultPoses = [
  {
    _id: "0",
    title: "Dead lift",
    url: "/images/ideal/Deadlift.png",
  },
  {
    _id: "1",
    title: "Squat",
    url: "/images/ideal/Squat.png",
  },
  {
    _id: "2",
    title: "Front Lunge",
    url: "/images/ideal/FrontLunge.png",
  },
  {
    _id: "3",
    title: "Overhead Press Plus",
    url: "/images/ideal/OverheadPressPlus.png",
  },
  {
    _id: "4",
    title: "Front Rack",
    url: "/images/ideal/FrontRack.png",
  },
  {
    _id: "5",
    title: "Upright Row",
    url: "/images/ideal/UprightRow.png",
  },
  {
    _id: "6",
    title: "Relay Reach",
    url: "/images/ideal/RelayReach.png",
  },
  {
    _id: "7",
    title: "Horizontal",
    url: "/images/ideal/Horizontal.png",
  },
  {
    _id: "8",
    title: "Vertical",
    url: "/images/ideal/Vertical.png",
  },
  {
    _id: "9",
    title: "Front Facing Upright Row",
    url: "/images/ideal/FrontFacingUprightRow.png",
  },
  {
    _id: "10",
    title: "Front Facing Front Rack",
    url: "/images/ideal/FrontFacingFrontRack.png",
  },
  {
    _id: "11",
    title: "Front Facing Relay Reach",
    url: "/images/ideal/FrontFacingRelayReach.png",
  },
  {
    _id: "12",
    title: "Front Facing Overhead Press Plus",
    url: "/images/ideal/FrontFacingOverheadPressPlus.png",
  },
  {
    _id: "13",
    title: "Front Facing Squat",
    url: "/images/ideal/FrontFacingSquat.png",
  },
  {
    _id: "14",
    title: "Front Facing Deadlift",
    url: "/images/ideal/FrontFacingDeadlift.png",
  },
  {
    _id: "15",
    title: "Front Facing Lunge",
    url: "/images/ideal/FrontFacingLunge.png",
  },
];

export default defaultPoses;
