import React, { FC, useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import IAttachment from "../../models/IAttachment";
import { CalendarIcon, DeleteIcon, DocumentIcon, VerticalDotsIcon } from "../core/Icons";
import { formatObjectId } from "../../services/DateService";
import { Role } from "../../models/IAuth";

export type Props = {
  attachment: IAttachment;
  onAttachmentDelete: (attachment: IAttachment) => void;
};

const ViewFileCard: FC<Props> = ({ attachment, onAttachmentDelete }) => {
  const auth = useAuth()!;
  const [loaded, setLoaded] = useState(false);
  const [url, setUrl] = useState(`https://docs.google.com/gview?url=${encodeURIComponent(attachment.sas)}&embedded=true`);

  useEffect(() => {
    if (!loaded) {
      const timeoutId = setTimeout(() => {
        if (!loaded) {
          setUrl(url + "&timestamp=" + Date.now());
        }
      }, 600);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [loaded, url]);

  const handleIframeLoaded = () => {
    setLoaded(true);
  };

  const handleIframeError = () => {
    setUrl(url + "&" + Date.now() + "=now");
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <iframe
        key={`attachment-${attachment._id}`}
        loading="lazy"
        onError={handleIframeError}
        onLoad={handleIframeLoaded}
        title={attachment.name || `Attachment ${attachment._id}`}
        // src={"https://drive.google.com/viewerng/viewer?embedded=true&url=" + encodeURIComponent(attachment.sas)}
        src={url}
        style={{ height: "100%" }}
      ></iframe>

      <section className="border-without-top p-2" style={{ flex: "1 1 auto" }}>
        <div data-toggle="dropdown" style={{ cursor: "pointer", float: "right", minWidth: "1rem", textAlign: "center" }}>
          <VerticalDotsIcon />
        </div>
        <div className="dropdown-menu dropdown-menu-right">
          <a className="dropdown-item" href={attachment.sas} download={true}>
            <DocumentIcon /> Download
          </a>
          {(auth.role === Role.doctor || auth.role === Role.organizationadmin) && (
            <div className="dropdown-item" onClick={() => onAttachmentDelete(attachment)}>
              <DeleteIcon style={{ color: "red" }} /> Delete Attachment
            </div>
          )}
        </div>
        <div className="info-wrap">
          <div>
            <CalendarIcon /> {formatObjectId(attachment._id)}
          </div>
          <div>{attachment.name}</div>
        </div>
      </section>
    </div>
  );
};

export default ViewFileCard;
