import React, { FC } from "react";

import IAuth from "../../models/IAuth";
import IContact from "../../models/IContact";
import IPlan from "../../models/IPlan";
import LoadingBlock from "../core/LoadingBlock";
import SectionDisplay from "./SectionDisplay";
import PatientAssessment from "../assessments/PatientAssessment";
import PatientEducation from "../patientEducation/PatientEducation";
import { ExpandIcon } from "../core/Icons";
import { compareProps } from "../../utils/sort";
import { IAppState } from "../../store/Root";
import { useSelector } from "react-redux";

type Props = {
  auth: IAuth;
  plans: (IPlan & { doctor: IContact | null })[] | null;
};

const Dashboard: FC<Props> = ({ auth, plans }) => {
  const doctorFilter = (state: IAppState) => state.settings.doctorFilter;
  const doctorFilterId = useSelector(doctorFilter);
  const patientFilter = (state: IAppState) => state.settings.patientFilter;
  const patientFilterId = useSelector(patientFilter);

  if (!plans) {
    return (
      <div style={{ height: "15rem", maxWidth: "100%", width: "15rem" }}>
        <LoadingBlock />
      </div>
    );
  }

  return (
    <main>
      {plans.length === 0 ? (
        <section className="border">Welcome, {auth.firstName}. It looks like your doctor hasn&apos;t activated any sessions or plans.</section>
      ) : (
        <>
          <PatientAssessment patientID={patientFilterId || plans[0].patientId} doctorID={doctorFilterId || plans[0].doctorId} />
          {plans.map(plan => (
            <article key={plan._id}>
              <h2 style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div>{plan.title}</div>
                <div>
                  <button
                    data-cy="expand caseload"
                    className="btn btn-secondary"
                    style={{ marginLeft: "1rem" }}
                    type="button"
                    data-toggle="collapse"
                    data-target={`#collapsePlan-${plan._id}`}
                    aria-expanded="false"
                    aria-controls={`collapsePlan-${plan._id}`}
                  >
                    <ExpandIcon />
                  </button>
                </div>
              </h2>
              <div className={plan.isFeatured ? "collapse show" : "collapse"} id={`collapsePlan-${plan._id}`}>
                <SectionDisplay
                  doctor={plan.doctor}
                  planId={plan._id}
                  sectionLabel="Acute"
                  sessions={plan.sessions
                    .filter(session => session.focus === "baseline" || session.focus === "")
                    .sort((a, b) => (a.order !== 0 && b.order !== 0 ? compareProps(b, a, { order: -1 }) : compareProps(a, b, { _id: -1 })))}
                />
                <SectionDisplay
                  doctor={plan.doctor}
                  planId={plan._id}
                  sectionLabel="Intermediate"
                  sessions={plan.sessions
                    .filter(session => session.focus === "stability")
                    .sort((a, b) => (a.order !== 0 && b.order !== 0 ? compareProps(b, a, { order: -1 }) : compareProps(a, b, { _id: -1 })))}
                />
                <SectionDisplay
                  doctor={plan.doctor}
                  planId={plan._id}
                  sectionLabel="Advanced"
                  sessions={plan.sessions
                    .filter(session => session.focus === "advanced")
                    .sort((a, b) => (a.order !== 0 && b.order !== 0 ? compareProps(b, a, { order: -1 }) : compareProps(a, b, { _id: -1 })))}
                />
              </div>
            </article>
          ))}
          <PatientEducation patientID={patientFilterId || plans[0].patientId} doctorID={doctorFilterId || plans[0].doctorId} />
        </>
      )}
    </main>
  );
};

export default Dashboard;
