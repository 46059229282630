import React, { FC } from "react";
import Spinner from "../core/Spinner";

export type Props = {
  isPending: boolean;
  onCancel: () => void;
  onSubmit: () => void;
};

const DeleteVideoCard: FC<Props> = ({ isPending, onCancel, onSubmit }) => {
  return (
    <article data-cy="video card">
      <h2>Delete Video?</h2>
      <form
        autoComplete="off"
        className="material"
        onSubmit={e => {
          e.preventDefault();

          onSubmit();
        }}
      >
        <section>
          <div className="d-flex justify-content-between">
            <div>
              <button type="button" className="action btn" disabled={isPending} onClick={() => onCancel()}>
                Cancel
              </button>
              <button title="Delete Video" type="submit" className="action btn btn-danger" disabled={isPending}>
                {isPending && <Spinner />} {isPending ? "Deleting" : "Delete"}
              </button>
            </div>
          </div>
        </section>
      </form>
    </article>
  );
};

export default DeleteVideoCard;
