import INotification from "../models/INotification";

type NotificationsAction = {
  collection: INotification[];
  item?: INotification;
  type: "REMOVE_NOTIFICATION" | "SET_NOTIFICATION" | "SET_NOTIFICATIONS" | "RESET";
};

export type NotificationsState = {
  notifications: INotification[];
  loaded: boolean;
};

export const notificationsReducer = (state: NotificationsState | null = null, action: NotificationsAction) => {
  const { collection, item, type } = action;

  switch (type) {
    case "REMOVE_NOTIFICATION": {
      const newCollection: INotification[] = [];
      if (state) {
        state.notifications.forEach(notification => {
          if (notification._id !== item?._id) {
            newCollection.push({ ...notification });
          }
        });
      }

      return { notifications: newCollection, loaded: true };
    }
    case "SET_NOTIFICATION": {
      const newCollection: INotification[] = [];
      if (state) {
        state.notifications.forEach(notification => {
          newCollection.push(notification._id === item?._id ? { ...item } : notification);
        });
      } else {
        newCollection.push(item!);
      }

      return { notifications: newCollection, loaded: true };
    }
    case "SET_NOTIFICATIONS": {
      return { notifications: collection, loaded: true };
    }
    case "RESET": {
      return null;
    }
    default: {
      return state;
    }
  }
};
