import classnames from "classnames";
import gravatar from "gravatar";
import React, { CSSProperties, FC } from "react";

type Props = {
  className?: string;
  circle?: boolean;
  email?: string;
  firstName?: string;
  lastName?: string;
  rounded?: boolean;
  size?: number;
  style?: CSSProperties;
};

const getInitialsFallback = ({ firstName, lastName, size }: Props) => {
  const name = [firstName, lastName].filter(x => x).join("+") || "-";

  return ["https://ui-avatars.com/api", name, size].filter(x => x).join("/");
};

const getGravatarIcon = (props: Props) => {
  const d = encodeURI(getInitialsFallback(props));

  return gravatar.url(props.email || "", { default: d, size: props.size ? props.size.toString() : "" });
};

const Avatar: FC<Props> = props => {
  const { className, firstName, lastName, style } = props;
  const fullName = [firstName, lastName].filter(x => x).join(" ");

  return (
    <img
      src={getGravatarIcon(props)}
      alt="user-icon"
      title={fullName}
      className={classnames("border border-gray", props.circle && "circle", !props.circle && props.rounded && "rounded", className)}
      style={style}
    />
  );
};

Avatar.defaultProps = {
  email: "unknown@unknown",
  rounded: true,
  size: 32,
};

export default Avatar;
