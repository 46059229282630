import classNames from "classnames";
import React, { CSSProperties, FC } from "react";

import { getPencil } from "../../memo/blobs";
import noop from "../../utils/noop";
import { LineIcon, PointerIcon, WrenchIcon } from "../core/Icons";
import defaultPoses from "../assessments/DefaultPoses";

export type Mode = "ideal" | "line" | "pencil" | "select";

const colorWheel = ["white", "black", "#1894d5", "red", "green"];

type Props = {
  canUndo: boolean;
  color?: string;
  enableIdeal: boolean;
  idealPoseImageSelected: string | undefined;
  mode?: Mode;
  size?: number;
  style?: CSSProperties;
  onAddIdealPose?: () => void;
  onClear?: () => void;
  onColorChange?: (x: string) => void;
  onFlipIdealPoseChange?: () => void;
  onModeChange?: (x: Mode) => void;
  onSizeChange?: (x: number) => void;
  onSelectedIdealPoseIdChange?: (x: string) => void;
  onUndo?: () => void;
};

const AnnotationsMenu: FC<Props> = ({
  canUndo = false,
  color = "white",
  enableIdeal = false,
  idealPoseImageSelected,
  mode = "select",
  style,
  onAddIdealPose = noop,
  onClear = noop,
  onColorChange = noop,
  onFlipIdealPoseChange = noop,
  onModeChange = noop,
  onSelectedIdealPoseIdChange = noop,
  onUndo = noop,
}) => {
  const pencilUrl = getPencil(color);
  let triggerIcon = null;
  switch (mode) {
    case "ideal":
      triggerIcon = <WrenchIcon style={{ marginLeft: "0.4rem" }} />;
      break;
    case "line":
      triggerIcon = <LineIcon style={{ marginLeft: "auto", color }} className="fa-lg" />;
      break;
    case "pencil":
      triggerIcon = <img src={pencilUrl} alt="annotations menu" />;
      break;
    case "select":
      triggerIcon = <img src="/images/mouse-pointer.svg" alt="annotations menu" />;
      break;
  }

  return (
    <div className="annotations-menu dropdown" style={style}>
      <div className="trigger" data-toggle="dropdown">
        {triggerIcon}
      </div>
      <div className="dropdown-menu dropdown-menu-right" style={{ padding: 0 }}>
        <form className="dropdown-container" onSubmit={e => e.preventDefault()}>
          <div>
            <div>Mode</div>
            <div className="mode-grid">
              <button title="select" className={classNames("btn", mode === "select" && "active")} onClick={() => onModeChange("select")}>
                <PointerIcon />
              </button>
              <button title="pencil" className={classNames("btn", mode === "pencil" && "active")} onClick={() => onModeChange("pencil")}>
                <img src={pencilUrl} alt="pencil" />
              </button>
              <button title="line" className={classNames("btn", mode === "line" && "active")} onClick={() => onModeChange("line")}>
                <LineIcon style={{ color }} />
              </button>
              {enableIdeal && (
                <button title="ideal" className={classNames("btn", mode === "ideal" && "active")} onClick={() => onModeChange("ideal")}>
                  <WrenchIcon />
                </button>
              )}
            </div>
          </div>
          {mode === "ideal" && (
            <div>
              <select onChange={e => onSelectedIdealPoseIdChange(e.target.value)}>
                {defaultPoses
                  .sort((x, y) => x.title.localeCompare(y.title))
                  .map(pose => (
                    <option key={pose._id} value={pose._id}>
                      {pose.title}
                    </option>
                  ))}
              </select>
              <button className="btn btn-secondary" onClick={onFlipIdealPoseChange} disabled={idealPoseImageSelected === undefined}>
                Flip
              </button>
              <button className="btn btn-secondary" onClick={onAddIdealPose}>
                Add
              </button>
            </div>
          )}
          <div>
            <div>Color</div>
            <div style={{ display: "flex" }}>
              {colorWheel.map(x => (
                <i key={x} className="color-square" style={{ "--color": x, cursor: "pointer" } as any} onClick={() => onColorChange(x)} />
              ))}
            </div>
          </div>
          <div>
            <div>Hotkeys</div>
            <table className="table table-sm table-striped" style={{ color: "gray", fontSize: ".7rem" }}>
              <tbody>
                <tr>
                  <td>Start/stop recording</td>
                  <td>Enter</td>
                </tr>
                <tr>
                  <td>Play/pause video</td>
                  <td>Spacebar</td>
                </tr>
                <tr>
                  <td>Slow/regular speed</td>
                  <td>s</td>
                </tr>
                <tr>
                  <td>Show controls</td>
                  <td>Hold shift</td>
                </tr>
                <tr>
                  <td>Select</td>
                  <td>Escape</td>
                </tr>
                <tr>
                  <td>Pencil</td>
                  <td>p</td>
                </tr>
                <tr>
                  <td>Undo</td>
                  <td>{navigator.appVersion.toUpperCase().indexOf("MAC") >= 0 ? "Cmd" : "Ctrl"}+z</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <div>Actions</div>
            <div>
              <button className="btn btn-outline-remove" onClick={onClear}>
                Clear
              </button>
              <button className="btn" onClick={onUndo} disabled={!canUndo}>
                Undo
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AnnotationsMenu;
