import classNames from "classnames";
import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import IContact from "../../models/IContact";
import IPlan from "../../models/IPlan";
import ISession from "../../models/ISession";
import PatientAccount from "../../models/PatientAccount";
import { compareProps, compareStrings } from "../../utils/sort";
import ModifyPatientModal from "../caseload/ModifyPatientModal";
import PatientPreview from "../caseload/PatientPreview";
import { CreateIcon, ExpandIcon } from "../core/Icons";
import LoadingBlock from "../core/LoadingBlock";
import SessionPreview from "../sessions/SessionPreview";

type Props = {
  caseload: IContact[] | null;
  needsReview: (ISession & { plan: IPlan; patient: IContact | null })[] | null;
  onSavePatient?: (item: PatientAccount) => Promise<any>;
};

const Dashboard: FC<Props> = ({ caseload, needsReview, onSavePatient }) => {
  const [editPatient, setEditPatient] = useState<PatientAccount>();
  const [isSavingPatient, setIsSavingPatient] = useState(false);

  const hasPendingReviews = needsReview && needsReview.length > 0;

  return (
    <main>
      <article data-cy="needs review section">
        <h2 style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          Needs Review
          {hasPendingReviews && (
            <button
              data-cy="expand needs review"
              className="btn btn-secondary"
              type="button"
              data-toggle="collapse"
              data-target="#collapseReview"
              aria-expanded="false"
              aria-controls="collapseReview"
            >
              <ExpandIcon />
            </button>
          )}
        </h2>
        <div className={classNames("collapse", !hasPendingReviews && "show")} id="collapseReview">
          <section className="grid">
            {needsReview ? (
              needsReview.length === 0 ? (
                <section className="border">Good job! You have reviewed all submitted videos.</section>
              ) : (
                needsReview
                  .sort((a, b) => compareStrings(b._id, a._id))
                  .map(x => (
                    <Link key={x._id} to={`/plans/${x.plan._id}/sessions/${x._id}`}>
                      <SessionPreview contact={x.patient} session={x} />
                    </Link>
                  ))
              )
            ) : (
              <div style={{ height: "15rem", maxWidth: "100%", width: "15rem" }}>
                <LoadingBlock />
              </div>
            )}
          </section>
        </div>
      </article>
      <article>
        <h2 style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}>
          <div>Caseload</div>
          <div>
            <button className="btn btn-outline" onClick={() => setEditPatient({} as PatientAccount)}>
              <CreateIcon /> Add Patient
            </button>
            <button
              data-cy="expand caseload"
              className="btn btn-secondary"
              style={{ marginLeft: "1rem" }}
              type="button"
              data-toggle="collapse"
              data-target="#collapseCaseload"
              aria-expanded="false"
              aria-controls="collapseCaseload"
            >
              <ExpandIcon />
            </button>
          </div>
        </h2>
        <div className="collapse" id="collapseCaseload">
          <section className="grid">
            {caseload ? (
              caseload
                .sort((a, b) => compareProps(a, b, { lastName: 1, firstName: 1, _id: 1 }))
                .map(x => (
                  <Link key={x._id} to={`/caseload/${x._id}`}>
                    <PatientPreview style={{ border: "1px solid #ddd", padding: ".5rem" }} contact={x} />
                  </Link>
                ))
            ) : (
              <div style={{ height: "15rem", width: "100%" }}>
                <LoadingBlock />
              </div>
            )}
          </section>
        </div>
      </article>
      {editPatient && (
        <ModifyPatientModal
          current={editPatient}
          isPending={isSavingPatient}
          onCancel={() => setEditPatient(undefined)}
          onSubmit={x => {
            if (!onSavePatient) {
              return;
            }

            setIsSavingPatient(true);

            onSavePatient({ ...editPatient, ...x })
              .then(() => setEditPatient(undefined))
              .catch(err => toast.error(err.message))
              .finally(() => setIsSavingPatient(false));
          }}
        />
      )}
    </main>
  );
};

export default Dashboard;
