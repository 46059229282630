import React from "react";
import { useDispatch } from "react-redux";

import { useContacts, usePlans } from "../../hooks/useCollection";
import IContact from "../../models/IContact";

const DoctorSelect = () => {
  const dispatch = useDispatch();

  const plans = usePlans();
  const contacts = useContacts();

  const setDoctorFilter = (doctorId: string) => {
    dispatch({ type: "SET_DOCTOR_FILTER", item: doctorId });
    dispatch({ type: "SET_PATIENT_FILTER", item: Object.values(plans || {}).find(plan => plan.doctorId === doctorId)?.patientId });
  };

  let contactsWithPlans: IContact[] = [];
  if (contacts && plans) {
    const allPlans = Object.values(plans);
    contactsWithPlans = Object.values(contacts).filter(contact => allPlans.some(plan => plan.doctorId === contact._id));
    if (contactsWithPlans.length > 1) {
      setDoctorFilter(contactsWithPlans[0]._id);
    }
  }

  return contactsWithPlans.length > 1 ? (
    <div>
      <select onChange={e => setDoctorFilter(e.target.value)}>
        {contactsWithPlans.map(contact => (
          <option key={contact._id} value={contact._id}>{`${contact.firstName} ${contact.lastName}`}</option>
        ))}
      </select>
    </div>
  ) : (
    <></>
  );
};

export default DoctorSelect;
