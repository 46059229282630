import { useEffect, useState } from "react";

const useDebounce = (delay = 1) => {
  const [, forceUpdate] = useState(false);

  useEffect(() => {
    setTimeout(() => forceUpdate(true), delay);
  }, [delay]);
};

export default useDebounce;
