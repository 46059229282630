import React, { FC, useState } from "react";

import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

import useAuth from "../../hooks/useAuth";
import { useContacts, usePlans } from "../../hooks/useCollection";
import IPlan from "../../models/IPlan";
import ISession from "../../models/ISession";
import { removeAttachment } from "../../services/AttachmentsService";
import { sendInvitation } from "../../services/InvitationsService";
import { addComment, addSession, addVideo, saveSession } from "../../services/PlansService";
import SessionDetail from "./SessionDetail";
import { Role } from "../../models/IAuth";

const SessionDetailPageContent: FC = () => {
  const auth = useAuth()!;
  const contacts = useContacts();
  const plans = usePlans();

  const { planId, sessionId } = useParams<{ planId: string; sessionId: string }>();

  const [isVideoPending, setIsVideoPending] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const dispatch = useDispatch();

  if (!contacts) {
    return null;
  }

  if (!plans) {
    return null;
  }

  if (!planId || !sessionId) {
    return null;
  }

  const plan = plans[planId];
  const session = plan.sessions.find(x => x._id === sessionId);

  if (!session) {
    return null;
  }

  return (
    <SessionDetail
      contacts={new Map(Object.entries(contacts))}
      doctorId={plan.doctorId}
      isVideoPending={isVideoPending}
      patientId={plan.patientId}
      role={auth.role}
      plan={plan}
      session={session}
      onAddComment={x => {
        addComment(plan._id, session._id, x)
          .then(comment => {
            toast.success("Successfully added comment!");
            session.comments.push(comment);

            dispatch({ type: "SET_PLAN", item: plan });
          })
          .catch(err => toast.error(err.message));
      }}
      onAddVideo={(x: Blob, recordingType: "example" | "doctor" | "patient", patientVideoId?: string) => {
        setIsVideoPending(true);

        addVideo(plan._id, session._id, x, recordingType, patientVideoId, (name, percentComplete) => {
          setUploadProgress(percentComplete);
        })
          .then(video => {
            if (auth.role === Role.patient) {
              session.videos.push({ ...video, videoType: "patient" });
            } else if (recordingType === "example") {
              session.videos.push({ ...video, videoType: "example" });
            } else {
              session.videos.push({ ...video, videoType: "doctor", patientVideoId });
            }

            dispatch({ type: "SET_PLAN", item: plan });
          })
          .catch(err => toast.error(err.message))
          .finally(() => setIsVideoPending(false));
      }}
      onRemoveAttachment={id => removeAttachment(plan._id, session._id, id)}
      onSaveSession={(session: ISession) => {
        const promise = (session._id ? saveSession : addSession)(plan._id, session);

        promise.then(res => {
          let data: IPlan;

          if (session._id) {
            data = { ...plan, sessions: plan.sessions.map(x => (x._id === session._id ? { ...res } : x)) };
          } else {
            data = { ...plan, sessions: [...plan.sessions, res] };
          }

          dispatch({ type: "SET_PLAN", item: data });
        });

        return promise;
      }}
      onSendInvitation={sendInvitation}
      uploadProgress={uploadProgress}
    />
  );
};

const SessionDetailPage: FC = () => {
  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <SessionDetailPageContent />
    </MsalAuthenticationTemplate>
  );
};

export default SessionDetailPage;
