import React, { FC, useRef, useState } from "react";

import Spinner from "../core/Spinner";

type Props = {
  isPending: boolean;
  onCancel: () => void;
  onUpload: (value: Blob) => void;
  uploadProgress?: number;
};

const UploadImage: FC<Props> = ({ isPending, onCancel, onUpload, uploadProgress }) => {
  const [selectedFile, setSelectedFile] = useState<Blob>();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const inputField = useRef<HTMLInputElement>(null);

  const changeHandler = (event: any) => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      setIsFilePicked(true);
    } else {
      setIsFilePicked(false);
    }
  };

  return (
    <div style={{ position: "fixed", top: 0, left: 0, height: "100%", width: "100%", zIndex: 999 }}>
      <div style={{ backgroundColor: "#000", height: "100%", width: "100%" }}>
        {inputField.current !== null && inputField.current.files && inputField.current.files.length > 0 && (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "2rem" }}>
            <img style={{ maxWidth: "100%", maxHeight: "100%" }} src={URL.createObjectURL(inputField.current.files[0])} alt="" />
          </div>
        )}
        <div
          style={{ display: "flex", position: "absolute", bottom: 0, left: 0, right: 0, justifyContent: "center", paddingTop: "20px", marginBottom: "80px", alignItems: "center" }}
        >
          <input type="file" ref={inputField} id="upload-image-btn" style={{ display: "none" }} accept="image/*" onChange={changeHandler} />
          <label
            htmlFor="upload-image-btn"
            color="#000"
            style={{
              backgroundColor: "white",
              color: "#000",
              borderRadius: "4px",
              height: "40px",
              padding: "9px 18px",
              border: "none",
              margin: "-8px",
              fontSize: "14px",
              fontWeight: "bold",
              outline: "none",
            }}
          >
            Select an image
          </label>
        </div>
      </div>
      <div style={{ top: 0, left: 0, padding: "1rem", position: "absolute" }}>
        <div style={{ background: "white", padding: ".25rem", borderRadius: ".5rem" }}>
          <button type="button" className="action btn text-danger" disabled={isPending} onClick={onCancel}>
            Cancel
          </button>
          <button data-cy="video upload button" type="button" className="action btn btn-primary" disabled={!isFilePicked || isPending} onClick={() => onUpload(selectedFile!)}>
            {isPending && <Spinner />} {isPending ? `Uploading${uploadProgress ? ` ${uploadProgress}%` : ""}` : "Upload"}
          </button>
        </div>
      </div>
    </div>
  );
};
export default UploadImage;
