import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { useVideoLibrary } from "../../hooks/useCollection";
import { addVideoToVideoLibrary, deleteVideo, updateVideo } from "../../services/VideoLibraryService";
import { compareStrings } from "../../utils/sort";
import IVideo from "../../models/IVideo";
import VideoLibrary from "./VideoLibrary";

const VideoLibraryPageContent: FC = () => {
  const [editVideo, setEditVideo] = useState<IVideo>();
  const [deletingVideo, setDeleteVideo] = useState<IVideo>();
  const [isSavingVideo, setIsSavingVideo] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const videoLibrary = useVideoLibrary();
  const videoLibraryList = videoLibrary && Object.values(videoLibrary).sort((x, y) => compareStrings(x.name, y.name));
  const dispatch = useDispatch();

  const handleDelete = () => {
    if (deletingVideo !== undefined) {
      setIsSavingVideo(true);
      deleteVideo(deletingVideo._id)
        .then(() => {
          dispatch({ type: "DELETE_VIDEO", item: { _id: deletingVideo._id } });
          setDeleteVideo(undefined);
        })
        .catch(err => toast.error(err.message))
        .finally(() => setIsSavingVideo(false));
    }
  };

  const handleSave = (file: File, name: string) => {
    setIsSavingVideo(true);

    if (editVideo && editVideo._id) {
      updateVideo(editVideo._id, name)
        .then(item => {
          dispatch({ type: "SET_VIDEO_LIBRARY_VIDEO", item });

          setEditVideo(undefined);
        })
        .catch(err => toast.error(err.message))
        .finally(() => setIsSavingVideo(false));
    } else {
      addVideoToVideoLibrary(file, name, (name: string, percentComplete: number) => {
        setUploadProgress(percentComplete);
      })
        .then(item => {
          dispatch({ type: "SET_VIDEO_LIBRARY_VIDEO", item });
          setEditVideo(undefined);
        })
        .catch(err => toast.error(err.message))
        .finally(() => setIsSavingVideo(false));
    }
  };

  return (
    <VideoLibrary
      deletingVideo={deletingVideo}
      editVideo={editVideo}
      isSavingVideo={isSavingVideo}
      videoLibraryList={videoLibraryList}
      setDeleteVideo={setDeleteVideo}
      setEditVideo={setEditVideo}
      onDelete={handleDelete}
      onSave={handleSave}
      uploadProgress={uploadProgress}
    />
  );
};

const VideoLibraryPage: FC = () => {
  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <VideoLibraryPageContent />
    </MsalAuthenticationTemplate>
  );
};

export default VideoLibraryPage;
