import { toast } from "react-toastify";

import store, { dispatch } from "../store/Store";
import { saveVideo } from "./PlansService";
import { saveVideo as saveTemplateVideo } from "./PlanTemplatesService";
import { refreshVideos } from "./VideoService";

export async function saveExample(planId: string, sessionId: string, videoId: string, isVideoLibrary = true, isPlanTemplate = false) {
  try {
    const promise = isPlanTemplate ? saveTemplateVideo(planId, sessionId, videoId, "example", isVideoLibrary) : saveVideo(planId, sessionId, videoId, "example", isVideoLibrary);
    const video = await promise;
    const state = store.getState();
    const plan = isPlanTemplate ? state.planTemplates![planId] : state.plans![planId];
    const session = plan.sessions.find(x => x._id === sessionId)!;
    session.videos.push({ ...video, videoType: "example" });
    dispatch({ type: isPlanTemplate ? "SET_PLAN_TEMPLATE" : "SET_PLAN", item: plan });
    refreshVideos();
  } catch (err) {
    toast.error(err.message);
  }
}
