import React, { FC, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import useCustomEvent from "../../hooks/useCustomEvent";
import IAuth, { Role } from "../../models/IAuth";
import INotification from "../../models/INotification";
import Avatar from "../avatar/Avatar";
import { BellIcon, DeleteIcon, ExerciseIcon, LogoutIcon, ProfileIcon, SearchIcon, SupportIcon, VerticalDotsIcon, VideoLibraryIcon } from "../core/Icons";
import Lead from "../../models/Lead";
import { createLead } from "../../services/LeadsService";
import LeadModal from "../leads/LeadModal";
import { useNotifications } from "../../hooks/useCollection";
import { deleteNotification, toggleNotificationRead } from "../../services/NotificationsService";
import { formatObjectId } from "../../services/DateService";
import DoctorSelect from "./DoctorSelect";

type Topic = Lead["topic"];

const leadMessages = new Map<Topic, string>([
  ["contact", "Request submitted!"],
  ["demo", "Request submitted!"],
  ["guide", "Request submitted, check your email for the guide!"],
]);

type Props = {
  auth: IAuth | null;
};

const NavBar: FC<Props> = ({ auth }) => {
  const { role } = auth || { role: null };
  const [leadTopic, setLeadTopic] = useState<Topic>();
  const notificationCloseBtn = useRef<HTMLButtonElement>(null);

  const notifications = useNotifications();
  const history = useHistory();
  const dispatch = useDispatch();
  const showSearch = useCustomEvent("showSearch");
  useCustomEvent("showRequestGuide", () => setLeadTopic("guide"));
  useCustomEvent("showScheduleDemo", () => setLeadTopic("demo"));

  const unreadNotifications = notifications != null ? notifications.filter((n: INotification) => !n.read).length : 0;

  const handleNotificationClick = (notification: INotification) => {
    if (!notification.read) {
      toggleNotificationRead(notification._id).then(notification => {
        dispatch({ type: "SET_NOTIFICATION", item: notification });
      });
    }
    if (notification.url && notification.url !== "") {
      history.push(notification.url);
    }
    notificationCloseBtn.current?.click();
  };

  const handleNotificationDelete = (notification: INotification) => {
    deleteNotification(notification._id)
      .then(() => {
        dispatch({ type: "REMOVE_NOTIFICATION", item: notification });
        toast.success("Notification deleted");
      })
      .catch(() => {
        toast.error("The server had an unknown error. Please try again sometime later.");
      });
  };

  return (
    <>
      <div className="modal fade" id="notifications" tabIndex={-1} role="dialog" aria-labelledby="notifications" aria-hidden="true">
        <div className="modal-dialog modal-dialog-slideout" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Notifications
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="nt-container">
                {notifications != null &&
                  notifications.map((n: INotification) => {
                    return (
                      <div className="nt-note" key={n._id} style={{ backgroundColor: n.read ? "white" : "#d9e7fc" }}>
                        <div onClick={() => handleNotificationClick(n)}>
                          {n.message}
                          <div>{formatObjectId(n._id)}</div>
                        </div>
                        <div data-toggle="dropdown" style={{ cursor: "pointer", float: "right", minWidth: "1rem", textAlign: "center" }}>
                          <VerticalDotsIcon />
                        </div>
                        <div className="dropdown-menu dropdown-menu-right">
                          <div className="dropdown-item" onClick={() => handleNotificationDelete(n)}>
                            <DeleteIcon style={{ color: "red" }} /> Delete Notification
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" ref={notificationCloseBtn}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <nav className="navbar sticky-top navbar-expand-md navbar-dark bg-dark" style={{ zIndex: 999 }}>
        <div className="container">
          <div style={{ display: "flex", justifyContent: "space-between", position: "relative", width: "100%" }}>
            <Link className="navbar-brand" to="/">
              <img src="/images/logo.svg" width="30" height="30" className="d-inline-block align-top" alt="TeleCare Rx logo" />
              <span style={{ marginLeft: "0.5rem" }}>TeleCare Rx</span>
            </Link>

            <div data-cy="menu" style={{ display: "flex", alignItems: "center" }}>
              {auth && (
                <>
                  {(role === Role.doctor || role === Role.organizationadmin) && (
                    <>
                      <a href="https://blog.telecarerx.com/" className="btn mr-1" style={{ color: "white" }}>
                        Blog
                      </a>
                      <div data-cy="search" className="mr-4 text-white cursor-pointer" style={{ fontSize: "1.25rem" }} onClick={() => showSearch()}>
                        <SearchIcon />
                      </div>
                    </>
                  )}
                  {auth.role === Role.patient && <DoctorSelect />}
                  <div className="mr-4 text-white cursor-pointer" style={{ position: "relative" }} data-toggle="modal" data-target="#notifications">
                    <span
                      style={{
                        top: "-2px",
                        left: "14px",
                        backgroundColor: "#d11124",
                        position: "absolute",
                        height: "1.6rem",
                        borderRadius: "0.8rem",
                        padding: "0 0.4rem",
                        display: unreadNotifications !== 0 ? "block" : "none",
                      }}
                    >
                      <span style={{}}>{unreadNotifications}</span>
                    </span>
                    <div style={{ fontSize: "1.25rem" }}>
                      <BellIcon />
                    </div>
                  </div>
                  <div data-cy="avatar" className="cursor-pointer" data-toggle="dropdown">
                    <Avatar {...auth} circle={true} size={36} />
                  </div>
                  <div className="dropdown-menu dropdown-menu-right">
                    <Link to="/profile" className="dropdown-item">
                      <ProfileIcon /> Profile
                    </Link>
                    {(role === Role.doctor || role === Role.organizationadmin) && (
                      <>
                        <div className="dropdown-divider"></div>
                        <Link to="/videolibrary" className="dropdown-item">
                          <VideoLibraryIcon /> Video Library
                        </Link>
                        <div className="dropdown-divider"></div>
                        <Link to="/plantemplates" className="dropdown-item">
                          <ExerciseIcon /> Plan Templates
                        </Link>
                      </>
                    )}
                    <div className="dropdown-divider"></div>
                    <Link to="/support" className="dropdown-item">
                      <SupportIcon /> Support
                    </Link>
                    <div className="dropdown-divider"></div>
                    <Link to="/logout" className="dropdown-item">
                      <LogoutIcon /> Sign Out
                    </Link>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </nav>
      {leadTopic && (
        <LeadModal
          topic={leadTopic}
          onCancel={() => setLeadTopic(undefined)}
          onSubmit={item => {
            const message = leadMessages.get(leadTopic);

            return createLead(item)
              .then(() => toast.success(message))
              .catch(() => toast.error("The server had an unknown error. Please try again sometime later."))
              .finally(() => setLeadTopic(undefined));
          }}
        />
      )}
    </>
  );
};

export default NavBar;
