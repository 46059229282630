import React, { FC, useEffect, useState } from "react";

import { DragDropContext, DropResult, DraggableLocation } from "react-beautiful-dnd";

import IPlan from "../../models/IPlan";
import ISession from "../../models/ISession";
import { compareProps } from "../../utils/sort";
import { CreateIcon } from "../core/Icons";
import { saveSession } from "../../services/PlansService";
import DroppableSection from "./DroppableSection";

export type EditSession = ISession & {
  plan: IPlan;
};

export type Props = {
  handleEditSession: (session: EditSession) => void;
  plan: IPlan;
};

const SessionsDisplay: FC<Props> = ({ handleEditSession, plan }) => {
  const [baseline, setBaseline] = useState<Array<ISession>>([]);
  const [stability, setStability] = useState<Array<ISession>>([]);
  const [advanced, setAdvanced] = useState<Array<ISession>>([]);

  useEffect(() => {
    const beg: Array<ISession> = [],
      int: Array<ISession> = [],
      adv: Array<ISession> = [];
    plan.sessions.forEach(session => {
      switch (session.focus) {
        case "stability":
          int.push(session);
          break;
        case "advanced":
          adv.push(session);
          break;
        default:
          beg.push(session);
          break;
      }

      setBaseline(order(beg));
      setStability(order(int));
      setAdvanced(order(adv));
    });
  }, [plan.sessions]);

  const order = (sessions: Array<ISession>) => {
    return sessions.sort((a, b) => (a.order !== 0 && b.order !== 0 ? compareProps(b, a, { order: -1 }) : compareProps(a, b, { _id: -1 })));
  };

  const reorder = (list: Array<ISession>, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const move = (source: Array<ISession>, destination: Array<ISession>, droppableSource: DraggableLocation, droppableDestination: DraggableLocation) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = [];
    result.push(sourceClone);
    result.push(destClone);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }

    let sourceArray, destinationArray: Array<ISession>;
    let sourceSetFunc, destinationSetFunc: (session: Array<ISession>) => void;
    if (source.droppableId === "baseline") {
      sourceArray = baseline;
      sourceSetFunc = setBaseline;
    } else if (source.droppableId === "stability") {
      sourceArray = stability;
      sourceSetFunc = setStability;
    } else {
      sourceArray = advanced;
      sourceSetFunc = setAdvanced;
    }

    if (destination.droppableId === "baseline") {
      destinationArray = baseline;
      destinationSetFunc = setBaseline;
    } else if (destination.droppableId === "stability") {
      destinationArray = stability;
      destinationSetFunc = setStability;
    } else {
      destinationArray = advanced;
      destinationSetFunc = setAdvanced;
    }

    let items: Array<ISession> = [];
    if (source.droppableId === destination.droppableId) {
      items = reorder(sourceArray, result.source.index, result.destination?.index);
      sourceSetFunc(items);
    } else {
      const result = move(sourceArray, destinationArray, source, destination);
      sourceSetFunc(result[0]);
      items = result[1];
      destinationSetFunc(items);
    }

    Promise.all(
      items.map((session, index) => {
        session.order = index + 1;
        session.focus = destination.droppableId === "baseline" ? "baseline" : destination.droppableId === "stability" ? "stability" : "advanced";
        return saveSession(plan._id, session);
      }),
    );
  };

  return (
    <>
      {plan.sessions && plan.sessions.length > 0 && (
        <DragDropContext onDragEnd={onDragEnd}>
          <DroppableSection
            handleEditSession={session => handleEditSession({ plan, ...session })}
            planId={plan._id}
            sectionId="baseline"
            sectionLabel="Acute"
            videoSection={baseline}
          />
          <DroppableSection
            handleEditSession={session => handleEditSession({ plan, ...session })}
            planId={plan._id}
            sectionId="stability"
            sectionLabel="Intermediate"
            videoSection={stability}
          />
          <DroppableSection
            handleEditSession={session => handleEditSession({ plan, ...session })}
            planId={plan._id}
            sectionId="advanced"
            sectionLabel="Advanced"
            videoSection={advanced}
          />
        </DragDropContext>
      )}
      {plan.sessions.length === 0 && (
        <div style={{ background: "white", border: "1px solid #ddd", cursor: "pointer", padding: "1.5rem" }} onClick={() => handleEditSession({ plan } as any)}>
          <CreateIcon style={{ marginRight: ".5rem" }} />
          Create First Session
        </div>
      )}
    </>
  );
};

export default SessionsDisplay;
