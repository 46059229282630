export const defaultExampleVideos = [
  {
    _id: "5fb696e8db6c0812c2d32da7",
    isExampleVideo: true,
    name: "2 - Hand Rule 1",
    sas: "https://telecare.blob.core.windows.net/prod/5fb696e8db6c0812c2d32da7?sp=r&st=2020-11-19T16:08:59Z&se=2040-11-20T00:08:59Z&spr=https&sv=2019-12-12&sr=b&sig=0LR0yRxcQqofCE%2BAL9I5mVLh%2B8JuigIN36BYZv%2FoxdY%3D",
  },
  {
    _id: "5fb69710bd72a30f3103834f",
    isExampleVideo: true,
    name: "Adductor Mash",
    sas: "https://telecare.blob.core.windows.net/prod/5fb69710bd72a30f3103834f?sp=r&st=2020-11-19T16:11:01Z&se=2040-11-20T00:11:01Z&spr=https&sv=2019-12-12&sr=b&sig=eAAwFwTIRlG7VZrKWovt4olNIj958t22W5A7zt%2FF3YI%3D",
  },
  {
    _id: "5fb6974cfff043878f33f282",
    isExampleVideo: true,
    name: "Adductor Stretch",
    sas: "https://telecare.blob.core.windows.net/prod/5fb6974cfff043878f33f282?sp=r&st=2020-11-19T16:11:33Z&se=2040-11-20T00:11:33Z&spr=https&sv=2019-12-12&sr=b&sig=WjJ9Ilg1%2FiK58qNgGfhqIMqsY4XfWojmM%2BEBMx8phG0%3D",
  },
  {
    _id: "5fb6976fb8402ef31d411590",
    isExampleVideo: true,
    name: "Anterior Shin Mash",
    sas: "https://telecare.blob.core.windows.net/prod/5fb6976fb8402ef31d411590?sp=r&st=2020-11-19T16:12:06Z&se=2040-11-20T00:12:06Z&spr=https&sv=2019-12-12&sr=b&sig=h0e6jT1SV%2FoosoDk8bPv9WrtV28g2GFq1gwa9uwEmRg%3D",
  },
  {
    _id: "5fb6978e76dc86f8e7e45533",
    isExampleVideo: true,
    name: "Arch Mash",
    sas: "https://telecare.blob.core.windows.net/prod/5fb6978e76dc86f8e7e45533?sp=r&st=2020-11-19T16:12:37Z&se=2040-11-20T00:12:37Z&spr=https&sv=2019-12-12&sr=b&sig=GrqLF9aASxzoZh7kzLA2vhEya8MjeP8uwE1l1GEcMUc%3D",
  },
  {
    _id: "5fb697c74b6a6d9c5f7dd1fd",
    isExampleVideo: true,
    name: "Arm Circles",
    sas: "https://telecare.blob.core.windows.net/prod/5fb697c74b6a6d9c5f7dd1fd?sp=r&st=2020-11-19T16:13:13Z&se=2040-11-20T00:13:13Z&spr=https&sv=2019-12-12&sr=b&sig=1NomCVz06anCLiOP0HWGDKj%2BTuwnf3PrUu0RtGoPWys%3D",
  },
  {
    _id: "5fc809bed8131ff651b5847f",
    isExampleVideo: true,
    name: "Banded Overhead Press",
    sas: "https://telecare.blob.core.windows.net/prod/5fc809bed8131ff651b5847f?sv=2020-02-10&spr=https&se=2040-12-02T21%3A40%3A18Z&sr=b&sp=r&sig=E2UDsMnZDTl4eJGtXQ06kfv4wf89JaQiodm2G2MWvjU%3D",
  },
  {
    _id: "5fc809c2d8131ff651b58480",
    isExampleVideo: true,
    name: "Banded Press",
    sas: "https://telecare.blob.core.windows.net/prod/5fc809c2d8131ff651b58480?sv=2020-02-10&spr=https&se=2040-12-02T21%3A40%3A21Z&sr=b&sp=r&sig=Ufo3OtdIySNlpCW22hjq9Nf4wPSxRycPmDbCcx9H6kA%3D",
  },
  {
    _id: "5fc809c5d8131ff651b58481",
    isExampleVideo: true,
    name: "Banded Row",
    sas: "https://telecare.blob.core.windows.net/prod/5fc809c5d8131ff651b58481?sv=2020-02-10&spr=https&se=2040-12-02T21%3A40%3A23Z&sr=b&sp=r&sig=ZF8kzxPP5zkdfuog34VpQxpUyM1eWASih8Ps7o2UK6Y%3D",
  },
  {
    _id: "5fc809c7d8131ff651b58482",
    isExampleVideo: true,
    name: "Bear Hug Squat",
    sas: "https://telecare.blob.core.windows.net/prod/5fc809c7d8131ff651b58482?sv=2020-02-10&spr=https&se=2040-12-02T21%3A40%3A27Z&sr=b&sp=r&sig=fsROTG%2BhOcu0d0dhjVrroSe4YQ8EPDlK5vEcbuk5Lqg%3D",
  },
  {
    _id: "5fc809cbd8131ff651b58483",
    isExampleVideo: true,
    name: "Beginner Bug",
    sas: "https://telecare.blob.core.windows.net/prod/5fc809cbd8131ff651b58483?sv=2020-02-10&spr=https&se=2040-12-02T21%3A40%3A30Z&sr=b&sp=r&sig=nZwRaQUeL6N6NEtf0Wi%2BJE%2F574OxBYPsn6zlbIS005Y%3D",
  },
  {
    _id: "5fc809ced8131ff651b58484",
    isExampleVideo: true,
    name: "Bench Press - Single Arm",
    sas: "https://telecare.blob.core.windows.net/prod/5fc809ced8131ff651b58484?sv=2020-02-10&spr=https&se=2040-12-02T21%3A40%3A33Z&sr=b&sp=r&sig=qXdgUyq083t6j%2F60ZqfUqLTBIFxBnXZ2mfc9AoNjU9U%3D",
  },
  {
    _id: "5fc809d1d8131ff651b58485",
    isExampleVideo: true,
    name: "Bent Row - Bench with Dumbell",
    sas: "https://telecare.blob.core.windows.net/prod/5fc809d1d8131ff651b58485?sv=2020-02-10&spr=https&se=2040-12-02T21%3A40%3A36Z&sr=b&sp=r&sig=4co1sNIeiozHupPriqxFOrv8Xk%2Fd6DlxexRBV0W%2Bayw%3D",
  },
  {
    _id: "5fc809d4d8131ff651b58486",
    isExampleVideo: true,
    name: "Bent Row - Single Dumbell",
    sas: "https://telecare.blob.core.windows.net/prod/5fc809d4d8131ff651b58486?sv=2020-02-10&spr=https&se=2040-12-02T21%3A40%3A39Z&sr=b&sp=r&sig=eVmRfl4d0Let4wRfP2v3gtygY6FyZ3%2FfbQdscnt5YIo%3D",
  },
  {
    _id: "5fc809d7d8131ff651b58487",
    isExampleVideo: true,
    name: "Bilateral Quad Stretch",
    sas: "https://telecare.blob.core.windows.net/prod/5fc809d7d8131ff651b58487?sv=2020-02-10&spr=https&se=2040-12-02T21%3A40%3A41Z&sr=b&sp=r&sig=02Qx0omrRq7BcR6B9WdmR12IXPsZhq0t7vb%2FujTF9U4%3D",
  },
  {
    _id: "5fc809d9d8131ff651b58488",
    isExampleVideo: true,
    name: "Bruggar_s Relief Posture",
    sas: "https://telecare.blob.core.windows.net/prod/5fc809d9d8131ff651b58488?sv=2020-02-10&spr=https&se=2040-12-02T21%3A40%3A43Z&sr=b&sp=r&sig=imW1ZKOzY4%2F6i1D4olxUpUP3TxT2j11Cx8V7zob4m8E%3D",
  },
  {
    _id: "5fc809dbd8131ff651b58489",
    isExampleVideo: true,
    name: "Calf Mash",
    sas: "https://telecare.blob.core.windows.net/prod/5fc809dbd8131ff651b58489?sv=2020-02-10&spr=https&se=2040-12-02T21%3A40%3A46Z&sr=b&sp=r&sig=bP6T9R3IWOLylnvFp%2BRSWoBCP9idLsQkgGp8SB6%2BLtI%3D",
  },
  {
    _id: "5fc809ded8131ff651b5848a",
    isExampleVideo: true,
    name: "Calf Stretch",
    sas: "https://telecare.blob.core.windows.net/prod/5fc809ded8131ff651b5848a?sv=2020-02-10&spr=https&se=2040-12-02T21%3A40%3A48Z&sr=b&sp=r&sig=aAFS8Dg8l0THUBCNX2wjXYMFMcZf%2FvhP%2FZ4VrhN6m5Y%3D",
  },
  {
    _id: "5fc809e0d8131ff651b5848b",
    isExampleVideo: true,
    name: "Cat - Camel",
    sas: "https://telecare.blob.core.windows.net/prod/5fc809e0d8131ff651b5848b?sv=2020-02-10&spr=https&se=2040-12-02T21%3A40%3A50Z&sr=b&sp=r&sig=dlbTGkNt1x4bJwxnpD9X%2FYRQ4QssN3FEjohqVTjUMrQ%3D",
  },
  {
    _id: "5fc809e2d8131ff651b5848c",
    isExampleVideo: true,
    name: "Chair Squat",
    sas: "https://telecare.blob.core.windows.net/prod/5fc809e2d8131ff651b5848c?sv=2020-02-10&spr=https&se=2040-12-02T21%3A40%3A52Z&sr=b&sp=r&sig=bSEZDyKsskaW4RMxJNF17E8QNolcdNCiVfvRInAy0s8%3D",
  },
  {
    _id: "5fc809e4d8131ff651b5848d",
    isExampleVideo: true,
    name: "Curl Up",
    sas: "https://telecare.blob.core.windows.net/prod/5fc809e4d8131ff651b5848d?sv=2020-02-10&spr=https&se=2040-12-02T21%3A40%3A54Z&sr=b&sp=r&sig=W44sQg4Bk8uLmYpMMU6DrP6Z3fiUArTXUzJjGP2D7OY%3D",
  },
  {
    _id: "5fc809e6d8131ff651b5848e",
    isExampleVideo: true,
    name: "Dead Bug",
    sas: "https://telecare.blob.core.windows.net/prod/5fc809e6d8131ff651b5848e?sv=2020-02-10&spr=https&se=2040-12-02T21%3A40%3A56Z&sr=b&sp=r&sig=nxNkUOfeSX0%2BPWd9bbX9Vk%2BicVj4G3Lm791qQS2Ww2c%3D",
  },
  {
    _id: "5fc809e8d8131ff651b5848f",
    isExampleVideo: true,
    name: "Deadlift - Ball",
    sas: "https://telecare.blob.core.windows.net/prod/5fc809e8d8131ff651b5848f?sv=2020-02-10&spr=https&se=2040-12-02T21%3A40%3A58Z&sr=b&sp=r&sig=LklIFqM1HqFCsI5iBDJI75V3KMO4%2BccD2L2FBzn75Aw%3D",
  },
  {
    _id: "5fc809ead8131ff651b58490",
    isExampleVideo: true,
    name: "Deadlift - Reverse Kettlebell",
    sas: "https://telecare.blob.core.windows.net/prod/5fc809ead8131ff651b58490?sv=2020-02-10&spr=https&se=2040-12-02T21%3A41%3A00Z&sr=b&sp=r&sig=KrcjzRSEZQVSw0JMBYWFovDSdMjcJur8Qq%2F62RjjmdQ%3D",
  },
  {
    _id: "5fc809ecd8131ff651b58491",
    isExampleVideo: true,
    name: "Diaphragmatic Breathing - Seated",
    sas: "https://telecare.blob.core.windows.net/prod/5fc809ecd8131ff651b58491?sv=2020-02-10&spr=https&se=2040-12-02T21%3A41%3A02Z&sr=b&sp=r&sig=qbDmKZbUsyXbgk56dZSnz7Dbesgqcxxi0EVgQjHJzyc%3D",
  },
  {
    _id: "5fc809eed8131ff651b58492",
    isExampleVideo: true,
    name: "Diaphragmatic Breathing - Standing",
    sas: "https://telecare.blob.core.windows.net/prod/5fc809eed8131ff651b58492?sv=2020-02-10&spr=https&se=2040-12-02T21%3A41%3A05Z&sr=b&sp=r&sig=0UeUGFgOlG1lIWSvdU8qt8ZmNcGRfQIcps6jizmbczk%3D",
  },
  {
    _id: "5fc809f1d8131ff651b58493",
    isExampleVideo: true,
    name: "Diaphragmatic Breathing - Supine",
    sas: "https://telecare.blob.core.windows.net/prod/5fc809f1d8131ff651b58493?sv=2020-02-10&spr=https&se=2040-12-02T21%3A41%3A06Z&sr=b&sp=r&sig=Zhbopsf53mASRra%2BFqNe9sqO%2F3%2BkXJeeFVKk9zJ4%2Bq4%3D",
  },
  {
    _id: "5fc809f2d8131ff651b58494",
    isExampleVideo: true,
    name: "Diaphragmatic Breathing - Weight Assist",
    sas: "https://telecare.blob.core.windows.net/prod/5fc809f2d8131ff651b58494?sv=2020-02-10&spr=https&se=2040-12-02T21%3A41%3A07Z&sr=b&sp=r&sig=cwRG5A72u87%2B%2BYdFF9OdiZcyDbKvD41sKttu3CmBBl0%3D",
  },
  {
    _id: "5fc809f3d8131ff651b58495",
    isExampleVideo: true,
    name: "Double Sleeper Stretch",
    sas: "https://telecare.blob.core.windows.net/prod/5fc809f3d8131ff651b58495?sv=2020-02-10&spr=https&se=2040-12-02T21%3A41%3A09Z&sr=b&sp=r&sig=rrOAjie2c0zeU3yjh6JtCSnyyq6023nq7wIkyOxO%2BmE%3D",
  },
  {
    _id: "5fc809f5d8131ff651b58496",
    isExampleVideo: true,
    name: "Forearm Extensor Mash",
    sas: "https://telecare.blob.core.windows.net/prod/5fc809f5d8131ff651b58496?sv=2020-02-10&spr=https&se=2040-12-02T21%3A41%3A11Z&sr=b&sp=r&sig=kC1ZOuS%2Bc8MHLmPpWmeMHQImBMB%2FnbQdO3S2Yjt7TpQ%3D",
  },
  {
    _id: "5fc809f7d8131ff651b58497",
    isExampleVideo: true,
    name: "Forearm Flexor Mash",
    sas: "https://telecare.blob.core.windows.net/prod/5fc809f7d8131ff651b58497?sv=2020-02-10&spr=https&se=2040-12-02T21%3A41%3A18Z&sr=b&sp=r&sig=uybC%2BgZm48T0bS2XqWdlaU2cpJ0cBw6W%2BseI9jsDlLQ%3D",
  },
  {
    _id: "5fc809fed8131ff651b58498",
    isExampleVideo: true,
    name: "Forefoot Mobilizations",
    sas: "https://telecare.blob.core.windows.net/prod/5fc809fed8131ff651b58498?sv=2020-02-10&spr=https&se=2040-12-02T21%3A41%3A23Z&sr=b&sp=r&sig=N2tL9PV7aR6kwpBk0pVPSRLAi3SnUVipQu1XQS8tNpU%3D",
  },
  {
    _id: "5fc80a03d8131ff651b58499",
    isExampleVideo: true,
    name: "Front Lunge",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a03d8131ff651b58499?sv=2020-02-10&spr=https&se=2040-12-02T21%3A41%3A26Z&sr=b&sp=r&sig=LymD24QbsvlBQOLFQRUauPw4p9Bh9Fq87DIzwZ%2BF4zY%3D",
  },
  {
    _id: "5fc80a06d8131ff651b5849a",
    isExampleVideo: true,
    name: "Front Plank",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a06d8131ff651b5849a?sv=2020-02-10&spr=https&se=2040-12-02T21%3A41%3A28Z&sr=b&sp=r&sig=g0iUJff90WfdHV%2FOBv1G7Sfud5iGbG4zw2BUG0rtYp0%3D",
  },
  {
    _id: "5fc80a08d8131ff651b5849b",
    isExampleVideo: true,
    name: "Glute Abductions - Seated",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a08d8131ff651b5849b?sv=2020-02-10&spr=https&se=2040-12-02T21%3A41%3A30Z&sr=b&sp=r&sig=XhH2WqeEHI6lQxQO7srNMQUUVWxqZc4omwdvL7UXwRo%3D",
  },
  {
    _id: "5fc80a0ad8131ff651b5849c",
    isExampleVideo: true,
    name: "Glute Bridge",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a0ad8131ff651b5849c?sv=2020-02-10&spr=https&se=2040-12-02T21%3A41%3A32Z&sr=b&sp=r&sig=K33EJwEWHWyothuUiVD7WDghozQcU5z7zQndP878bXI%3D",
  },
  {
    _id: "5fc80a0cd8131ff651b5849d",
    isExampleVideo: true,
    name: "Glute Hamstring Activations - Prone",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a0cd8131ff651b5849d?sv=2020-02-10&spr=https&se=2040-12-02T21%3A41%3A34Z&sr=b&sp=r&sig=BTlfYZpjGSg0UZL0D5TrXAiCDWpEXejbkgYRGLmfMQE%3D",
  },
  {
    _id: "5fc80a0ed8131ff651b5849e",
    isExampleVideo: true,
    name: "Hamstring Mash - Floor",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a0ed8131ff651b5849e?sv=2020-02-10&spr=https&se=2040-12-02T21%3A41%3A37Z&sr=b&sp=r&sig=IkxJIeMakMXYOq9PXYKvQgGjTDN0KgCq0upKLVUg%2FPI%3D",
  },
  {
    _id: "5fc80a11d8131ff651b5849f",
    isExampleVideo: true,
    name: "Hamstring Mash - Seated",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a11d8131ff651b5849f?sv=2020-02-10&spr=https&se=2040-12-02T21%3A41%3A41Z&sr=b&sp=r&sig=eaAhgteb%2FD5vyaOxUa9oFqFuIQHm7XuYxBX2EvE5bLI%3D",
  },
  {
    _id: "5fc80a15d8131ff651b584a0",
    isExampleVideo: true,
    name: "Hamstring Stretch - Band Assisted",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a15d8131ff651b584a0?sv=2020-02-10&spr=https&se=2040-12-02T21%3A41%3A44Z&sr=b&sp=r&sig=hQhDFM%2FgSYhI%2FdkbVI0Upv%2BgjAm8Cu2U%2FJeicRZxaOs%3D",
  },
  {
    _id: "5fc80a18d8131ff651b584a1",
    isExampleVideo: true,
    name: "Hamstring Stretch - Kneeling",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a18d8131ff651b584a1?sv=2020-02-10&spr=https&se=2040-12-02T21%3A41%3A47Z&sr=b&sp=r&sig=vjE61yvrtSJsWzA65ZqTeLdhquAXP8WdS8pMTWuMmhI%3D",
  },
  {
    _id: "5fc80a1bd8131ff651b584a2",
    isExampleVideo: true,
    name: "Hamstring Stretch - Seated",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a1bd8131ff651b584a2?sv=2020-02-10&spr=https&se=2040-12-02T21%3A41%3A51Z&sr=b&sp=r&sig=ADK%2F8maCZZ7%2FDT96L6coESINnXJB37f64YXkpDnRhLY%3D",
  },
  {
    _id: "5fc80a1fd8131ff651b584a3",
    isExampleVideo: true,
    name: "Hip Hinge - Band Assisted",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a1fd8131ff651b584a3?sv=2020-02-10&spr=https&se=2040-12-02T21%3A41%3A53Z&sr=b&sp=r&sig=Y5oZ%2Fgyymr6B5xQFWnntjCUVGjucA2%2F8S%2BySPtUi9P0%3D",
  },
  {
    _id: "5fc80a21d8131ff651b584a4",
    isExampleVideo: true,
    name: "Hip Hinge - Kneeling",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a21d8131ff651b584a4?sv=2020-02-10&spr=https&se=2040-12-02T21%3A41%3A55Z&sr=b&sp=r&sig=kAQE8RVMNxfFvvu2JS2zNSOD6rIlFFTYbhR6NtVVU%2BI%3D",
  },
  {
    _id: "5fc80a23d8131ff651b584a5",
    isExampleVideo: true,
    name: "Hip Hinge - PVC Pipe Assistance",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a23d8131ff651b584a5?sv=2020-02-10&spr=https&se=2040-12-02T21%3A41%3A56Z&sr=b&sp=r&sig=RDDzwsu7N2nSgYWR%2FbGgEO%2BnomVT%2FOdSVigORXCJsWE%3D",
  },
  {
    _id: "5fc80a24d8131ff651b584a6",
    isExampleVideo: true,
    name: "Hip Hinge - Seated with Flexion Rotation",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a24d8131ff651b584a6?sv=2020-02-10&spr=https&se=2040-12-02T21%3A41%3A59Z&sr=b&sp=r&sig=KDivUO4Ai22w0eGBvcEG77VMoeEvudmq552Y3WJk3TY%3D",
  },
  {
    _id: "5fc80a27d8131ff651b584a7",
    isExampleVideo: true,
    name: "Hip Hinge - Seated with Rotation",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a27d8131ff651b584a7?sv=2020-02-10&spr=https&se=2040-12-02T21%3A42%3A02Z&sr=b&sp=r&sig=SlK%2B2n%2F%2F9aQFVa%2Bfb1ou4lJ1Ks%2FYf2g%2FIYuW7KKpYT4%3D",
  },
  {
    _id: "5fc80a2ad8131ff651b584a8",
    isExampleVideo: true,
    name: "Hip Hinge - Seated",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a2ad8131ff651b584a8?sv=2020-02-10&spr=https&se=2040-12-02T21%3A42%3A05Z&sr=b&sp=r&sig=Gy0XtltOaSF%2FFrJcpc7b96RyvTyVtuGcteMOJ3QEHC8%3D",
  },
  {
    _id: "5fc80a2dd8131ff651b584a9",
    isExampleVideo: true,
    name: "Hip Hinge - Single Leg",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a2dd8131ff651b584a9?sv=2020-02-10&spr=https&se=2040-12-02T21%3A42%3A07Z&sr=b&sp=r&sig=keyNLcyQWTR953FbinC3g4NrAq%2BE5toiW2LHAhLtvIs%3D",
  },
  {
    _id: "5fc80a2fd8131ff651b584aa",
    isExampleVideo: true,
    name: "Hip Hinge - Standing to Flexion Rotations",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a2fd8131ff651b584aa?sv=2020-02-10&spr=https&se=2040-12-02T21%3A42%3A11Z&sr=b&sp=r&sig=Tp4nfLV5QwJydqlpCCO7YW3pGEPqKCTukuvmq59hcQk%3D",
  },
  {
    _id: "5fc80a33d8131ff651b584ab",
    isExampleVideo: true,
    name: "Hip Hinge - Wall",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a33d8131ff651b584ab?sv=2020-02-10&spr=https&se=2040-12-02T21%3A42%3A13Z&sr=b&sp=r&sig=GZ%2B7J%2BR%2BnfbsTl1Sz6QeHF0A5va%2F9sjMsGxIPKC4RSI%3D",
  },
  {
    _id: "5fc80a35d8131ff651b584ac",
    isExampleVideo: true,
    name: "Hip Hinge",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a35d8131ff651b584ac?sv=2020-02-10&spr=https&se=2040-12-02T21%3A42%3A15Z&sr=b&sp=r&sig=vS44skBX%2FYkTtQUM88iIs8MT6Y4dSPbBAISJohO6H1k%3D",
  },
  {
    _id: "5fc80a37d8131ff651b584ad",
    isExampleVideo: true,
    name: "IT Band Stretch",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a37d8131ff651b584ad?sv=2020-02-10&spr=https&se=2040-12-02T21%3A42%3A18Z&sr=b&sp=r&sig=R9fzgJLkcdjXK5VVX8IfFLerVxQpGty3hkNcIL01yi0%3D",
  },
  {
    _id: "5fc80a3ad8131ff651b584ae",
    isExampleVideo: true,
    name: "Knee Gapping",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a3ad8131ff651b584ae?sv=2020-02-10&spr=https&se=2040-12-02T21%3A42%3A21Z&sr=b&sp=r&sig=p%2BAqG%2FC2HoARiYH6%2FUmE3xpRscx6NnmcXmQiotxXgow%3D",
  },
  {
    _id: "5fc80a3dd8131ff651b584af",
    isExampleVideo: true,
    name: "Lat Mash",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a3dd8131ff651b584af?sv=2020-02-10&spr=https&se=2040-12-02T21%3A42%3A27Z&sr=b&sp=r&sig=0COnIBUZNQ2VaKq5v2wJ7kyaicBWrWb2XCDD0BINY4E%3D",
  },
  {
    _id: "5fc80a43d8131ff651b584b0",
    isExampleVideo: true,
    name: "Lat Stretch",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a43d8131ff651b584b0?sv=2020-02-10&spr=https&se=2040-12-02T21%3A42%3A31Z&sr=b&sp=r&sig=ZXtNGf%2F6Y6IZN7Fl3fXm1ejm9jFSRtysUiDLNFnGlgw%3D",
  },
  {
    _id: "5fc80a47d8131ff651b584b1",
    isExampleVideo: true,
    name: "Lat-Tricep Stretch",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a47d8131ff651b584b1?sv=2020-02-10&spr=https&se=2040-12-02T21%3A42%3A35Z&sr=b&sp=r&sig=PwNMjaMBLqrhGWIrkW7SN1NZFBFc1%2BHM6VxggLoXz8o%3D",
  },
  {
    _id: "5fc80a4bd8131ff651b584b2",
    isExampleVideo: true,
    name: "Lateral Hip Mash - Floor",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a4bd8131ff651b584b2?sv=2020-02-10&spr=https&se=2040-12-02T21%3A42%3A37Z&sr=b&sp=r&sig=ABNHjT5lDBDjKVpjpbuDGoF2dcFVQFObIvp7O4XgynI%3D",
  },
  {
    _id: "5fc80a4dd8131ff651b584b3",
    isExampleVideo: true,
    name: "Lateral Hip Mash",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a4dd8131ff651b584b3?sv=2020-02-10&spr=https&se=2040-12-02T21%3A42%3A40Z&sr=b&sp=r&sig=xYfYSJDmM3zASX7Vlu%2FaFNdjlk7d00I2HrhMjkBJhrU%3D",
  },
  {
    _id: "5fc80a50d8131ff651b584b4",
    isExampleVideo: true,
    name: "Lateral Step-Up",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a50d8131ff651b584b4?sv=2020-02-10&spr=https&se=2040-12-02T21%3A42%3A42Z&sr=b&sp=r&sig=DVZBOGUsv5qe%2FNaIlN2BJZ4bd8iR0N5Uwm0CfCKwnhw%3D",
  },
  {
    _id: "5fc80a52d8131ff651b584b5",
    isExampleVideo: true,
    name: "Low Back Mash - Single Ball",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a52d8131ff651b584b5?sv=2020-02-10&spr=https&se=2040-12-02T21%3A42%3A44Z&sr=b&sp=r&sig=V3fR2bUHqTaMebnF80IcabN46PfI8wSEWYtBeGhcaQ0%3D",
  },
  {
    _id: "5fc80a54d8131ff651b584b6",
    isExampleVideo: true,
    name: "Low Back Stretch - Knee to Chest",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a54d8131ff651b584b6?sv=2020-02-10&spr=https&se=2040-12-02T21%3A42%3A46Z&sr=b&sp=r&sig=PLBcPzgbOLNRvuIFFtUbP28snxEFkIRj5ZJ1kP1S%2FDA%3D",
  },
  {
    _id: "5fc80a56d8131ff651b584b7",
    isExampleVideo: true,
    name: "Low Back Traction - Chair Assist",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a56d8131ff651b584b7?sv=2020-02-10&spr=https&se=2040-12-02T21%3A42%3A47Z&sr=b&sp=r&sig=t9WkbsCCLz2qS3hPG%2FipLC4U4cVUHWbJxOHfCSjkK8U%3D",
  },
  {
    _id: "5fc80a57d8131ff651b584b8",
    isExampleVideo: true,
    name: "Medial Shin Mash",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a57d8131ff651b584b8?sv=2020-02-10&spr=https&se=2040-12-02T21%3A42%3A51Z&sr=b&sp=r&sig=CEtgb4lGRvxt4ji577HL7oOrnFiTDk%2FKtFasxfTti34%3D",
  },
  {
    _id: "5fc80a5bd8131ff651b584b9",
    isExampleVideo: true,
    name: "Monster Walk - Lateral",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a5bd8131ff651b584b9?sv=2020-02-10&spr=https&se=2040-12-02T21%3A42%3A53Z&sr=b&sp=r&sig=xClJZB3KAksjEMHc5W8ezkCVTNm8rZ0vkfm0%2BjMrYhQ%3D",
  },
  {
    _id: "5fc80a5dd8131ff651b584ba",
    isExampleVideo: true,
    name: "Monster Walk",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a5dd8131ff651b584ba?sv=2020-02-10&spr=https&se=2040-12-02T21%3A42%3A56Z&sr=b&sp=r&sig=qeh0W8KlyCpypb9MN3xkwpP4ayvTzOxX8nNRclkBWIE%3D",
  },
  {
    _id: "5fc80a60d8131ff651b584bb",
    isExampleVideo: true,
    name: "Neck Mash - Wall with Ball",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a60d8131ff651b584bb?sv=2020-02-10&spr=https&se=2040-12-02T21%3A43%3A00Z&sr=b&sp=r&sig=vmMRzOJYVJOHsQMY14sZ2wQ%2BlydqB0Nfqmt125n%2B4xQ%3D",
  },
  {
    _id: "5fc80a64d8131ff651b584bc",
    isExampleVideo: true,
    name: "Neck Stretch - Levator Scap Focus",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a64d8131ff651b584bc?sv=2020-02-10&spr=https&se=2040-12-02T21%3A43%3A02Z&sr=b&sp=r&sig=2ieYG0XltKhE0RbpxOysuFVGqJqZz1NXDQBL1zkrGds%3D",
  },
  {
    _id: "5fc80a66d8131ff651b584bd",
    isExampleVideo: true,
    name: "Pallof Press - Anterior",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a66d8131ff651b584bd?sv=2020-02-10&spr=https&se=2040-12-02T21%3A43%3A04Z&sr=b&sp=r&sig=yGtN7%2FI0dkRzEapxHfLe1tTkAY4TD10MX%2F75EIjFKi4%3D",
  },
  {
    _id: "5fc80a68d8131ff651b584be",
    isExampleVideo: true,
    name: "Pallof Press - Posterior",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a68d8131ff651b584be?sv=2020-02-10&spr=https&se=2040-12-02T21%3A43%3A07Z&sr=b&sp=r&sig=igBL5hHsDY0JXS4bYFoS%2FaiWE9u2sLBoBBJDSXjuIzM%3D",
  },
  {
    _id: "5fc80a6bd8131ff651b584bf",
    isExampleVideo: true,
    name: "Pallof Pull - Anti Rotation",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a6bd8131ff651b584bf?sv=2020-02-10&spr=https&se=2040-12-02T21%3A43%3A10Z&sr=b&sp=r&sig=AuC4jAHai2xzXK41jMvI7OFkTq8RL6QikH0z39H1hFs%3D",
  },
  {
    _id: "5fc80a6ed8131ff651b584c0",
    isExampleVideo: true,
    name: "Palm Mash",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a6ed8131ff651b584c0?sv=2020-02-10&spr=https&se=2040-12-02T21%3A43%3A15Z&sr=b&sp=r&sig=fy%2BaalQbS%2FnjusZ4oR3Qwm3Ts9UKoyji8x10HxoaElc%3D",
  },
  {
    _id: "5fc80a73d8131ff651b584c1",
    isExampleVideo: true,
    name: "Pec Mash - Floor",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a73d8131ff651b584c1?sv=2020-02-10&spr=https&se=2040-12-02T21%3A43%3A17Z&sr=b&sp=r&sig=Dz9yydicn%2BW7f5D9II6D%2FBKahSJ%2BvZLkY0ZQxWtE5wQ%3D",
  },
  {
    _id: "5fc80a75d8131ff651b584c2",
    isExampleVideo: true,
    name: "Pec Mash",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a75d8131ff651b584c2?sv=2020-02-10&spr=https&se=2040-12-02T21%3A43%3A19Z&sr=b&sp=r&sig=jvfFCs8yzCNV5AjwHWazPpUAJMwTWHdVxDC4msRSsEo%3D",
  },
  {
    _id: "5fc80a77d8131ff651b584c3",
    isExampleVideo: true,
    name: "Pec Stretch - External Rotation and Extension",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a77d8131ff651b584c3?sv=2020-02-10&spr=https&se=2040-12-02T21%3A43%3A22Z&sr=b&sp=r&sig=0IT7FlJycrGn21ovD8wwwlI148zqctfTPj4vbeyqI5A%3D",
  },
  {
    _id: "5fc80a7ad8131ff651b584c4",
    isExampleVideo: true,
    name: "Pec Stretch - Internal Rotation and Extension",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a7ad8131ff651b584c4?sv=2020-02-10&spr=https&se=2040-12-02T21%3A43%3A27Z&sr=b&sp=r&sig=eMbfTL%2FXaogB4SCrd9k5LC4uYSw1ARHbTqA2boSQBQ4%3D",
  },
  {
    _id: "5fc80a7fd8131ff651b584c5",
    isExampleVideo: true,
    name: "Pigeon Stretch - Abduction Focus",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a7fd8131ff651b584c5?sv=2020-02-10&spr=https&se=2040-12-02T21%3A43%3A29Z&sr=b&sp=r&sig=rxkhxntGxHJncVUaVO6sfT7sef3%2Bge3jSPA4pP%2FHFOI%3D",
  },
  {
    _id: "5fc80a81d8131ff651b584c6",
    isExampleVideo: true,
    name: "Pigeon Stretch - Adduction Focus",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a81d8131ff651b584c6?sv=2020-02-10&spr=https&se=2040-12-02T21%3A43%3A34Z&sr=b&sp=r&sig=QMWMsDIvCoS63mj3WIVFafQyxKFamqo36WuSzIAKbBM%3D",
  },
  {
    _id: "5fc80a86d8131ff651b584c7",
    isExampleVideo: true,
    name: "Pigeon Stretch",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a86d8131ff651b584c7?sv=2020-02-10&spr=https&se=2040-12-02T21%3A43%3A38Z&sr=b&sp=r&sig=jF%2F6u%2F4vU%2B202%2FDjPE9Hw%2BOQyNyG9tUATvdM3I8hHuo%3D",
  },
  {
    _id: "5fc80a8ad8131ff651b584c8",
    isExampleVideo: true,
    name: "Posterior Hip Capsule Shear",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a8ad8131ff651b584c8?sv=2020-02-10&spr=https&se=2040-12-02T21%3A43%3A43Z&sr=b&sp=r&sig=7G8764ehCaD5i4RxCllk7Qh6o%2F67RYc9fNvBYvNr6Fw%3D",
  },
  {
    _id: "5fc80a8fd8131ff651b584c9",
    isExampleVideo: true,
    name: "Posterior Hip Mash - Roller",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a8fd8131ff651b584c9?sv=2020-02-10&spr=https&se=2040-12-02T21%3A43%3A48Z&sr=b&sp=r&sig=pgKfxhlGsvFOytxjJRWwf2IICrcj8Wwr58jnKCJlxJI%3D",
  },
  {
    _id: "5fc80a94d8131ff651b584ca",
    isExampleVideo: true,
    name: "Posterior Hip Stretch - Abduction Focus",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a94d8131ff651b584ca?sv=2020-02-10&spr=https&se=2040-12-02T21%3A43%3A52Z&sr=b&sp=r&sig=4XjIYtuktw5hJahPXOENroFsq3jPU%2FkF17x7iEIq6i0%3D",
  },
  {
    _id: "5fc80a98d8131ff651b584cb",
    isExampleVideo: true,
    name: "Posterior Hip Stretch - Adduction",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a98d8131ff651b584cb?sv=2020-02-10&spr=https&se=2040-12-02T21%3A43%3A54Z&sr=b&sp=r&sig=qm%2B4aDUG10oCMPqyH2fEhTZxNWJW1NwXjwvvJRwq%2F94%3D",
  },
  {
    _id: "5fc80a9ad8131ff651b584cc",
    isExampleVideo: true,
    name: "Posterior Hip Stretch - Supine",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a9ad8131ff651b584cc?sv=2020-02-10&spr=https&se=2040-12-02T21%3A43%3A56Z&sr=b&sp=r&sig=QikHFHFMsRIwLNqQQnTXEHtbT7DOVRK2iIZY9TtmfOM%3D",
  },
  {
    _id: "5fc80a9cd8131ff651b584cd",
    isExampleVideo: true,
    name: "Posterior Hip Stretch",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a9cd8131ff651b584cd?sv=2020-02-10&spr=https&se=2040-12-02T21%3A43%3A59Z&sr=b&sp=r&sig=lVsBpfZ7GnJzS6YGgAdLSK2CWpL%2F0xq7T2t0hGTqQWo%3D",
  },
  {
    _id: "5fc80a9fd8131ff651b584ce",
    isExampleVideo: true,
    name: "Posterior Shoulder Capsule Shear",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80a9fd8131ff651b584ce?sv=2020-02-10&spr=https&se=2040-12-02T21%3A44%3A02Z&sr=b&sp=r&sig=%2BlyYBQvHRQkaCvHdIarwLBoqoQ%2BK1RtzbPKnS%2FTaabo%3D",
  },
  {
    _id: "5fc80aa2d8131ff651b584cf",
    isExampleVideo: true,
    name: "Posterior Shoulder Mash",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80aa2d8131ff651b584cf?sv=2020-02-10&spr=https&se=2040-12-02T21%3A44%3A05Z&sr=b&sp=r&sig=Fu6g3Gbb0ssXzhGg5fbwQzwr58Tq6O5eXd1OrNpNZI4%3D",
  },
  {
    _id: "5fc80aa5d8131ff651b584d0",
    isExampleVideo: true,
    name: "Psoas Mash - Ball and Weight",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80aa5d8131ff651b584d0?sv=2020-02-10&spr=https&se=2040-12-02T21%3A44%3A06Z&sr=b&sp=r&sig=QWLcPnrhga%2BSjj6T0vb24e84nUbez60o6q7Y5kXzWDM%3D",
  },
  {
    _id: "5fc80aa6d8131ff651b584d1",
    isExampleVideo: true,
    name: "Psoas Mash - Ball",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80aa6d8131ff651b584d1?sv=2020-02-10&spr=https&se=2040-12-02T21%3A44%3A09Z&sr=b&sp=r&sig=bv1mAUR06eA807RuAozoppQBXnyPH%2BVuIk0gsCnuFs0%3D",
  },
  {
    _id: "5fc80aa9d8131ff651b584d2",
    isExampleVideo: true,
    name: "PushUp - Knees",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80aa9d8131ff651b584d2?sv=2020-02-10&spr=https&se=2040-12-02T21%3A44%3A11Z&sr=b&sp=r&sig=IWled8sbGCW2Ck%2Fu4Bcc%2FOYnGulMmCX7XKqrmQ3KqTc%3D",
  },
  {
    _id: "5fc80aabd8131ff651b584d3",
    isExampleVideo: true,
    name: "PushUp - Toes",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80aabd8131ff651b584d3?sv=2020-02-10&spr=https&se=2040-12-02T21%3A44%3A14Z&sr=b&sp=r&sig=lYkfVk4tBrF%2FEy3n3Xb8WvHfL1d1LimitbSnmEuyjP4%3D",
  },
  {
    _id: "5fc80aaed8131ff651b584d4",
    isExampleVideo: true,
    name: "QL Mash",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80aaed8131ff651b584d4?sv=2020-02-10&spr=https&se=2040-12-02T21%3A44%3A17Z&sr=b&sp=r&sig=%2B%2FSJ2IJxQ3V8BOty4Kzrk7IImoXDc2iBbEQ%2Fw637FmA%3D",
  },
  {
    _id: "5fc80ab1d8131ff651b584d5",
    isExampleVideo: true,
    name: "QL-Oblique Stretch",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80ab1d8131ff651b584d5?sv=2020-02-10&spr=https&se=2040-12-02T21%3A44%3A19Z&sr=b&sp=r&sig=fjodPgMq4gFwfrx4gvapAePABtYovUMdJx98NowAat4%3D",
  },
  {
    _id: "5fc80ab3d8131ff651b584d6",
    isExampleVideo: true,
    name: "Quad Mash",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80ab3d8131ff651b584d6?sv=2020-02-10&spr=https&se=2040-12-02T21%3A44%3A22Z&sr=b&sp=r&sig=5XCq%2Fp%2FciKMJBtCFWexdx6j0cESF14XulhScLKGHeLY%3D",
  },
  {
    _id: "5fc80ab6d8131ff651b584d7",
    isExampleVideo: true,
    name: "Quad-Psoas Stretch - Chair",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80ab6d8131ff651b584d7?sv=2020-02-10&spr=https&se=2040-12-02T21%3A44%3A24Z&sr=b&sp=r&sig=Hly3%2B99vvap87agj%2BpYBTtUNXmumRgzJl%2BrKHq2kGmY%3D",
  },
  {
    _id: "5fc80ab8d8131ff651b584d8",
    isExampleVideo: true,
    name: "Quad-Psoas Stretch",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80ab8d8131ff651b584d8?sv=2020-02-10&spr=https&se=2040-12-02T21%3A44%3A28Z&sr=b&sp=r&sig=MpVqMU2oIdBWbPW0Ps5GOS4NppmULjY%2BaN0DCAOVFHM%3D",
  },
  {
    _id: "5fc80abcd8131ff651b584d9",
    isExampleVideo: true,
    name: "Rib Cage Mash",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80abcd8131ff651b584d9?sv=2020-02-10&spr=https&se=2040-12-02T21%3A44%3A31Z&sr=b&sp=r&sig=1jg893Zu5ikYyANICTygRJ9zbaKJorH4nZ%2FNRXLhk9s%3D",
  },
  {
    _id: "5fc80abfd8131ff651b584da",
    isExampleVideo: true,
    name: "Scalene Mobility",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80abfd8131ff651b584da?sv=2020-02-10&spr=https&se=2040-12-02T21%3A44%3A33Z&sr=b&sp=r&sig=OeWqwxwDQPb0E0EXGQf2gnfomHsVHPcSkj6RdLhhLbE%3D",
  },
  {
    _id: "5fc80ac1d8131ff651b584db",
    isExampleVideo: true,
    name: "Scapular Mash and Mobility",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80ac1d8131ff651b584db?sv=2020-02-10&spr=https&se=2040-12-02T21%3A44%3A35Z&sr=b&sp=r&sig=KyydzxJdrs7fl9pAMXVOgL3OiWw0lC90m8binyq0zjM%3D",
  },
  {
    _id: "5fc80ac3d8131ff651b584dc",
    isExampleVideo: true,
    name: "Scapular Protractions",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80ac3d8131ff651b584dc?sv=2020-02-10&spr=https&se=2040-12-02T21%3A44%3A39Z&sr=b&sp=r&sig=yVToFCNZxul7FeaovgqKis%2FoI99qnkXCiUbL8W85vhM%3D",
  },
  {
    _id: "5fc80ac7d8131ff651b584dd",
    isExampleVideo: true,
    name: "Scapular Retractions",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80ac7d8131ff651b584dd?sv=2020-02-10&spr=https&se=2040-12-02T21%3A44%3A41Z&sr=b&sp=r&sig=0fW2Uh%2BKRIHcN%2BDO%2FM5t4I4VLiX1fWS5J2rIs04%2BvYI%3D",
  },
  {
    _id: "5fc80ac9d8131ff651b584de",
    isExampleVideo: true,
    name: "Seated Postural Corrections",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80ac9d8131ff651b584de?sv=2020-02-10&spr=https&se=2040-12-02T21%3A44%3A43Z&sr=b&sp=r&sig=8c0EEjykJgDouqs0fubSslf5X3sX2zPY6766tdgYMhU%3D",
  },
  {
    _id: "5fc80acbd8131ff651b584df",
    isExampleVideo: true,
    name: "Sit to Stand - Banded",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80acbd8131ff651b584df?sv=2020-02-10&spr=https&se=2040-12-02T21%3A44%3A47Z&sr=b&sp=r&sig=tZSWJwzsNJ6hi8bikIZIo9y5m8aoyzTz6pHsgZ%2BldQs%3D",
  },
  {
    _id: "5fc80acfd8131ff651b584e0",
    isExampleVideo: true,
    name: "Speedskater Deadlift",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80acfd8131ff651b584e0?sv=2020-02-10&spr=https&se=2040-12-02T21%3A44%3A49Z&sr=b&sp=r&sig=1QASSOIWYqHQKFyrIUQDyR6H%2FCH%2F9LjEOpDpHSR%2BcTQ%3D",
  },
  {
    _id: "5fc80ad1d8131ff651b584e1",
    isExampleVideo: true,
    name: "Sphinx Extensions",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80ad1d8131ff651b584e1?sv=2020-02-10&spr=https&se=2040-12-02T21%3A44%3A53Z&sr=b&sp=r&sig=OvBJ4JFsVi%2FgjIvrMHHUin2CF14PhKUSAeoeQUCbDUA%3D",
  },
  {
    _id: "5fc80ad5d8131ff651b584e2",
    isExampleVideo: true,
    name: "Spinal Twist - Single Leg",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80ad5d8131ff651b584e2?sv=2020-02-10&spr=https&se=2040-12-02T21%3A44%3A57Z&sr=b&sp=r&sig=3xie7BPhimx7s2c%2F3s834xkZ%2FGGMZ70TYeDbPVqWx18%3D",
  },
  {
    _id: "5fc80ad9d8131ff651b584e3",
    isExampleVideo: true,
    name: "Spinal Twist",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80ad9d8131ff651b584e3?sv=2020-02-10&spr=https&se=2040-12-02T21%3A44%3A59Z&sr=b&sp=r&sig=p3T9BcZ4DFexIxrYdGqMcax1F0RbBlj%2BGKWEK8wsAaQ%3D",
  },
  {
    _id: "5fc80adbd8131ff651b584e4",
    isExampleVideo: true,
    name: "Squat Hold - Wall to Hip Assist",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80adbd8131ff651b584e4?sv=2020-02-10&spr=https&se=2040-12-02T21%3A45%3A02Z&sr=b&sp=r&sig=WWOB8yljHvohpXpDJYAC7nyB%2B6%2FFr7kaq4bMhmMmpvM%3D",
  },
  {
    _id: "5fc80aded8131ff651b584e5",
    isExampleVideo: true,
    name: "Squat Therapy",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80aded8131ff651b584e5?sv=2020-02-10&spr=https&se=2040-12-02T21%3A45%3A04Z&sr=b&sp=r&sig=amNjaodHw6ct2iWI28JR1w6PIFDR8LQJ13A4Ha88bWA%3D",
  },
  {
    _id: "5fc80ae0d8131ff651b584e6",
    isExampleVideo: true,
    name: "Squatting Runners Stretch",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80ae0d8131ff651b584e6?sv=2020-02-10&spr=https&se=2040-12-02T21%3A45%3A07Z&sr=b&sp=r&sig=9UdvnlAqZrkjiGV4RaNDA67ULjajhngHE6DQOpKLicg%3D",
  },
  {
    _id: "5fc80ae3d8131ff651b584e7",
    isExampleVideo: true,
    name: "Step Down - Forward",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80ae3d8131ff651b584e7?sv=2020-02-10&spr=https&se=2040-12-02T21%3A45%3A09Z&sr=b&sp=r&sig=F%2BA67Z6JqOnmiCZvSoMKWhwTY0k9O4j56HOa2vWIYqo%3D",
  },
  {
    _id: "5fc80ae5d8131ff651b584e8",
    isExampleVideo: true,
    name: "StepUp - Down",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80ae5d8131ff651b584e8?sv=2020-02-10&spr=https&se=2040-12-02T21%3A45%3A12Z&sr=b&sp=r&sig=jC5JjsEK7dpgr1umbKC2WQTsk6rJsT5iU4N%2Btwu5oow%3D",
  },
  {
    _id: "5fc80ae8d8131ff651b584e9",
    isExampleVideo: true,
    name: "Stir the Pot",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80ae8d8131ff651b584e9?sv=2020-02-10&spr=https&se=2040-12-02T21%3A45%3A15Z&sr=b&sp=r&sig=Mm2X5WRSoMody%2BfLcdQZ%2Bn%2B6RSW6ZEVXBNEJk5QJtMs%3D",
  },
  {
    _id: "5fc80aebd8131ff651b584ea",
    isExampleVideo: true,
    name: "Terminal Knee Extension - Sitting",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80aebd8131ff651b584ea?sv=2020-02-10&spr=https&se=2040-12-02T21%3A45%3A16Z&sr=b&sp=r&sig=1J7UB5O1Z7KRc3wAKeZ%2BMpHAN2bGtNx%2B8DDAzPLpmFs%3D",
  },
  {
    _id: "5fc80aecd8131ff651b584eb",
    isExampleVideo: true,
    name: "Terminal Knee Extension",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80aecd8131ff651b584eb?sv=2020-02-10&spr=https&se=2040-12-02T21%3A45%3A17Z&sr=b&sp=r&sig=y%2BuddArtAu2F1hDAaBrXxbpHj7XuKDY8jtdyv7ylpg8%3D",
  },
  {
    _id: "5fc80aedd8131ff651b584ec",
    isExampleVideo: true,
    name: "Thoracic Spine Extension - PVC Overhead",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80aedd8131ff651b584ec?sv=2020-02-10&spr=https&se=2040-12-02T21%3A45%3A19Z&sr=b&sp=r&sig=pm8X8jWyNrAZMcdjonNQwoszevXQEepAhLLF7tR%2FAf4%3D",
  },
  {
    _id: "5fc80aefd8131ff651b584ed",
    isExampleVideo: true,
    name: "Thoracic Spine Extension - Single Arm Motion",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80aefd8131ff651b584ed?sv=2020-02-10&spr=https&se=2040-12-02T21%3A45%3A22Z&sr=b&sp=r&sig=cISwGSbiV2jhnwiE9%2Fd8zqzcUTpHIPgpwUHsKg65HY4%3D",
  },
  {
    _id: "5fc80af2d8131ff651b584ee",
    isExampleVideo: true,
    name: "Thoracic Spine Extension - Tilts",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80af2d8131ff651b584ee?sv=2020-02-10&spr=https&se=2040-12-02T21%3A45%3A23Z&sr=b&sp=r&sig=bjSnw4DXC%2BMlHrViHXvVFwjXo7a%2BJ3IQoNOPjXWH%2FMY%3D",
  },
  {
    _id: "5fc80af3d8131ff651b584ef",
    isExampleVideo: true,
    name: "Trap Stretch",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80af3d8131ff651b584ef?sv=2020-02-10&spr=https&se=2040-12-02T21%3A45%3A25Z&sr=b&sp=r&sig=VlON9FOJFEbRzyq%2Fy%2Ft%2BwOxabQDwajyEB%2BhS485DUIE%3D",
  },
  {
    _id: "5fc80af5d8131ff651b584f0",
    isExampleVideo: true,
    name: "Tricep Mash",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80af5d8131ff651b584f0?sv=2020-02-10&spr=https&se=2040-12-02T21%3A45%3A28Z&sr=b&sp=r&sig=mQOdSMU3QkGVRR%2B7KxybQL7TyW1DoiX7sHmitIgq2oA%3D",
  },
  {
    _id: "5fc80af8d8131ff651b584f1",
    isExampleVideo: true,
    name: "Wall Pushup - Elbow Mobility",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80af8d8131ff651b584f1?sv=2020-02-10&spr=https&se=2040-12-02T21%3A45%3A32Z&sr=b&sp=r&sig=bE%2BQ9NOmJyJOVqaPe9xCW%2FucmW%2Fumxh%2F1pmJq2UMdn4%3D",
  },
  {
    _id: "5fc80afcd8131ff651b584f2",
    isExampleVideo: true,
    name: "Wall Pushup",
    sas: "https://telecare.blob.core.windows.net/prod/5fc80afcd8131ff651b584f2?sv=2020-02-10&spr=https&se=2040-12-02T21%3A45%3A36Z&sr=b&sp=r&sig=r6%2FKmh7HOchW0Ln9s4E8TcYiZMI%2BeXAviKpLWeE87G8%3D",
  },
  {
    _id: "62265aac14752d2fc85fbc72",
    isExampleVideo: true,
    name: "Back Extensions - Adductor Focus",
    sas: "https://telecare.blob.core.windows.net/prod/62265aac14752d2fc85fbc72?sv=2020-08-04&st=2022-03-07T19%3A09%3A25Z&se=2042-03-08T19%3A09%3A00Z&sr=b&sp=r&sig=nlameXk3GXmCquNiZK0qipYhagHQmk74swrfqNy17fw%3D",
  },
  {
    _id: "62265aac14752d2fc85fbc73",
    isExampleVideo: true,
    name: "Back Extensions",
    sas: "https://telecare.blob.core.windows.net/prod/62265aac14752d2fc85fbc73?sv=2020-08-04&st=2022-03-07T19%3A31%3A44Z&se=2042-03-08T19%3A31%3A00Z&sr=b&sp=r&sig=JenoOxOHZax9iE6Jii%2BIjT7SnZzWJhicIfz9QvqQ7SY%3D",
  },
  {
    _id: "62265aac14752d2fc85fbc74",
    isExampleVideo: true,
    name: "Child's Pose to Kneeling Foundation Flow",
    sas: "https://telecare.blob.core.windows.net/prod/62265aac14752d2fc85fbc74?sv=2020-08-04&st=2022-03-07T19%3A32%3A08Z&se=2042-03-08T19%3A32%3A00Z&sr=b&sp=r&sig=oK41LPBPkJWyeZ6jisauA2AC5vVHHoYIcvodAGRxtEY%3D",
  },
  {
    _id: "62265aac14752d2fc85fbc75",
    isExampleVideo: true,
    name: "Decompression Breathing",
    sas: "https://telecare.blob.core.windows.net/prod/62265aac14752d2fc85fbc75?sv=2020-08-04&st=2022-03-07T19%3A32%3A28Z&se=2042-03-08T19%3A32%3A00Z&sr=b&sp=r&sig=1lHObDK4dbu9OBs%2FbCJ7Tin7Ml410UacVapawyc0wOA%3D",
  },
  {
    _id: "62265aac14752d2fc85fbc76",
    isExampleVideo: true,
    name: "Foundational Flow",
    sas: "https://telecare.blob.core.windows.net/prod/62265aac14752d2fc85fbc76?sv=2020-08-04&st=2022-03-07T19%3A32%3A43Z&se=2042-03-08T19%3A32%3A00Z&sr=b&sp=r&sig=UpajiWlJYDSUNcDeSgBzB3sfhiiftfheDtolO41AiZ4%3D",
  },
  {
    _id: "62265aac14752d2fc85fbc77",
    isExampleVideo: true,
    name: "Foundational Plank",
    sas: "https://telecare.blob.core.windows.net/prod/62265aac14752d2fc85fbc77?sv=2020-08-04&st=2022-03-07T19%3A40%3A12Z&se=2042-03-08T19%3A40%3A00Z&sr=b&sp=r&sig=EAIhBfAampOgS9cbhDHIWgoB2zGitPlM8tr%2BOGXN2DE%3D",
  },
  {
    _id: "62265aac14752d2fc85fbc78",
    isExampleVideo: true,
    name: "Foundational Squat",
    sas: "https://telecare.blob.core.windows.net/prod/62265aac14752d2fc85fbc78?sv=2020-08-04&st=2022-03-07T19%3A40%3A31Z&se=2042-03-08T19%3A40%3A00Z&sr=b&sp=r&sig=MEzWDl%2B7M3%2BZkXunjFlczooQCJ3ng3dmQWSoq94Ao0A%3D",
  },
  {
    _id: "62265aac14752d2fc85fbc79",
    isExampleVideo: true,
    name: "Good Morning",
    sas: "https://telecare.blob.core.windows.net/prod/62265aac14752d2fc85fbc79?sv=2020-08-04&st=2022-03-07T19%3A40%3A46Z&se=2042-03-08T19%3A40%3A00Z&sr=b&sp=r&sig=u%2FEGYmfU5UXnP3il6amYQIujZ9fDpWlph0PInmTmJoo%3D",
  },
  {
    _id: "62265aac14752d2fc85fbc7a",
    isExampleVideo: true,
    name: "Prone Cervical Sphinx",
    sas: "https://telecare.blob.core.windows.net/prod/62265aac14752d2fc85fbc7a?sv=2020-08-04&st=2022-03-07T19%3A42%3A00Z&se=2042-03-08T19%3A42%3A00Z&sr=b&sp=r&sig=LGKOR3Cb6vZrkUXFQR36j9AFnWspdC7eayAc1jWN0cE%3D",
  },
  {
    _id: "62265aac14752d2fc85fbc7b",
    isExampleVideo: true,
    name: "Prone Lumbar Extension - Partner Pressure",
    sas: "https://telecare.blob.core.windows.net/prod/62265aac14752d2fc85fbc7b?sv=2020-08-04&st=2022-03-07T19%3A42%3A34Z&se=2042-03-08T19%3A42%3A00Z&sr=b&sp=r&sig=Wtv2%2FtBUQQzgA9vRmbursCuh%2Fr2IApSpXujoIwcXJR0%3D",
  },
  {
    _id: "62265aac14752d2fc85fbc7c",
    isExampleVideo: true,
    name: "Prone Lumbar Extension",
    sas: "https://telecare.blob.core.windows.net/prod/62265aac14752d2fc85fbc7c?sv=2020-08-04&st=2022-03-07T19%3A42%3A49Z&se=2042-03-08T19%3A42%3A00Z&sr=b&sp=r&sig=%2BGEz02%2B0kVGBcQ8rYyY542oAfe85DmAJZtlgs%2BqeSdI%3D",
  },
  {
    _id: "62265aac14752d2fc85fbc7d",
    isExampleVideo: true,
    name: "Prone Lumbar Extensions - Off Center",
    sas: "https://telecare.blob.core.windows.net/prod/62265aac14752d2fc85fbc7d?sv=2020-08-04&st=2022-03-07T19%3A43%3A05Z&se=2042-03-08T19%3A43%3A00Z&sr=b&sp=r&sig=XYoBsIZsdz9aSEIqhG4uGyS3hDUb3cvFkXBfzo8Rt24%3D",
  },
  {
    _id: "62265aac14752d2fc85fbc7e",
    isExampleVideo: true,
    name: "Prone Sphinx",
    sas: "https://telecare.blob.core.windows.net/prod/62265aac14752d2fc85fbc7e?sv=2020-08-04&st=2022-03-07T19%3A43%3A24Z&se=2042-03-08T19%3A43%3A00Z&sr=b&sp=r&sig=S2cWQ%2B1O0K6RjRhEsdJYFmDqnF2tmSf%2FUPPPLyS4aIA%3D",
  },
  {
    _id: "62265aac14752d2fc85fbc7f",
    isExampleVideo: true,
    name: "Prone Thoracic Extensions",
    sas: "https://telecare.blob.core.windows.net/prod/62265aac14752d2fc85fbc7f?sv=2020-08-04&st=2022-03-07T19%3A43%3A40Z&se=2042-03-08T19%3A43%3A00Z&sr=b&sp=r&sig=vAqOf3ZC%2F3Uw%2Fr3xgKiT44saba%2BR5FFutgugf2LWmRU%3D",
  },
  {
    _id: "62265aac14752d2fc85fbc80",
    isExampleVideo: true,
    name: "Seated Ballistic Thoracic Rotations",
    sas: "https://telecare.blob.core.windows.net/prod/62265aac14752d2fc85fbc80?sv=2020-08-04&st=2022-03-07T19%3A43%3A58Z&se=2042-03-08T19%3A43%3A00Z&sr=b&sp=r&sig=75IqS26zYjlJdNuYp24YmdUy%2F6TDaKKSzsVanrTZyz0%3D",
  },
  {
    _id: "62265aac14752d2fc85fbc81",
    isExampleVideo: true,
    name: "Seated Cervical Extensions",
    sas: "https://telecare.blob.core.windows.net/prod/62265aac14752d2fc85fbc81?sv=2020-08-04&st=2022-03-07T19%3A44%3A14Z&se=2042-03-08T19%3A44%3A00Z&sr=b&sp=r&sig=%2FghJ3pOOlPdwDjilPzWCjHBDsvAFOJF3H3s6M6yYE7k%3D",
  },
  {
    _id: "62265aac14752d2fc85fbc82",
    isExampleVideo: true,
    name: "Seated Cervical Retractions - Patient Pressure",
    sas: "https://telecare.blob.core.windows.net/prod/62265aac14752d2fc85fbc82?sv=2020-08-04&st=2022-03-07T19%3A44%3A34Z&se=2042-03-08T19%3A44%3A00Z&sr=b&sp=r&sig=Nkba2EU9AamJ5MU9wSKsjnkaVV5rwGJZWmV2yQwVWJk%3D",
  },
  {
    _id: "62265aac14752d2fc85fbc83",
    isExampleVideo: true,
    name: "Seated Cervical Retractions with Extension - Patient Pressure",
    sas: "https://telecare.blob.core.windows.net/prod/62265aac14752d2fc85fbc83?sv=2020-08-04&st=2022-03-07T19%3A44%3A48Z&se=2042-03-08T19%3A44%3A00Z&sr=b&sp=r&sig=Z%2BNwz1reJhU7OvCYLH5VDMD7bFDNaCNuvprYirWJuis%3D",
  },
  {
    _id: "62265aac14752d2fc85fbc84",
    isExampleVideo: true,
    name: "Seated Cervical Retractions with Extension",
    sas: "https://telecare.blob.core.windows.net/prod/62265aac14752d2fc85fbc84?sv=2020-08-04&st=2022-03-07T19%3A45%3A02Z&se=2042-03-08T19%3A45%3A00Z&sr=b&sp=r&sig=ZYq6v9FBYWFX2kITii57PdZCOXC%2FNR50dS63fq8Uwsc%3D",
  },
  {
    _id: "62265aac14752d2fc85fbc85",
    isExampleVideo: true,
    name: "Seated Cervical Retractions with Flexion - Patient Pressure",
    sas: "https://telecare.blob.core.windows.net/prod/62265aac14752d2fc85fbc85?sv=2020-08-04&st=2022-03-07T20%3A32%3A21Z&se=2042-03-08T20%3A32%3A00Z&sr=b&sp=r&sig=s7KdsPkLW%2Baut6%2BvlnowKyZa1wc6HHefsj%2BxZ02RNCU%3D",
  },
  {
    _id: "62265aac14752d2fc85fbc86",
    isExampleVideo: true,
    name: "Seated Cervical Retractions with Flexion and Rotation - Patient Pressure",
    sas: "https://telecare.blob.core.windows.net/prod/62265aac14752d2fc85fbc86?sv=2020-08-04&st=2022-03-07T20%3A32%3A39Z&se=2042-03-08T20%3A32%3A00Z&sr=b&sp=r&sig=hgJWmFzJf%2BcK%2BH5tPrY5LFGGQ%2FH13JD07j0RalpZdZg%3D",
  },
  {
    _id: "62265aac14752d2fc85fbc87",
    isExampleVideo: true,
    name: "Seated Cervical Retractions with Flexion",
    sas: "https://telecare.blob.core.windows.net/prod/62265aac14752d2fc85fbc87?sv=2020-08-04&st=2022-03-07T20%3A33%3A05Z&se=2042-03-08T20%3A33%3A00Z&sr=b&sp=r&sig=JVa3OXbQDobAOk8od2TStNrrM%2FSOmLMfIbPRQLk%2F5DY%3D",
  },
  {
    _id: "62265aac14752d2fc85fbc88",
    isExampleVideo: true,
    name: "Seated Cervical Retractions",
    sas: "https://telecare.blob.core.windows.net/prod/62265aac14752d2fc85fbc88?sv=2020-08-04&st=2022-03-07T20%3A33%3A20Z&se=2042-03-08T20%3A33%3A00Z&sr=b&sp=r&sig=OwPzIiGKL6PnSI3tIV8PaHgiz0%2Ff4qcRSlBK%2F7CBvHg%3D",
  },
  {
    _id: "62265aac14752d2fc85fbc89",
    isExampleVideo: true,
    name: "Seated Lumbar Flexions - Patient Pressure",
    sas: "https://telecare.blob.core.windows.net/prod/62265aac14752d2fc85fbc89?sv=2020-08-04&st=2022-03-07T20%3A33%3A32Z&se=2042-03-08T20%3A33%3A00Z&sr=b&sp=r&sig=eJR3PqC0Qv%2FCOHIjaNw0y4blXnUVDNeKCsvhYsoF5oo%3D",
  },
  {
    _id: "62265aac14752d2fc85fbc8a",
    isExampleVideo: true,
    name: "Seated Lumbar Flexions",
    sas: "https://telecare.blob.core.windows.net/prod/62265aac14752d2fc85fbc8a?sv=2020-08-04&st=2022-03-07T20%3A33%3A44Z&se=2042-03-08T20%3A33%3A00Z&sr=b&sp=r&sig=jMW2UeC8MwIQPnccadtiWrFSk73L3DS9cXBUng%2BkbYY%3D",
  },
  {
    _id: "62265aac14752d2fc85fbc8b",
    isExampleVideo: true,
    name: "Seated Postural Corrections",
    sas: "https://telecare.blob.core.windows.net/prod/62265aac14752d2fc85fbc8b?sv=2020-08-04&st=2022-03-07T20%3A33%3A58Z&se=2042-03-08T20%3A33%3A00Z&sr=b&sp=r&sig=ppcr1GSe39QAlCQ2OnOX5X8z87kO7XFRW0y8VuWvtB4%3D",
  },
  {
    _id: "62265aac14752d2fc85fbc8c",
    isExampleVideo: true,
    name: "Seated Thoracic Extensions",
    sas: "https://telecare.blob.core.windows.net/prod/62265aac14752d2fc85fbc8c?sv=2020-08-04&st=2022-03-07T20%3A34%3A11Z&se=2042-03-08T20%3A34%3A00Z&sr=b&sp=r&sig=you9OcUxuxjgEPQMZkQTiz8mB9UMEGLCln%2B5WjLtP48%3D",
  },
  {
    _id: "62265aac14752d2fc85fbc8d",
    isExampleVideo: true,
    name: "Seated Thoracic Rotations",
    sas: "https://telecare.blob.core.windows.net/prod/62265aac14752d2fc85fbc8d?sv=2020-08-04&st=2022-03-07T20%3A34%3A26Z&se=2042-03-08T20%3A34%3A00Z&sr=b&sp=r&sig=inuN140Ttakq78b56QFv1JqjnHdpUgj009gjhZ59Xj0%3D",
  },
  {
    _id: "62265aac14752d2fc85fbc8e",
    isExampleVideo: true,
    name: "Standing Lateral Glides",
    sas: "https://telecare.blob.core.windows.net/prod/62265aac14752d2fc85fbc8e?sv=2020-08-04&st=2022-03-07T20%3A34%3A40Z&se=2042-03-08T20%3A34%3A00Z&sr=b&sp=r&sig=ZJ5BgNhhdPh4LzVU6ZclAO7BzNvbttfTSU%2BYGlYvAF4%3D",
  },
  {
    _id: "62265aac14752d2fc85fbc8f",
    isExampleVideo: true,
    name: "Standing Lumbar Extensions",
    sas: "https://telecare.blob.core.windows.net/prod/62265aac14752d2fc85fbc8f?sv=2020-08-04&st=2022-03-07T20%3A35%3A00Z&se=2042-03-08T20%3A35%3A00Z&sr=b&sp=r&sig=H3ja%2FtCujknJbRq%2FpAMQLKBNvkjyforsyAvGmMksJEw%3D",
  },
  {
    _id: "62265aac14752d2fc85fbc90",
    isExampleVideo: true,
    name: "Supine Lumbar Flexions with Rotation",
    sas: "https://telecare.blob.core.windows.net/prod/62265aac14752d2fc85fbc90?sv=2020-08-04&st=2022-03-07T20%3A35%3A17Z&se=2042-03-08T20%3A35%3A00Z&sr=b&sp=r&sig=h6LBMpossMsJjjfGF8B4lpGBHNTCQu%2FHbVQ9FzfARQU%3D",
  },
  {
    _id: "62265aac14752d2fc85fbc91",
    isExampleVideo: true,
    name: "Supine Lumbar Flexions",
    sas: "https://telecare.blob.core.windows.net/prod/62265aac14752d2fc85fbc91?sv=2020-08-04&st=2022-03-07T20%3A35%3A31Z&se=2042-03-08T20%3A35%3A00Z&sr=b&sp=r&sig=%2BmBC7gufmF1VRsFRQbocBwWUk%2BJKyn0USy49JQBnQDM%3D",
  },
  {
    _id: "62265aac14752d2fc85fbc92",
    isExampleVideo: true,
    name: "Windmill",
    sas: "https://telecare.blob.core.windows.net/prod/62265aac14752d2fc85fbc92?sv=2020-08-04&st=2022-03-07T20%3A35%3A42Z&se=2042-03-08T20%3A35%3A00Z&sr=b&sp=r&sig=0MaRcify7B937ANjjEcZxjHEzqGGBIyLe%2FOHTQeNpXE%3D",
  },
  {
    _id: "62265aac14752d2fc85fbc93",
    isExampleVideo: true,
    name: "Woodpecker",
    sas: "https://telecare.blob.core.windows.net/prod/62265aac14752d2fc85fbc93?sv=2020-08-04&st=2022-03-07T20%3A35%3A54Z&se=2042-03-08T20%3A35%3A00Z&sr=b&sp=r&sig=CuuQ0hdgsTK6G6QmOdQkMJ3VsktOR9zeIMr2b3H4s2U%3D",
  },
  {
    _id: "624f3c586617c38238d43e15",
    isExampleVideo: true,
    name: "Banded External Rotations",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e15?sv=2020-10-02&st=2022-04-08T13%3A50%3A16Z&se=2040-04-09T13%3A50%3A00Z&sr=b&sp=r&sig=mteMP0K91bADD7ta0PbR80uotiT7fppQvg74df6RfGE%3D",
  },
  {
    _id: "624f3c586617c38238d43e16",
    isExampleVideo: true,
    name: "Banded Press with Scaptions",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e16?sv=2020-10-02&st=2022-04-08T13%3A51%3A01Z&se=2040-04-09T13%3A51%3A00Z&sr=b&sp=r&sig=E4OBuVY95hUobjkrIkGK1040OJ%2BSD28knkDHpgJtiQk%3D",
  },
  {
    _id: "624f3c586617c38238d43e17",
    isExampleVideo: true,
    name: "Calf and Achilles Stretch with Eversion",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e17?sv=2020-10-02&st=2022-04-08T13%3A51%3A24Z&se=2040-04-09T13%3A51%3A00Z&sr=b&sp=r&sig=dJr1hf6Vhae%2FrC0pFgLz3vI2dQu3R8OT8wDoIAAUlZc%3D",
  },
  {
    _id: "624f3c586617c38238d43e18",
    isExampleVideo: true,
    name: "Calf and Achilles Stretch",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e18?sv=2020-10-02&st=2022-04-08T13%3A51%3A46Z&se=2040-04-09T13%3A51%3A00Z&sr=b&sp=r&sig=ouzPiRbI6JN7T3S4NU5f9LFpglOedFzfGLJyzX3d1J4%3D",
  },
  {
    _id: "624f3c586617c38238d43e19",
    isExampleVideo: true,
    name: "Chair Squat - Narrow Stance",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e19?sv=2020-10-02&st=2022-04-08T13%3A52%3A06Z&se=2040-04-09T13%3A52%3A00Z&sr=b&sp=r&sig=PTMCXUeGr66ijRL0FCNtDyqOiZU7b1p%2BWHrMfq%2BREuU%3D",
  },
  {
    _id: "624f3c586617c38238d43e1a",
    isExampleVideo: true,
    name: "Chair Squat - Wide Stance",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e1a?sv=2020-10-02&st=2022-04-08T13%3A53%3A47Z&se=2040-04-09T13%3A53%3A00Z&sr=b&sp=r&sig=%2B1zWUTejPaJ%2FavmE%2F1RqhAcrfCruY6v1cncLNRUyQuE%3D",
  },
  {
    _id: "624f3c586617c38238d43e1b",
    isExampleVideo: true,
    name: "Crucifix Curls",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e1b?sv=2020-10-02&st=2022-04-08T13%3A54%3A07Z&se=2040-04-09T13%3A54%3A00Z&sr=b&sp=r&sig=JO%2BVxSzcmWxpsFAqbO4B96eS4mEOQceYrX4RUu54Xlc%3D",
  },
  {
    _id: "624f3c586617c38238d43e1c",
    isExampleVideo: true,
    name: "Gait - Standing 123_s",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e1c?sv=2020-10-02&st=2022-04-08T13%3A55%3A17Z&se=2040-04-09T13%3A55%3A00Z&sr=b&sp=r&sig=mPuEinvQzUgbJgTFOUuHe3ylTdVdfdTFufiFSwMxGEQ%3D",
  },
  {
    _id: "624f3c586617c38238d43e1d",
    isExampleVideo: true,
    name: "Gait - Standing Pulls - Alternating",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e1d?sv=2020-10-02&st=2022-04-08T13%3A55%3A38Z&se=2040-04-09T13%3A55%3A00Z&sr=b&sp=r&sig=jS6tjF3vPP7BTctdvamjrmxez6IwveTzJtMMa1XMfNQ%3D",
  },
  {
    _id: "624f3c586617c38238d43e1e",
    isExampleVideo: true,
    name: "Gait - Standing Pulls",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e1e?sv=2020-10-02&st=2022-04-08T13%3A56%3A05Z&se=2040-04-09T13%3A56%3A00Z&sr=b&sp=r&sig=dLO17eMHiL5Dw3mksDK47Lu0JQeoZpUdv7FVVjxHk7k%3D",
  },
  {
    _id: "624f3c586617c38238d43e1f",
    isExampleVideo: true,
    name: "Gait - Wall Pulls - 123_s",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e1f?sv=2020-10-02&st=2022-04-08T13%3A56%3A31Z&se=2040-04-09T13%3A56%3A00Z&sr=b&sp=r&sig=92%2BpxbJ0adHEmGCJ%2FOYTLniBAm2j%2FtQ%2BIZEE4cQlfDw%3D",
  },
  {
    _id: "624f3c586617c38238d43e20",
    isExampleVideo: true,
    name: "Gait - Wall Pulls - Alternating",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e20?sv=2020-10-02&st=2022-04-08T13%3A56%3A52Z&se=2040-04-09T13%3A56%3A00Z&sr=b&sp=r&sig=8OeMjtB61lei5cHvksTHDIIaWBc3hfm2ZA30oYKitpM%3D",
  },
  {
    _id: "624f3c586617c38238d43e21",
    isExampleVideo: true,
    name: "Gait - Wall Pulls",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e21?sv=2020-10-02&st=2022-04-08T13%3A57%3A12Z&se=2040-04-09T13%3A57%3A00Z&sr=b&sp=r&sig=5G6HpUYqtnobacZR0WWEGnbZl%2FTO6J5tgcVxrbyP58g%3D",
  },
  {
    _id: "624f3c586617c38238d43e22",
    isExampleVideo: true,
    name: "Goodmorning with PVC",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e22?sv=2020-10-02&st=2022-04-08T13%3A57%3A39Z&se=2040-04-09T13%3A57%3A00Z&sr=b&sp=r&sig=bNHFbbUSckFtEHUXq5c3KHqPX4tn3uIhNQuFSMNFZyo%3D",
  },
  {
    _id: "624f3c586617c38238d43e23",
    isExampleVideo: true,
    name: "Hinge Flexion Hinge",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e23?sv=2020-10-02&st=2022-04-08T13%3A57%3A58Z&se=2040-04-09T13%3A57%3A00Z&sr=b&sp=r&sig=iTqnNKhBzhEAcNFtHWeizs2WJRITf%2BkpAL6BrBzxQc4%3D",
  },
  {
    _id: "624f3c586617c38238d43e24",
    isExampleVideo: true,
    name: "Hollow Hold - Alternating Legs",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e24?sv=2020-10-02&st=2022-04-08T13%3A58%3A30Z&se=2040-04-09T13%3A58%3A00Z&sr=b&sp=r&sig=B7xl2lF8yMcCj5U8kgLIgzP0jLuUlwK%2B6CPU1IUsXIA%3D",
  },
  {
    _id: "624f3c586617c38238d43e25",
    isExampleVideo: true,
    name: "Hollow Hold - Knee Raise",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e25?sv=2020-10-02&st=2022-04-08T13%3A58%3A50Z&se=2040-04-09T13%3A58%3A00Z&sr=b&sp=r&sig=ijYJn5XsZm2vsmMIuVgbq5Wb9N4TZmoKRjonBYeLn7M%3D",
  },
  {
    _id: "624f3c586617c38238d43e26",
    isExampleVideo: true,
    name: "Hollow Hold",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e26?sv=2020-10-02&st=2022-04-08T13%3A59%3A06Z&se=2040-04-09T13%3A59%3A00Z&sr=b&sp=r&sig=LdVJ1bDFEVA3f5BWnj3BXG5QQp4ZmOaYcDRkiWPpSxk%3D",
  },
  {
    _id: "624f3c586617c38238d43e27",
    isExampleVideo: true,
    name: "Lateral Lunge",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e27?sv=2020-10-02&st=2022-04-08T13%3A59%3A25Z&se=2040-04-09T13%3A59%3A00Z&sr=b&sp=r&sig=9JTSqaF0xW6hD0585HOKqP00lgkUtaycR7Ac%2FQRP0Cs%3D",
  },
  {
    _id: "624f3c586617c38238d43e28",
    isExampleVideo: true,
    name: "Lumbar Spine Extension Tilts",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e28?sv=2020-10-02&st=2022-04-08T13%3A59%3A53Z&se=2040-04-09T13%3A59%3A00Z&sr=b&sp=r&sig=SZ1Odyj9BrglXlpZU58sdhoXggjSQsAaMvl4kg2HtgU%3D",
  },
  {
    _id: "624f3c586617c38238d43e29",
    isExampleVideo: true,
    name: "Lumbar Spine Extension with Hip Rotations",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e29?sv=2020-10-02&st=2022-04-08T14%3A00%3A29Z&se=2040-04-09T14%3A00%3A00Z&sr=b&sp=r&sig=%2FvcWdMTC3Vkds8WguwfOWKaasXyv%2FDUnGmZJgz0ev%2Fo%3D",
  },
  {
    _id: "624f3c586617c38238d43e2a",
    isExampleVideo: true,
    name: "Lumbar Spine Roll",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e2a?sv=2020-10-02&st=2022-04-08T14%3A00%3A47Z&se=2040-04-09T14%3A00%3A00Z&sr=b&sp=r&sig=89Thj7EkyP5n838XoYtsOQIZf7JosoQe%2BubqgGAMJ0M%3D",
  },
  {
    _id: "624f3c586617c38238d43e2b",
    isExampleVideo: true,
    name: "Plank Rotations",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e2b?sv=2020-10-02&st=2022-04-08T14%3A01%3A06Z&se=2040-04-09T14%3A01%3A00Z&sr=b&sp=r&sig=XA8GzS1R1e5OOhiJas8MyIbWR%2FLw1M4vtjz5XFYn6fU%3D",
  },
  {
    _id: "624f3c586617c38238d43e2c",
    isExampleVideo: true,
    name: "Psoas Mash - Roller",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e2c?sv=2020-10-02&st=2022-04-08T14%3A01%3A23Z&se=2040-04-09T14%3A01%3A00Z&sr=b&sp=r&sig=suU2Goxgg59Xil9HWQJC4Dyv1Farr3yG7sNLtNutUl8%3D",
  },
  {
    _id: "624f3c586617c38238d43e2d",
    isExampleVideo: true,
    name: "Pushup - Negative to Worm up",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e2d?sv=2020-10-02&st=2022-04-08T14%3A01%3A42Z&se=2040-04-09T14%3A01%3A00Z&sr=b&sp=r&sig=bBLZcRjkpekef0qp7%2FuiTekXtCt9RpxtsV5uUVNX%2Fec%3D",
  },
  {
    _id: "624f3c586617c38238d43e2e",
    isExampleVideo: true,
    name: "Pushup - Worm Down to Worm Up",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e2e?sv=2020-10-02&st=2022-04-08T14%3A02%3A00Z&se=2040-04-09T14%3A02%3A00Z&sr=b&sp=r&sig=Skwh2nxqTJ86vRSvC%2FUFyLfNhddBgtzm4M5U2fnT4DQ%3D",
  },
  {
    _id: "624f3c586617c38238d43e2f",
    isExampleVideo: true,
    name: "Scapular Protractions - Supine",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e2f?sv=2020-10-02&st=2022-04-08T14%3A02%3A34Z&se=2040-04-09T14%3A02%3A00Z&sr=b&sp=r&sig=TbaFgFgeYOzu538uMgFaECtbCJv1fBg81k7ipxAKLts%3D",
  },
  {
    _id: "624f3c586617c38238d43e30",
    isExampleVideo: true,
    name: "Scapular Retractions - Supine",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e30?sv=2020-10-02&st=2022-04-08T14%3A02%3A53Z&se=2040-04-09T14%3A02%3A00Z&sr=b&sp=r&sig=s%2BksiyPVeQVmdmw6cNJskGc%2FbVrSRxidQoOuTJ82MSM%3D",
  },
  {
    _id: "624f3c586617c38238d43e31",
    isExampleVideo: true,
    name: "Shoulder Abductions - Side Posture",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e31?sv=2020-10-02&st=2022-04-08T14%3A03%3A11Z&se=2040-04-09T14%3A03%3A00Z&sr=b&sp=r&sig=EJGhbcz4yA%2F9cJgyuyNzpZSZtA5h5CWFj1awEPvo%2FVs%3D",
  },
  {
    _id: "624f3c586617c38238d43e32",
    isExampleVideo: true,
    name: "Side Plank",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e32?sv=2020-10-02&st=2022-04-08T14%3A06%3A26Z&se=2040-04-09T14%3A06%3A00Z&sr=b&sp=r&sig=EiL4cUvHklEFJZXe0XTzGVG44e0oZP9D416gKWQ7ZQA%3D",
  },
  {
    _id: "624f3c586617c38238d43e33",
    isExampleVideo: true,
    name: "Ski Jumper",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e33?sv=2020-10-02&st=2022-04-08T14%3A06%3A45Z&se=2040-04-09T14%3A06%3A00Z&sr=b&sp=r&sig=kMf%2Btmgf%2FuVok6GQkV%2Bhn%2BZF4mrV2k%2BhekHlffwVxjY%3D",
  },
  {
    _id: "624f3c586617c38238d43e34",
    isExampleVideo: true,
    name: "Spinal Flow",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e34?sv=2020-10-02&st=2022-04-08T14%3A07%3A03Z&se=2040-04-09T14%3A07%3A00Z&sr=b&sp=r&sig=wFVHq%2FJa%2BrhVzZWw32UJ6E%2FuRy7GuE75Emv%2BI4nXRrQ%3D",
  },
  {
    _id: "624f3c586617c38238d43e35",
    isExampleVideo: true,
    name: "Supermans",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e35?sv=2020-10-02&st=2022-04-08T14%3A07%3A49Z&se=2040-04-09T14%3A07%3A00Z&sr=b&sp=r&sig=APAYHb4jeygZ%2Bs4%2B8nuD6v2v8PjorZTqeMpWgW5%2BsTE%3D",
  },
  {
    _id: "624f3c586617c38238d43e36",
    isExampleVideo: true,
    name: "Thoracic Spine Extension Tilts - Butterfly",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e36?sv=2020-10-02&st=2022-04-08T14%3A08%3A11Z&se=2040-04-09T14%3A08%3A00Z&sr=b&sp=r&sig=tPxJxq7ZkZGFgqwZE9UVdz59AWkVFwJb6wv7uX7NDso%3D",
  },
  {
    _id: "624f3c586617c38238d43e37",
    isExampleVideo: true,
    name: "Touchdown Press",
    sas: "https://telecare.blob.core.windows.net/prod/624f3c586617c38238d43e37?sv=2020-10-02&st=2022-04-08T14%3A08%3A28Z&se=2040-04-09T14%3A08%3A00Z&sr=b&sp=r&sig=gXt5INO2kAUgCyGJV6uz295QRF1bkj%2F%2BkDHQ%2FqwlD4k%3D",
  },
];
