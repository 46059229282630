import React, { FC } from "react";

import useAuth from "../../hooks/useAuth";
import { useContacts, usePlans } from "../../hooks/useCollection";
import IContact from "../../models/IContact";
import IPlan from "../../models/IPlan";
import Dashboard from "./Dashboard";
import { sortPlans } from "../../utils/sort";
import { IAppState } from "../../store/Root";
import { useSelector } from "react-redux";

const DashboardPage: FC = () => {
  const auth = useAuth()!;
  const contacts = useContacts();
  const plans = usePlans();
  const doctorFilter = (state: IAppState) => state.settings.doctorFilter;
  const doctorFilterId = useSelector(doctorFilter);

  let plansList: (IPlan & { doctor: IContact | null })[] | null =
    plans &&
    Object.values(plans)
      .map(x => ({ ...x, doctor: contacts && contacts[x.doctorId] }))
      .sort(sortPlans);

  if (doctorFilterId && plansList) {
    plansList = plansList.filter(plan => plan.doctorId === doctorFilterId);
  }

  return <Dashboard auth={auth} plans={plansList} />;
};

export default DashboardPage;
