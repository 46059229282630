import React, { FC } from "react";

type Props = {
  bodyColor?: string;
  shellColor?: string;
};

const Turtle: FC<Props> = ({ bodyColor = "black", shellColor = "black" }) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      data-icon="turtle"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
      className="svg-inline--fa fa-turtle fa-w-18 fa-7x"
    >
      <g className="fa-group">
        <path
          fill={bodyColor}
          d="M552.28 137.25l-48.65-34.75c-35.17-17.42-80.49 1.57-86.81 40.31A63.4 63.4 0 0 0 416 153v71.22a62.79 62.79 0 0 1-13.27 38.44A64.75 64.75 0 0 1 351.05 288H18.6a18.6 18.6 0 0 0-5.89 36.24l98.29 22.1L66.17 424A16 16 0 0 0 80 448h37a16 16 0 0 0 13.86-8l40.3-69.8c26 8.52 45.55 13.8 84.87 13.8s58.89-5.28 84.87-13.8l40.3 69.8a16 16 0 0 0 13.8 8h37a16 16 0 0 0 13.86-24l-47.21-81.76c21.25-8.42 40.36-21.78 54.81-40.53A126.33 126.33 0 0 0 478.74 240h40.62a56.65 56.65 0 0 0 32.92-102.76zM480 176a16 16 0 1 1 16-16 16 16 0 0 1-16 16z"
          className="fa-secondary"
        ></path>
        <path
          fill={shellColor}
          d="M68.25 256c-23.54 0-41-19.8-35.1-40 20-69 90.53-152 174.44-152h.82c83.92 0 154.43 83 174.45 152 5.87 20.24-11.56 40-35.1 40z"
          className="fa-primary"
        ></path>
      </g>
    </svg>
  );
};

export default Turtle;
