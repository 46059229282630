import { Configuration } from "@azure/msal-browser";
import { getAuthClientId, getAuthDomain } from "./utils/config";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: getAuthClientId(),
    authority: `https://${getAuthDomain()}.b2clogin.com/${getAuthDomain()}.onmicrosoft.com/B2C_1_signin1`,
    knownAuthorities: [`https://${getAuthDomain()}.b2clogin.com`],
    redirectUri: `${window.location.origin}/dashboard`,
    postLogoutRedirectUri: "/",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  } as const,
};
