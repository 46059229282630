import React, { useState } from "react";

import { formatObjectId } from "../../services/DateService";
import { noopp } from "../../utils/noop";
import { compareProps } from "../../utils/sort";
import { CalendarIcon, SearchIcon } from "../core/Icons";
import Spinner from "../core/Spinner";

export type SearchSelectItem = {
  title: string;
  id: string;
};

export type Props = {
  autoFocus?: boolean;
  containerHeight?: string;
  items: SearchSelectItem[];
  onCancel?: () => void;
  onSelect?: (item: SearchSelectItem) => Promise<void>;
  sectionHeight?: string;
  selectedItem?: SearchSelectItem;
};

export default function SearchSelect({
  items = [],
  onCancel,
  onSelect = noopp,
  autoFocus = true,
  containerHeight = "80vh",
  sectionHeight = "70vh",
  selectedItem = undefined,
}: Props) {
  const [isPending] = useState(false);
  const [phrase, setPhrase] = useState("");

  const lowerPhrase = phrase.toLocaleLowerCase();

  const results = items.filter(x => x.title.toLocaleLowerCase().includes(lowerPhrase)).sort((a, b) => compareProps(a, b, { title: 1 }));

  const total = results.length;

  return (
    <article data-cy="search card" className="material" style={{ height: containerHeight }}>
      <h2 style={{ display: "flex", alignItems: "center" }}>
        <label className="mr-2" htmlFor="search" style={{ margin: 0 }}>
          <SearchIcon />
        </label>
        <div style={{ width: "100%" }}>
          <input
            data-cy="search bar"
            type="text"
            className="form-control"
            style={{ background: "transparent", color: "white", padding: "0rem .2rem" }}
            autoComplete="off"
            autoFocus={autoFocus}
            id="search"
            spellCheck={false}
            value={phrase}
            onChange={e => setPhrase(e.target.value)}
            onKeyDown={e => {
              if (e.key === "ArrowDown") {
                e.preventDefault();

                const parent = e.currentTarget.closest("article");

                if (!parent) {
                  return;
                }

                const el = parent.querySelector(".list-group-item") as HTMLElement;

                if (el && el.focus) {
                  el.focus();
                }
              }
            }}
          />
        </div>
      </h2>
      <section>
        <div style={{ overflowY: "auto", height: sectionHeight }}>
          {!!total && (
            <div
              data-cy="search results"
              className="list-group"
              onKeyDown={e => {
                const el = e.target as HTMLElement;

                if (!el) {
                  return;
                }

                const parent = el.parentElement;

                let sib: HTMLElement | null = null;

                switch (e.key) {
                  case "ArrowUp": {
                    const first = parent && parent.querySelector(".list-group-item");

                    if (el === first) {
                      sib = document.getElementById("search");
                    } else {
                      sib = el.previousElementSibling as HTMLElement;
                    }

                    break;
                  }
                  case "ArrowDown": {
                    sib = el.nextElementSibling as HTMLElement;
                    break;
                  }
                  default: {
                    return;
                  }
                }

                e.preventDefault();

                if (sib && sib.focus) {
                  sib.focus();
                }
              }}
            >
              {results.map(result => {
                // let isDefaultVideo = "isExampleVideo" in result && result.isExampleVideo;
                let isDefaultVideo = false;

                return (
                  <button
                    key={result.id}
                    type="button"
                    className="list-group-item list-group-item-action"
                    style={{ display: "flex", alignItems: "center", backgroundColor: selectedItem && result.id === selectedItem.id ? "lightblue" : "initial" }}
                    onClick={() => {
                      // setSelected(result);
                      onSelect(result);
                    }}
                  >
                    {/* 12/14/2020 Dan - investigate performance issue with many videos
                    <div style={{ width: "10rem" }}>
                      <VideoPreview video={result} />
                    </div> */}
                    <div className="ml-3">
                      <div className={`mb-1${isDefaultVideo ? " text-success" : ""}`}>{result.title}</div>
                      <div style={{ color: "gray", fontSize: ".8rem" }}>
                        <CalendarIcon /> {formatObjectId(result.id)}
                      </div>
                    </div>
                  </button>
                );
              })}
            </div>
          )}
        </div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <div style={{ color: "gray", fontSize: ".8rem" }}>{total} results.</div>
          {onCancel && (
            <button type="button" className="action btn text-success" disabled={isPending} onClick={() => onCancel()}>
              {isPending ? (
                <>
                  <Spinner /> Saving
                </>
              ) : (
                "Done"
              )}
            </button>
          )}
        </div>
      </section>
    </article>
  );
}
