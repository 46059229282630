import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import useAuth from "../../hooks/useAuth";
import { ExpandIcon, ImageIcon, UploadIcon, VideoIcon, VerticalDotsIcon } from "../core/Icons";
import UploadImage from "../image/UploadImage";
import UploadDeviceVideo from "../video/UploadDeviceVideo";
import ModalSlim from "../core/ModalSlim";
import ConfirmCard from "../core/ConfirmCard";
import IImage from "../../models/IImage";
import IVideo from "../../models/IVideo";
import AssessmentCompare from "./AssessmentCompare";
import VideoImageCapture from "../video/VideoImageCapture";
import { useAssessments } from "../../hooks/useCollection";
import { addImageToAssessment, addVideoToAssessment, deleteImage, deleteVideo } from "../../services/AssessmentsService";
import AssessmentVideoDisplay from "./AssessmentVideoDisplay";
import AssessmentImageDisplay from "./AssessmentImageDisplay";
import AssessmentPatientVideoDisplay from "./AssessmentPatientVideoDisplay";
import { Role } from "../../models/IAuth";
import VideoRecorder from "../video/videoRecorder/VideoRecorder";

type Props = {
  doctorID: string;
  patientID: string;
};

const PatientAssessment: FC<Props> = ({ patientID, doctorID }) => {
  const auth = useAuth()!;
  const [imageToDelete, setImageToDelete] = useState<IImage>();
  const [imageUpload, setImageUpload] = useState<boolean>(false);
  const [isComparing, setIsComparing] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [recordingMode, setRecordingMode] = useState<"file" | "camera" | undefined>();
  const [selectedImages, setSelectedImages] = useState<Array<IImage>>([]);
  const [video, setVideo] = useState<Blob>();
  const [videoToCaptureImage, setVideoToCaptureImage] = useState<IVideo>();
  const [videoToDelete, setVideoToDelete] = useState<IVideo>();
  let assessments = useAssessments();
  const [images, setImages] = useState<any>();
  const [videos, setVideos] = useState<any>();
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  useEffect(() => {
    setSelectedImages([]);
  }, [patientID]);

  useEffect(() => {
    let vids: any = [];
    let imgs: any = [];
    if (assessments && assessments[patientID]) {
      if (assessments[patientID].videos.length > 0) {
        vids = assessments[patientID].videos.map((vid, idx) => {
          return auth.role === Role.doctor || auth.role === Role.organizationadmin ? (
            <AssessmentVideoDisplay
              key={`assessment-video-${vid._id}-${idx}`}
              onVideoClick={setVideoToCaptureImage}
              onVideoDelete={setVideoToDelete}
              onVideoImageCapture={setVideoToCaptureImage}
              video={vid}
            />
          ) : (
            <AssessmentPatientVideoDisplay key={`assessment-video-${vid._id}-${idx}`} video={vid} />
          );
        });
      }

      if (assessments[patientID].images.length > 0) {
        imgs = assessments[patientID].images.map(image => (
          <AssessmentImageDisplay
            key={`assessment-image-${image._id}`}
            image={image}
            onImageDelete={setImageToDelete}
            selectedImages={selectedImages}
            setSelectedImages={setSelectedImages}
          />
        ));
      }
    }
    setVideos(vids);
    setImages(imgs);
  }, [patientID, assessments, auth.role, selectedImages]);

  const dispatch = useDispatch();

  const handleImageToDelete = (image: IImage) => {
    return deleteImage(image._id, patientID, doctorID)
      .then(() => {
        setSelectedImages(selectedImages.filter(img => img._id !== image._id));
        dispatch({ type: "DELETE_ASSESSMENT_IMAGE", item: assessments![patientID], imageId: image._id });
        toast.success("Image deleted");
      })
      .catch(() => toast.error("Error deleting image"));
  };

  const handleImageUpload = (blob: Blob, vidID: string) => {
    setIsUploading(true);
    return addImageToAssessment(blob, doctorID || "", patientID, vidID, (name, percentComplete) => {
      // setUploadProgress(percentComplete);
    })
      .then(assessment => {
        setImageUpload(false);
        dispatch({ type: "SET_ASSESSMENT", item: assessment });
        toast.success("Image uploaded");
      })
      .catch(() => toast.error("Error uploading image."))
      .finally(() => {
        setIsUploading(false);
      });
  };

  const handleVideoDelete = (vid: IVideo) => {
    return deleteVideo(vid._id, patientID, doctorID)
      .then(() => {
        dispatch({ type: "DELETE_ASSESSMENT_VIDEO", item: assessments![patientID], videoId: vid._id });
        toast.success("Video deleted");
      })
      .catch(() => toast.error("Error deleting video"));
  };

  const handleVideoUpload = () => {
    setIsUploading(true);
    return addVideoToAssessment(video!, patientID, doctorID, (name, percentComplete) => {
      setUploadProgress(percentComplete);
    })
      .then(assessment => {
        dispatch({ type: "SET_ASSESSMENT", item: assessment });
        setVideo(undefined);
        setRecordingMode(undefined);
        setUploadProgress(0);
        // toast.success("Video uploaded");
      })
      .catch(() => {
        toast.error("Error uploading video");
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  return (
    <article>
      <h2 style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <div>Assessments</div>
        <div>
          <button
            data-cy="expand assessments"
            className="btn btn-secondary"
            style={{ marginLeft: "1rem" }}
            type="button"
            data-toggle="collapse"
            data-target="#collapseAssessment"
            aria-expanded="false"
            aria-controls="collapseAssessment"
          >
            <ExpandIcon />
          </button>
        </div>
      </h2>
      <div className="collapse" id="collapseAssessment">
        <section>
          <button type="submit" className="action btn btn-primary" disabled={selectedImages.length === 0} onClick={() => setIsComparing(true)}>
            Display Assessment
          </button>
          <div data-cy="plan actions" data-toggle="dropdown" style={{ cursor: "pointer", float: "right", minWidth: "1rem", textAlign: "center" }}>
            <VerticalDotsIcon />
          </div>
          <div className="dropdown-menu dropdown-menu-right">
            {(auth.role === Role.doctor || auth.role === Role.organizationadmin) && (
              <>
                <div className="dropdown-item" onClick={() => setRecordingMode("camera")}>
                  <VideoIcon /> Record Video
                </div>
                <div className="dropdown-divider"></div>

                <div className="dropdown-item" onClick={() => setRecordingMode("file")}>
                  <UploadIcon /> Upload Video
                </div>
              </>
            )}
            <div className="dropdown-item" onClick={() => setImageUpload(true)}>
              <ImageIcon /> Upload Image
            </div>
          </div>
        </section>
        <section className="bg-secondary">
          <h3 className="text-primary">Videos</h3>
          <div className="grid" style={{ margin: "0 -1rem -1rem -1rem" }}>
            {videos}
          </div>
          <h3 className="text-primary" style={{ paddingTop: "2rem" }}>
            Images
          </h3>
          <div className="grid" style={{ margin: "0 -1rem -1rem -1rem" }}>
            {images}
          </div>
        </section>
      </div>
      {recordingMode === "file" && (
        <UploadDeviceVideo
          canUpload={!!video}
          isPending={isUploading}
          onCancel={() => {
            setRecordingMode(undefined);
            setVideo(undefined);
          }}
          onRecordingComplete={setVideo}
          onSaveRecording={handleVideoUpload}
          recordingMode={recordingMode}
          uploadProgress={uploadProgress}
        />
      )}
      {recordingMode === "camera" && (
        <VideoRecorder
          canUpload={!!video}
          isPending={isUploading}
          onCancel={() => {
            setRecordingMode(undefined);
            setVideo(undefined);
          }}
          onRecordingComplete={setVideo}
          onSaveRecording={handleVideoUpload}
          recordingMode={recordingMode}
          uploadProgress={uploadProgress}
        />
      )}
      {imageUpload && (
        <UploadImage isPending={isUploading} onCancel={() => setImageUpload(false)} onUpload={image => handleImageUpload(image, "")} uploadProgress={uploadProgress} />
      )}
      {videoToDelete && (
        <ModalSlim>
          <ConfirmCard
            question="Delete this assessment video?"
            onCancel={() => setVideoToDelete(undefined)}
            confirmLabel="Delete"
            confirmPendingLabel="Deleting"
            onSubmit={() => handleVideoDelete(videoToDelete)}
          />
        </ModalSlim>
      )}
      {imageToDelete && (
        <ModalSlim>
          <ConfirmCard
            question="Delete this assessment image?"
            onCancel={() => setImageToDelete(undefined)}
            confirmLabel="Delete"
            confirmPendingLabel="Deleting"
            onSubmit={() => handleImageToDelete(imageToDelete)}
          />
        </ModalSlim>
      )}
      {isComparing && <AssessmentCompare images={selectedImages} onCancel={() => setIsComparing(false)} />}
      {videoToCaptureImage && (
        <VideoImageCapture
          video={videoToCaptureImage}
          handleOnCancel={() => setVideoToCaptureImage(undefined)}
          handleOnMediaAdd={blob => handleImageUpload(blob, videoToCaptureImage._id)}
          isVideoPending={isUploading}
        />
      )}
    </article>
  );
};

export default PatientAssessment;
