import React, { FC, Fragment, useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import ObjectID from "bson-objectid";

import useCustomEvent from "../../hooks/useCustomEvent";
import useKeyListener from "../../hooks/useKeyListener";
import IContact from "../../models/IContact";
import IVideo from "../../models/IVideo";
import { addAttachment, removeAttachment } from "../../services/AttachmentsService";
import { saveExample } from "../../services/ExamplesService";
import AddAttachmentCard from "../attachments/AddAttachmentCard";
import RemoveAttachmentCard from "../attachments/RemoveAttachmentCard";
import ModalSlim from "../core/ModalSlim";
import SelectExampleCard from "../examples/SelectExampleCard";
import SearchCard from "../search/SearchCard";

type ModelType = "add-attachment" | "login" | "remove-attachment" | "search" | "select-example";

type ModelProps = {
  args: any;
  key: string;
  type: ModelType;
};

type Props = {
  caseload?: IContact[];
  library?: IVideo[];
};

const Models: FC<Props> = ({ caseload, library }) => {
  const location = useLocation();
  const [stack, setStack] = useState<ModelProps[]>([]);

  const ensureInStack = (type: ModelType, ev: any) => {
    if (stack.every(x => x.type !== type)) {
      const item = { args: ev.detail, type, key: new ObjectID().str };

      setStack([...stack, item]);
    }
  };

  const ensureOutStack = (type: ModelType) => {
    setStack(stack.filter(x => x.type !== type));
  };

  const popStack = useCallback(() => {
    if (stack.length) {
      setStack(stack.slice(0, -1));
    }
  }, [stack, setStack]);

  useCustomEvent("showAddAttachment", x => ensureInStack("add-attachment", x), [stack, setStack]);
  useCustomEvent("showLogin", x => ensureInStack("login", x), [stack, setStack]);
  useCustomEvent("showRemoveAttachment", x => ensureInStack("remove-attachment", x), [stack, setStack]);
  useCustomEvent("showSearch", x => ensureInStack("search", x), [stack, setStack]);
  useCustomEvent("showSelectExample", x => ensureInStack("select-example", x), [stack, setStack]);

  useKeyListener("Escape", popStack, [stack, setStack]);

  return (
    <>
      {!!stack.length && <div className="modal-slim-overlay"></div>}
      {stack.map((x, i) => {
        let el = null;

        switch (x.type) {
          case "add-attachment": {
            el = (
              <AddAttachmentCard
                key={x.key}
                onCancel={() => ensureOutStack("add-attachment")}
                onSave={item => {
                  const currentPath = location.pathname as string;
                  let isPlanTemplate = true;
                  if (!currentPath.toLocaleLowerCase().startsWith("/plantemplates/")) {
                    isPlanTemplate = false;
                  }
                  return addAttachment(x.args.planId, x.args.sessionId, item.file, item.name, undefined, isPlanTemplate);
                }}
              />
            );

            break;
          }
          case "remove-attachment": {
            el = (
              <RemoveAttachmentCard
                attachment={x.args.attachment}
                onCancel={() => ensureOutStack("remove-attachment")}
                onSubmit={() => {
                  const currentPath = location.pathname as string;
                  let isPlanTemplate = true;
                  if (!currentPath.toLocaleLowerCase().startsWith("/plantemplates/")) {
                    isPlanTemplate = false;
                  }
                  return removeAttachment(x.args.planId, x.args.sessionId, x.args.attachment._id, isPlanTemplate);
                }}
              />
            );

            break;
          }
          case "search": {
            el = <SearchCard key={x.key} caseload={caseload} onCancel={() => ensureOutStack("search")} />;

            break;
          }
          case "select-example": {
            el = (
              <SelectExampleCard
                key={x.key}
                library={library}
                onCancel={() => ensureOutStack("select-example")}
                onSelect={
                  x.args.onSelect ||
                  (async (item, isVideoLibrary) => {
                    const currentPath = location.pathname as string;
                    let isPlanTemplate = true;
                    if (!currentPath.toLocaleLowerCase().startsWith("/plantemplates/")) {
                      isPlanTemplate = false;
                    }
                    await saveExample(x.args.planId, x.args.sessionId, item._id, isVideoLibrary, isPlanTemplate);

                    ensureOutStack("select-example");
                  })
                }
              />
            );

            break;
          }
          default: {
            console.error(`Unknown modal: ${x.type}`);

            el = <Fragment key={x.key} />;

            break;
          }
        }

        return (
          <ModalSlim key={x.key} left={`${50 + i}%`} zIndex={10000 + i}>
            {el}
          </ModalSlim>
        );
      })}
    </>
  );
};

export default Models;
