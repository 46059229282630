import { BlobHTTPHeaders, BlockBlobClient } from "@azure/storage-blob";
import { toast } from "react-toastify";
import { deleteBlob, retrieveBlob } from "../utils/indexeddbHelper";

import { request } from "./ApiService";
import { convertVideo, refreshImages, refreshVideos } from "./VideoService";
import store from "../store/Store";

export type Options = {
  disposition?: "attachment";
  fileName?: string;
  fileExtension?: string;
};

interface DatabaseFileEntry {
  blob: Blob;
  videoId: string;
}

export const uploadFile = async (blob: Blob, options: Options = {}, handleProgress?: (name: string, percentComplete: number) => void): Promise<string> => {
  const headers: BlobHTTPHeaders = { blobCacheControl: "max-age=31536000", blobContentType: blob.type };

  if (options.disposition === "attachment") {
    headers.blobContentDisposition = `attachment; filename="${options.fileName}.${options.fileExtension}"`;
  }

  // @ts-ignore
  let blobName = blob.name;
  if (!blobName) {
    // @ts-ignore
    blob.name = new Date().toISOString();
    // @ts-ignore;
    blobName = blob.name;
  }

  let uploadSas = "";
  let videoId = "";

  try {
    const indexedDBPromise = new Promise((resolve, reject) => {
      retrieveBlob(
        blobName,
        async (blobdb: DatabaseFileEntry) => {
          if (!blobdb) {
            const shake: { _id: string; sas: string } = await request(`files/handshake?fileExtension=${options.fileExtension || ""}`);
            videoId = shake._id;
            uploadSas = shake.sas;
            // storeBlob({ blob, videoId: videoId }, blobName);
            resolve(shake);
          } else {
            const shake: { _id: string; sas: string } = await request(`files/handshake/${blobdb.videoId}?fileExtension=${options.fileExtension || ""}`);
            videoId = shake._id;
            uploadSas = shake.sas;
            resolve(shake);
          }
        },
        () => {
          reject();
        },
      );
    });

    await indexedDBPromise.catch(async () => {
      // try and get a sas without using blob storage
      const shake: { _id: string; sas: string } = await request(`files/handshake?fileExtension=${options.fileExtension || ""}`);
      videoId = shake._id;
      uploadSas = shake.sas;
    });
  } catch (err) {
    console.log(`Error retrieving blob: ${err}`);
    toast.error(`Error uploading: ${err}`);
  }

  const settings = {
    blobHTTPHeaders: headers,
    blockSize: 4 * 1024 * 1024, // 4MB block size
    concurrency: 6,
    maxSingleShotSize: 4 * 1024 * 1024, // 4MB block size
    onProgress: (ev: any) => {
      if (handleProgress) {
        const percentComplete = Math.round((ev.loadedBytes / blob.size) * 100);
        store.dispatch({ type: "UPDATE_FILE_PROGRESS", fileProgress: { name: videoId, percentComplete } });
        handleProgress(videoId, percentComplete);
        // console.log(Math.round((ev.loadedBytes / blob.size) * 100));
      }
    },
  };

  await new BlockBlobClient(uploadSas)
    .uploadData(blob, settings)
    .then(() => {
      toast.success("Upload successful!");
      refreshImages(videoId);
      convertVideo(videoId)
        .then(() => {
          refreshVideos();
        })
        .catch(ex => {
          console.log(`Error converting: ${ex}`);
        });

      try {
        deleteBlob(blobName, () => {
          console.log("Local blob deleted");
        });
      } catch (err) {
        console.log(`Error deleting blob: ${err}`);
      }
    })
    .catch(ex => {
      toast.error(`Error uploading: ${ex}`);
    });

  return videoId;
};
