import React, { FC } from "react";

type Props = {
  "data-cy"?: string;
  sasMp4?: string;
  sas?: string;
};
const VideoDisplay: FC<Props> = ({ "data-cy": dataCy, sasMp4, sas }) => {
  return (
    <div className="embed-responsive embed-responsive-16by9">
      <video data-cy={dataCy} className="embed-responsive-item" controls>
        {sasMp4 && <source src={sasMp4} />}
        {sas && <source src={sas} />}
      </video>
    </div>
  );
};
export default VideoDisplay;
