import { toast } from "react-toastify";

import { dispatch } from "../store/Store";
import { request } from "./ApiService";

export const sendInvitation = (accountId: string) =>
  request("invitations", { method: "POST", body: { accountId } })
    .then(({ invitationTms }) => {
      toast.success("Invitation sent");
      dispatch({ type: "SET_CONTACT_INVITATION", invitation: { _id: accountId, tms: new Date(invitationTms) } });
    })
    .catch(err => toast.error(err.message));
