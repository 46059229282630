import ObjectID from "bson-objectid";
import { addMonths, addYears, format, formatDistanceStrict } from "date-fns";

export const formatObjectId = (id: string) => {
  const stamp = new Date(getObjectIdTimestamp(id));

  if (addMonths(new Date(), -1) < stamp) {
    return formatDistanceStrict(stamp, new Date(), { addSuffix: true });
  }

  if (addYears(new Date(), -1) < stamp) {
    return format(stamp, "MMM d");
  }

  return format(stamp, "MMM yyyy");
};

export const getObjectIdTimestamp = (id: string) => {
  return new ObjectID(id).getTimestamp();
};
