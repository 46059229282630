import React, { FC, useState } from "react";
import Spinner from "../core/Spinner";
import IVideo from "../../models/IVideo";

export type Props = {
  current: IVideo;
  isPending: boolean;
  onCancel: () => void;
  onSubmit: (file: File, name: string) => void;
  uploadProgress?: number;
};

const validate = (file: File | undefined, current: IVideo, name: string) => {
  if (current._id === undefined && (file === undefined || !file.name)) {
    return "Select a local file";
  }

  if (!name || !name.trim()) {
    return "Name is required";
  }

  return null;
};

const ModifyVideoCard: FC<Props> = ({ current, isPending, onCancel, onSubmit, uploadProgress }) => {
  const [file, setFile] = useState<File | undefined>(undefined);
  const [name, setName] = useState<string>(current.name || "");

  const validation = validate(file, current, name);

  return (
    <article data-cy="video card">
      <h2>{current._id ? "Edit Video" : "Upload Video"}</h2>
      <form
        autoComplete="off"
        className="material"
        onSubmit={e => {
          e.preventDefault();

          onSubmit(file!, name);
        }}
      >
        <section>
          <div className="form-group">
            <input
              autoComplete="none"
              autoFocus={true}
              className="form-control text-primary"
              data-cy="name"
              style={{ fontSize: "1.2rem" }}
              type="text"
              id="name"
              placeholder="add name here..."
              required={true}
              title="name"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </div>
          {current._id === undefined && (
            <div className="form-group">
              <input
                type="file"
                name="file"
                onChange={event => {
                  if (event && event.target && event.target.files) setFile(event.target.files[0]);
                }}
              />
            </div>
          )}
        </section>
        <section>
          <div className="d-flex justify-content-between">
            <div>
              <button type="button" className="action btn text-danger" disabled={isPending} onClick={() => onCancel()}>
                Cancel
              </button>
              <button title={validation || undefined} type="submit" className="action btn btn-primary" disabled={!!validation || isPending}>
                {isPending && <Spinner />} {isPending ? `Saving${uploadProgress ? ` ${uploadProgress}%` : ""}` : "Save"}
              </button>
            </div>
          </div>
        </section>
      </form>
    </article>
  );
};

export default ModifyVideoCard;
