import React, { FC } from "react";

import IContact from "../../models/IContact";

export type Props = {
  doctors: IContact[];
  filterDoctor: (doctorId: string) => void;
  onCancel: () => void;
  resetFilter: () => void;
  selectedDoctors: string[];
};

const FilterDoctorsCard: FC<Props> = ({ doctors, filterDoctor, onCancel, resetFilter, selectedDoctors }) => {
  return (
    <article data-cy="patient card">
      <h2>Filter Doctors</h2>
      <section>
        <form
          autoComplete="off"
          className="material"
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          {doctors.map(doctor => (
            <div className="form-check" key={doctor._id}>
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id={`checkbox-${doctor._id}`}
                checked={selectedDoctors.includes(doctor._id)}
                onChange={() => {
                  filterDoctor(doctor._id);
                }}
              />
              <label className="form-check-label" htmlFor={`checkbox-${doctor._id}`}>
                {`${doctor.firstName} ${doctor.lastName}`}
              </label>
            </div>
          ))}
          <div className="d-flex justify-content-end">
            <div>
              <button type="button" className="action btn text-primary" onClick={() => onCancel()}>
                Ok
              </button>
              <button type="button" className="action btn btn-primary" disabled={selectedDoctors.length === 0} onClick={resetFilter}>
                Reset
              </button>
            </div>
          </div>
        </form>
      </section>
    </article>
  );
};

export default FilterDoctorsCard;
