// @ts-nocheck
import React, { useRef } from "react";
import { Image, Transformer } from "react-konva";
import useImage from "use-image";

const ImageResize = ({ shapeProps, isSelected, onSelect, onChange, url }) => {
  const shapeRef = useRef();
  const trRef = useRef();
  const [image] = useImage(url);

  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      // trRef.current.nodes([shapeRef.current]);
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  return (
    <React.Fragment>
      <Image
        image={image}
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        // scaleX={-1}
        {...shapeProps}
        draggable
        onDragEnd={e => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={e => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          // let scaleY = node.scaleY();
          let scaleY = Math.abs(node.scaleY());
          if (shapeProps.scaleX < 0) {
            // scaleY = Math.abs(node.scaleY());
            node.scaleY(-1);
          } else {
            node.scaleY(1);
          }
          // const scaleY = Math.abs(node.scaleY());

          // we will reset it back
          node.scaleX(1);
          // node.scaleY(-1);
          // const newProps = { ...shapeProps, x: node.x(), y: node.y(), width: Math.max(5, node.width() * scaleX), height: Math.max(node.height() * scaleY) };
          const newProps = { ...shapeProps, x: node.x(), y: node.y(), width: Math.max(5, node.width() * scaleX), height: Math.max(node.height() * scaleY) };

          // delete newProps.scaleX;

          // if (scaleY < 0) {
          //   newProps.scaleY = Math.abs(scaleY);
          // } else {
          //   delete newProps.scaleY;
          // }

          // console.log(newProps);

          onChange(newProps);

          // onChange({
          //   ...newProps,
          //   x: node.x(),
          //   y: node.y(),
          //   // set minimal value
          //   width: Math.max(5, node.width() * scaleX),
          //   height: Math.max(node.height() * scaleY),
          //   // scaleX: -node.scaleX(),
          //   // scaleY: scaleY,
          //   // scaleY: Math.abs(scaleY),
          // });
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </React.Fragment>
  );
};

export default ImageResize;
