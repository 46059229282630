import React, { FC } from "react";
import useAuth from "../../hooks/useAuth";
import IImage from "../../models/IImage";
import { CalendarIcon, DeleteIcon, VerticalDotsIcon } from "../core/Icons";
import { formatObjectId } from "../../services/DateService";
import noop from "../../utils/noop";
import { Role } from "../../models/IAuth";

export type Props = {
  allowImagineSelect?: boolean;
  image: IImage;
  onImageDelete: (image: IImage) => void;
  selectedImages?: Array<IImage>;
  setSelectedImages?: (images: Array<IImage>) => void;
};

const AssessmentImageDisplay: FC<Props> = ({ allowImagineSelect = true, image, onImageDelete, selectedImages = [], setSelectedImages = noop }) => {
  const _MAX_COMPARE_IMAGES = 8;
  const auth = useAuth()!;

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <img src={image.sas} alt="" />

      <section className="border-without-top p-2" style={{ flex: "1 1 auto" }}>
        {(auth.role === Role.doctor || auth.role === Role.organizationadmin) && (
          <>
            <div data-toggle="dropdown" style={{ cursor: "pointer", float: "right", minWidth: "1rem", textAlign: "center" }}>
              <VerticalDotsIcon />
            </div>
            <div className="dropdown-menu dropdown-menu-right">
              <div className="dropdown-item" onClick={() => onImageDelete(image)}>
                <DeleteIcon style={{ color: "red" }} /> Delete Image
              </div>
            </div>
          </>
        )}

        <div className="info-wrap">
          <div>
            <CalendarIcon /> {formatObjectId(image._id)}
          </div>
          {allowImagineSelect && (
            <div className="form-check" style={{ display: "block", minHeight: "1.5rem", paddingLeft: "1.5em" }}>
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                checked={selectedImages.findIndex(findImg => findImg._id === image._id) !== -1}
                disabled={selectedImages.length >= _MAX_COMPARE_IMAGES && selectedImages.findIndex(img => img._id === image._id) === -1}
                onChange={e => {
                  if (e.target.checked) {
                    setSelectedImages([...selectedImages, image]);
                  } else {
                    setSelectedImages([...selectedImages.filter(img => img._id !== image._id)]);
                  }
                }}
                id={`image-checkbox-${image._id}`}
                style={{
                  borderRadius: "0.25em",
                  float: "left",
                  marginLeft: "-1.5em",
                  width: "1em",
                  height: "1em",
                  marginTop: "0.25em",
                  verticalAlign: "top",
                  backgroundColor: "#fff",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  border: "1px solid rgba(0,0,0,.25)",
                }}
              />
              <label className="form-check-label" htmlFor={`image-checkbox-${image._id}`}>
                Display
              </label>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default AssessmentImageDisplay;
