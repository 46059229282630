import React from "react";
import { renderToString } from "react-dom/server";

import Pencil from "../svg/Pencil";

const pencils = new Map<string, string>();

const getSvgBlob = (data: string) => new Blob([data], { type: "image/svg+xml" });

const getSvgUrl = (data: string) => URL.createObjectURL(getSvgBlob(data));

export const getPencil = (color: string) => {
  const value = pencils.get(color);

  if (value) {
    return value;
  }

  const svg = renderToString(<Pencil color={color} />);

  const url = getSvgUrl(svg);

  pencils.set(color, url);

  return url;
};
