import React, { FC } from "react";
import { BrowserRouter } from "react-router-dom";

import Routed from "./Routed";

const Router: FC = () => {
  return (
    <BrowserRouter>
      <div
        className="bg-light-gray"
        style={{
          display: "flex",
          minHeight: "100vh",
          flexDirection: "column",
        }}
      >
        <Routed />
      </div>
    </BrowserRouter>
  );
};

export default Router;
