import "bootstrap/dist/js/bootstrap";
import "typeface-krona-one";
import "typeface-montserrat";
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import "../../styles/App.scss";

import React, { useCallback, useEffect } from "react";
import { ToastContainer } from "react-toastify";

import Router from "./Router";
import { getAllBlob } from "../../utils/indexeddbHelper";
import { uploadFile } from "../../services/FileService";
import { DEFAULT_VIDEO_DEVICE_ID } from "../../store/Settings";
import { useDispatch } from "react-redux";

type AppProps = {
  pca: IPublicClientApplication;
};

const App = ({ pca }: AppProps) => {
  const dispatch = useDispatch();

  const hydrateFromLocalStorage = useCallback(() => {
    const defaultVideoDeviceId = localStorage.getItem(DEFAULT_VIDEO_DEVICE_ID);
    if (defaultVideoDeviceId) {
      dispatch({ type: "SET_DEFAULT_VIDEO_ID", item: defaultVideoDeviceId });
    }
  }, [dispatch]);

  useEffect(() => {
    // hydrate local storage values
    hydrateFromLocalStorage();

    // try to continue uploading blobs
    try {
      getAllBlob((items: any) => {
        items.forEach((file: any) => {
          uploadFile(file.blob, undefined, () => {});
        });
      });
    } catch (err) {
      console.log(`Error getting all blobs: ${err}`);
    }
  }, [hydrateFromLocalStorage]);

  return (
    <MsalProvider instance={pca}>
      <div>
        <ToastContainer pauseOnFocusLoss />
        <Router />
      </div>
    </MsalProvider>
  );
};

export default App;
