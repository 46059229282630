import IComment from "../models/IComment";
import IPlan from "../models/IPlan";
import ISession from "../models/ISession";
import IVideo from "../models/IVideo";
import { request } from "./ApiService";
import { uploadFile } from "./FileService";

type SavePlanRequest = Pick<IPlan, "description" | "patientId" | "status" | "title">;

export const addComment = (id: string, sessionId: string, content: string): Promise<IComment> =>
  request(`plans/${id}/sessions/${sessionId}/comments`, { method: "POST", body: { content } });

export const addVideo = async (
  id: string,
  sessionId: string,
  blob: Blob,
  recordingType: string,
  patientVideoId?: string,
  handleProgress?: (name: string, percentComplete: number) => void,
): Promise<IVideo> => {
  const videoId = await uploadFile(blob, undefined, handleProgress);

  return saveVideo(id, sessionId, videoId, recordingType, false, patientVideoId);
};
export const saveVideo = async (id: string, sessionId: string, videoId: string, recordingType: string, isVideoLibrary: boolean, patientVideoId?: string) =>
  request(`plans/${id}/sessions/${sessionId}/video/${videoId}`, { method: "POST", body: { recordingType, isVideoLibrary, patientVideoId } });
// export const convertVideo = async (id: string, sessionId: string, videoId: string): Promise<IVideo> =>
//   request(`plans/${id}/sessions/${sessionId}/video/${videoId}/convert`, { method: "PUT" });
export const addVideoToLibrary = async (id: string, sessionId: string, videoId: string, name: string): Promise<IVideo> =>
  request(`plans/${id}/sessions/${sessionId}/video/${videoId}/addvideotolibrary`, { method: "POST", body: { name } });
export const deleteVideo = async (planId: string, sessionId: string, videoId: string) => request(`plans/${planId}/sessions/${sessionId}/video/${videoId}`, { method: "DELETE" });

export const addSession = (id: string, value: ISession) => request(`plans/${id}/sessions`, { method: "POST", body: value });
export const saveSession = (id: string, value: ISession) => request(`plans/${id}/sessions/${value._id}`, { method: "PUT", body: value });
export const addImageToSession = async (
  blob: Blob,
  fileExtension: string,
  planId: string,
  sessionId: string,
  handleProgress?: (name: string, percentComplete: number) => void,
): Promise<IVideo> => {
  const imageId = await uploadFile(blob, { fileExtension }, handleProgress);

  return request(`plans/${planId}/sessions/${sessionId}/image/${imageId}`, { method: "POST", body: { fileExtension } });
};
export const deleteSessionImage = async (planId: string, sessionId: string, imageId: string) =>
  request(`plans/${planId}/sessions/${sessionId}/image/${imageId}`, { method: "DELETE" });

export const createPlan = (value: SavePlanRequest & { _id?: string }) => request("plans", { method: "POST", body: value });
export const getPlans = () => request("plans");
export const savePlan = (value: SavePlanRequest & { _id: string }) => request(`plans/${value._id}`, { method: "PUT", body: value });
