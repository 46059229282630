import IPlan from "../models/IPlan";

type PlanTemplatesAction = {
  collection?: IPlan[];
  item?: IPlan;
  type: "DELETE_PLAN_TEMPLATE" | "SET_PLAN_TEMPLATE" | "SET_PLAN_TEMPLATES" | "RESET";
};

export type PlanTemplatesState = {
  [key: string]: IPlan;
};

export const planTemplatesReducer = (state: PlanTemplatesState | null = null, action: PlanTemplatesAction) => {
  const { collection, item, type } = action;

  switch (type) {
    case "DELETE_PLAN_TEMPLATE": {
      const newState = { ...state } as PlanTemplatesState;
      if (item && item._id && newState && newState[item._id]) {
        delete newState[item._id];
      }
      return newState;
    }
    case "SET_PLAN_TEMPLATE": {
      return { ...state, [item!._id]: item! };
    }
    case "SET_PLAN_TEMPLATES": {
      return collection!.reduce((prev, curr) => ({ ...prev, [curr._id]: curr }), {} as PlanTemplatesState);
    }
    case "RESET": {
      return null;
    }
    default: {
      return state;
    }
  }
};
